import { useTranslation } from "react-i18next";
import Header from "../common/Header";
import Siderbar from "../common/Siderbar";
import Footer from "../common/Footer";

import React, { useContext, useState } from "react";
import { AppContext } from '../App';
import { useNavigate } from 'react-router-dom'
import axios from "axios";
import Layout from "../common/Layout";
import Moment from 'moment';
import { getSkey, trans, AddLog } from '../common/Util'
import Swal from 'sweetalert2'
const Home = () => {
    const { t, i18n } = useTranslation();
    const defMenu = []
    const { sysName, setSysName, sysConfig, favorite, setFavorite, showLoading, isLogin, userRole } = useContext(AppContext)
    const [menu, setMenu] = useState(defMenu)
    const [data, setData] = useState([])
    const [rssData, setRssData] = useState(null)
    const [rssBlock, setRssBlock] = useState([])


    const getMobileOS = () => {
        const ua = navigator.userAgent
        if (/android/i.test(ua)) {
          return "Android"
        }
        else if (/iPad|iPhone|iPod/.test(ua)
           || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))
        {
          return "iOS"
        }
        return "Other"
    }

    const getBrowser = () => {
        let userAgent = navigator.userAgent;
        let browserName;
      
        if (userAgent.match(/chrome|chromium|crios/i)) {
          browserName = "Chrome";
        } else if (userAgent.match(/firefox|fxios/i)) {
          browserName = "Firefox";
        } else if (userAgent.match(/safari/i)) {
          browserName = "Safari";
        } else if (userAgent.match(/opr\//i)) {
          browserName = "Opera";
        } else if (userAgent.match(/edg/i)) {
          browserName = "Edge";
        } else if (userAgent.match(/android/i)) {
          browserName = "Android";
        } else if (userAgent.match(/iphone/i)) {
          browserName = "iPhone";
        } else {
          browserName = "Unknown";
        }
        return browserName
    }

    function getRssBlock() {
        let lang = 'zh_tw'
        //if (localStorage.getItem('i18n') != null) lang = localStorage.getItem('i18n')

        let sUrl = sysConfig.apiUrl + 'RssBlock?lang=' + lang
        let sMethod = 'get'
        axios({
            method: sMethod,
            url: sUrl
        })
            .then((response) => {
                console.log('getRssBlock', response.data)
                setRssBlock(response.data)

                showLoading(false)
            })
            .catch((error) => {
                console.log(error)
                Swal.fire({
                    title: 'API錯誤',
                    text: error.message,
                    icon: 'error',
                    showCancelButton: false,                   
                    showConfirmButton: false,
                    confirmButtonText: '確定'
                  })
            })
            .then(() => {

            })
    }

    function addRecord() {      

        // check        
        var precord = localStorage.getItem('precord')
        var pnow = Moment().format('yyyy-MM-DD')
        if(precord != null) {
            if(pnow == precord) {
                return
            }
        }

        const apiUrl = sysConfig.apiUrl + "LoginRecord"
        let account = 'guest'
        if(localStorage.getItem('LoginId') != null)
            account = localStorage.getItem('LoginId')

        let sData = {
            "userAccount": account,
            "device": getMobileOS(),
            "browser": getBrowser(),
            "ip": ''
          }
      
          axios({
            method: 'post',
            url: apiUrl,
            data: sData
          })
            .then((response) => {
             
              console.log('Add LoginRecord', response.data)
              localStorage.setItem('precord', Moment().format('yyyy-MM-DD'))
             
            })
            .catch((error) => {
              console.log(error)
            })
    }


    function getRss(link) {

        if (link == null)
            link = 'aHR0cHM6Ly9yc3MuY2d1LndvcmtlcnMuZGV2L3AvNTAzLTEwMDAtMTAxNy5waHA='
        else
            link = btoa(link)

        let sUrl = sysConfig.apiUrl + 'Rss/' + link
        let sMethod = 'get'
        axios({
            method: sMethod,
            url: sUrl
        })
            .then((response) => {
                console.log('getRss', response.data)
                setRssData(response.data.rss)


            })
            .catch((error) => {
                console.log(error)
            })
            .then(() => {

            })


    }

    React.useEffect(() => {
        
        console.log('getMobileOS',getMobileOS(), getBrowser())
        addRecord()

        showLoading(true)

        getRssBlock()
        getRss()
        if (localStorage.getItem('LoginId') == null) return        

        //getData()
        console.log('isLogin',isLogin)        

    }, []);

    React.useEffect(() => {

        getData()
       
        console.log('isLogin',isLogin)

    }, [userRole]);

    function getData() {
        let apiUrl = ''
        let rids = localStorage.getItem('role')

        if (localStorage.getItem('LoginId') == null) {
                apiUrl = sysConfig.apiUrl + 'PageContent/GuestHomeTag'

                // get PageContent
                axios.get(apiUrl).then((response) => {
                    showLoading(false)
                    console.log('PageContent/GuestHomeTag', response.data)

                    setData(response.data[0].contents)

                });
                showLoading(false)
            return      
        }

        const uid = localStorage.getItem('userId')
        apiUrl = sysConfig.apiUrl + 'Favorite?userId=' + uid

        if(rids == '') return

         // get Favorite
        axios({
            method: 'get',
            url: apiUrl
        })
            .then((response) => {
                console.log('getFavorite', response.data)
                setFavorite(response.data)

                apiUrl = sysConfig.apiUrl + 'PageContent?id=00db220e-ffdf-4a7e-8a42-416adfa55db9' + '&rid=' + rids

                // get PageContent
                axios.get(apiUrl).then((response) => {
                    showLoading(false)
                    console.log('PageContent', response.data)

                    setData(response.data[0].contents)

                });
                showLoading(false)

            })
            .catch((error) => {
                console.log(error)               
                
            })
            .then(() => {

                //
            })


    }

    function addFavorite(c_id, c_mode, c_style) {
        //event.preventDefault();
        //alert('add ' + id)

        let sUrl = sysConfig.apiUrl + 'Favorite'
        let sMethod = 'post'

        const uid = localStorage.getItem('userId')

        let sData = {
            userId: parseInt(uid),
            contentId: c_id,
            mode: c_mode
        }

        if (c_style == 'bi bi-star-fill') {
            sMethod = 'delete'
            sData = {
                userId: parseInt(uid),
                contentId: c_id
            }
        }

        axios({
            method: sMethod,
            url: sUrl,
            data: sData
        })
            .then((response) => {
                console.log(response.data);

                let nMenu = menu.slice()
                for (var i in nMenu) {
                    if (sMethod == 'post') {
                        if (nMenu[i].id == c_id)
                            nMenu[i].favoStyle = 'bi bi-star-fill';
                    } else {
                        if (nMenu[i].id == c_id)
                            nMenu[i].favoStyle = 'bi bi-star';
                    }
                }
                setMenu(nMenu)
                //getFavorite(nMenu)
                getData()
               
                let msg = t("已加入常用功能")
                if (c_style == 'bi bi-star-fill') msg ='已刪除常用功能'

                Swal.fire({
                    title: msg,
                    icon: 'success',
                    showCancelButton: false,
                    timer: 2000,
                    showConfirmButton: false,
                    confirmButtonText: '確定'
                  })
            })
            .catch((error) => {
                console.log(error)
            })

    }

    function getFavorite(mrr) {
        //event.preventDefault();
        //alert('add ' + id)

        const uid = localStorage.getItem('userId')

        let sUrl = sysConfig.apiUrl + 'Favorite?userId=' + uid
        let sMethod = 'get'
        axios({
            method: sMethod,
            url: sUrl
        })
            .then((response) => {
                console.log('getFavorite', response.data)
                setFavorite(response.data)
                let favs = response.data

                // for (var i in mrr) {
                //     var index = favs.findIndex(x => x.contentId == mrr[i].id)
                //     if (index > -1)
                //         mrr[i].favoStyle = 'bi bi-star-fill'
                //     else
                //         mrr[i].favoStyle = 'bi bi-star'
                // }
                data.forEach((d, i1) => {
                    d.links.forEach((e, i2) => {
                        var index = favs.findIndex(x => x.contentId == d.link_id)
                        if (index > -1)
                            data[i1].links[i2].favoStyle = 'bi bi-star-fill'
                        else
                            data[i1].links[i2].favoStyle = 'bi bi-star'
                    });
                });


            })
            .catch((error) => {
                console.log(error)
            })
            .then(() => {
                if (mrr.length > 0)
                    setMenu(mrr)

                showLoading(false)
            })

    }

    function checkIsAddFavorite(id) {
        let isAdd = false
        favorite.some(f => {
            if (f.contentId == id) {
                isAdd = true
                return true
            }
        })
        return isAdd
    }

    const Main = (
        <>
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>{t("歡迎使用 iCGU")}</h1>
                    {/* <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item active">Dashboard</li>
                        </ol>
                    </nav> */}
                </div>
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="card card-home recent-sales home-rss overflow-auto">

                                        <div className="card-body">

                                            {/* <h5 className="card-title">
                                                {
                                                    rssData != null ?
                                                        <p>{rssData.channel.title.cdata_section}</p>
                                                        :
                                                        <></>
                                                }
                                            </h5> */}

                                            <ul class="nav nav-tabs pt-3" id="myTab" role="tablist">
                                                {
                                                    rssBlock.map((item, index) => (
                                                        <li class="nav-item" role="presentation">
                                                            <button onClick={() => getRss(item.link)} className={index == 0 ? "nav-link active" : "nav-link"} id={"tabh" + index} data-bs-toggle="tab" data-bs-target={"#tab" + index} type="button" aria-selected={index == 0 ? "true" : "false"}>
                                                                {trans(i18n.language, item.name, item.ename)}
                                                            </button>
                                                        </li>
                                                    ))
                                                }
                                            </ul>

                                            <div class="tab-content pt-3" id="myTabjustifiedContent">
                                                {
                                                    rssBlock.map((item, index) => (
                                                        <div className={index == 0 ? "tab-pane fade show active" : "tab-pane fade"} id={"tab" + index}  >
                                                            <table className="table table-borderless datatable">
                                                                <tbody>
                                                                    {
                                                                        rssData != null && typeof(rssData.channel.item) != 'undefined' && rssData.channel.item.length > 0 ?                                                                            
                                                                            rssData.channel.item.slice(0, rssData.channel.item.length > 4 ? 4 : rssData.channel.item.length).map((f, index) => (
                                                                                <tr>
                                                                                    <td width="88" nowrap="nowrap">{Moment(f.pubDate).format('yyyy-MM-DD')}</td>
                                                                                    <td><a href={f.link} target="_blank">{f.title.cdata_section}</a></td>
                                                                                </tr>
                                                                            ))
                                                                            :
                                                                            <>
                                                                            {
                                                                                rssData != null && typeof(rssData.channel.item) != 'undefined' && typeof(rssData.channel.item.guid) != 'undefined' ?
                                                                                <tr>
                                                                                    <td width="88" nowrap="nowrap">{Moment(rssData.channel.item.pubDate).format('yyyy-MM-DD')}</td>
                                                                                    <td><a href={rssData.channel.item.link} target="_blank">{rssData.channel.item.title.cdata_section}</a></td>
                                                                                </tr>
                                                                                :
                                                                                <center>{t("無資料")}</center>
                                                                            }
                                                                            </>
                                                                            
                                                                    }
                                                                    <tr>
                                                                        <td align="right" colSpan={2}>
                                                                            {
                                                                                rssData != null ?
                                                                                    <a href={rssData.channel.link} target="_blank">更多訊息</a>
                                                                                    :
                                                                                    <></>
                                                                            }

                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    ))
                                                }

                                            </div>




                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    {/* <div className="card card-home info-card home-block-card-top1 home-card-bg-notice overflow-hidden">

                                        <div className="card-body">
                                            <h5 class="card-title-top">{t("使用說明")}</h5>
                                            <p class="card-text notice-text">{t("請於登入後點選功能列以執行各項服務。")}</p>
                                        </div>
                                    </div> */}
                                    <div class="card card-home home-card-bg-notice overflow-hidden">
                                        {
                                            i18n.language == 'en'?
                                            <div class="card-body" style={{paddingBottom: '10px'}} ><h5 class="card-title-top">Login Instructions</h5>
                                                <dl class="card-text">
                                                    <dt>Enter your school's M365 account</dt>
                                                    <dt>
                                                        <ul style={{paddingLeft: '1rem', marginBottom: '0rem'}}>
                                                            <li>For students: Student_ID@cgu.edu.tw</li>
                                                            <li>For faculty and staff: Employee_ID@cgu.edu.tw</li>
                                                        </ul>
                                                    </dt>
                                                    <dt>Password: Your SSO password</dt>
                                                </dl>
                                            </div>
                                            :
                                            <div class="card-body" style={{paddingBottom: '10px'}}><h5 class="card-title-top">登入說明</h5>
                                                <dl class="card-text">
                                                    <dt>輸入本校 M365 帳號</dt>
                                                    <dt>
                                                        <ul style={{paddingLeft: '1rem', marginBottom: '0rem'}}>
                                                            <li>學生：學號@cgu.edu.tw</li>
                                                            <li>教職員：員工編號@cgu.edu.tw</li>
                                                        </ul>
                                                    </dt>
                                                    <dt>M365 密碼為單一登入密碼</dt>
                                                </dl>
                                            </div>
                                        }                                        
                                    </div>

                                    {
                                        true ?
                                            data.map((m, index) => {
                                                if (index != 0) return
                                                return (
                                                    <div className="card card-home info-card home-block-card-top2">
                                                        <div className="card-body">
                                                            <h5 class="card-title">{trans(i18n.language, m.name, m.ename)}</h5>
                                                            <ul class="list-group">
                                                                {
                                                                    m.links.map(n => {
                                                                        return (
                                                                            <li class="list-group-item home-block">
                                                                                {
                                                                                    checkIsAddFavorite(n.link_id) ?
                                                                                    <i title={t("刪除常用功能")} onClick={() => addFavorite(n.link_id, 'link', 'bi bi-star-fill')} className={"bi bi-star-fill" + " bi-favo"}></i> 
                                                                                    : 
                                                                                    <i title={t("加入常用功能")} onClick={() => addFavorite(n.link_id, 'link', 'bi bi-star')} className={"bi bi-star" + " bi-favo"}></i>
                                                                                }
                                                                                <a href={n.url} target="_blank" onClick={() => AddLog(sysConfig, n.link_id, 'link')}>                                                                                
                                                                                    {
                                                                                        n.iconpath == null || n.iconpath == '' ?
                                                                                            <>
                                                                                                <img className="home-block-img" src="./img/t1.png" />
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <img className="home-block-img" src={sysConfig.imgUrl + 'link/' + n.iconpath} />
                                                                                            </>
                                                                                    }
                                                                                    <span className="home-block-name">{trans(i18n.language, n.name, n.ename)}</span>
                                                                                </a>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <></>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="row">
                                {
                                    true ?
                                        data.map((m, index) => {

                                            if (index == 0) return
                                            // let cardStyle = "card card-home info-card home-block-card home-card-bg"
                                            // if (index % 2 == 0) {
                                            //     cardStyle = "card card-home info-card home-block-card"
                                            // }
                                            let cardStyle = "card card-home info-card home-block-card";

                                            return (
                                                <div className="col-xxl-4 col-md-4 ">
                                                    <div className={cardStyle} >
                                                        <div className="card-body" >
                                                            <h5 class="card-title">{trans(i18n.language, m.name, m.ename)}</h5>
                                                            <ul class="list-group">
                                                                {
                                                                    m.links.map(n => {
                                                                        return (
                                                                            <li class="list-group-item home-block">
                                                                                {
                                                                                    checkIsAddFavorite(n.link_id) ?
                                                                                    <i title={t("刪除常用功能")} onClick={() => addFavorite(n.link_id, 'link', 'bi bi-star-fill')} className={"bi bi-star-fill" + " bi-favo"}></i> 
                                                                                    : 
                                                                                    <i title={t("加入常用功能")} onClick={() => addFavorite(n.link_id, 'link', 'bi bi-star')} className={"bi bi-star" + " bi-favo"}></i>
                                                                                }
                                                                                
                                                                                {
                                                                                    n.iconpath == null || n.iconpath == '' ?
                                                                                        <>
                                                                                            <img className="home-block-img" src="./img/t1.png" />
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <img className="home-block-img" src={sysConfig.imgUrl + 'link/' + n.iconpath} />
                                                                                        </>
                                                                                }

                                                                                <a href={n.url} target="_blank" onClick={() => AddLog(sysConfig, n.link_id, 'link')}>
                                                                                    <span className="home-block-name">{trans(i18n.language, n.name, n.ename)}</span>
                                                                                </a>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <></>
                                }


                            </div>
                        </div>

                    </div>
                </section>
            </main>
        </>
    )

    return (
        <Layout>
            {Main}
        </Layout>

    )
}

export default Home

