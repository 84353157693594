
const ScAuth = () => {
    const queryParams = new URLSearchParams(window.location.search)

    try {
        const token = queryParams.get("skey")
        const sobj = atob(token)
        const srr = sobj.split('@')
        if(srr.length == 2) {
            localStorage.setItem('stid', srr[0])
            //window.location.href = '/' + srr[1]
        }
        console.log('sobj', sobj)        
    } catch {
        localStorage.removeItem('stid')
        console.log('skey', 'error')
    }

}

export default ScAuth