import { useTranslation } from "react-i18next";
import React from "react"
import { useContext, useState } from "react";
import axios from "axios";
import { AppContext } from '../App';
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import {getSkey} from './Util'

function chgSiderBar() {
    if (document.body.className === 'toggle-sidebar')
        document.body.className = ''
    else
        document.body.className = 'toggle-sidebar'
}

function Logout() {
    localStorage.removeItem("token")
    localStorage.removeItem("role")   
    localStorage.removeItem("userId")  
    localStorage.removeItem("LoginId")    
    localStorage.removeItem("userName")   

    if(window.getIsSSOLogin() == true) 
    {
        window.logoutSSO()
    } 
    else 
    {
      window.location.href = "/"
    }
}

const Header = () => {
    const { t, i18n  } = useTranslation();
    const userStr = localStorage.getItem('userName')
    const userName = localStorage.getItem('userName')
    const userId = localStorage.getItem('LoginId')
    
    const defMenu = []
    const {sysName, setSysName, sysConfig, favorite, setFavorite, isLogin, setIsLogin } = useContext(AppContext) 
    const [menu, setMenu] = useState(defMenu)
    
    const apiUrl = sysConfig.apiUrl + 'Menu/top'

    let navigate = useNavigate();

    React.useEffect(() => {        
        getSSOInfo()
        if(localStorage.getItem('LoginId') == null) return
        axios.get(apiUrl).then((response) => {

            console.log('menu', response.data)
            const menus = response.data
            let mrr = []          
                        
            for(var i in menus) {
                var obj = menus[i]
                var mobj = {}
                if(obj.mode == 'fun') {
                    mobj =  {
                        id: obj.menu_id,
                        name: obj.name,
                        path: obj.content_dictionary[0].content + getSkey(),
                        icon: "bi-grid",
                        out: true,
                        sub: null
                    }
                }
                if(obj.mode == 'link') {
                    mobj =  {
                        id: obj.menu_id,
                        name: obj.name,
                        path: obj.content_dictionary[0].content,
                        icon: "bi-link",
                        out: true,
                        sub: null
                    }
                }
                if(obj.mode == 'page') {
                    mobj =  {
                        id: obj.menu_id,
                        cid: obj.content_dictionary[0].content_id,
                        mode: obj.mode,
                        name: obj.name,
                        path: '/pageshow?id='+obj.content_dictionary[0].content_id,
                        icon: "bi-card-list",
                        out: false,
                        sub: null
                    }
                }
                mrr.push(mobj)
            }
            
            setMenu(mrr)
            
            if(window.getIsSSOLogin() == true)
            {
                tokenSSO()
            } 
            else {
                checkAuth()
            }
            

        
        });

        
    }, []);
    
    function tokenSSO() {
        
        let sUrl = sysConfig.apiUrl + 'Auth/TokenSSO' 
        let sMethod = 'post'            
        let param = {
            "uId": localStorage.getItem('LoginId'),
            "uName": localStorage.getItem('userName'),
            "uRole": "user"
          }

        if(param.uId.indexOf('@') == -1)    return
        axios({
          method: sMethod,
          data: param,
          url: sUrl
        })
          .then((response) => {
            console.log('tokenSSO',response.data)
            if(response.data.code == 200) {
                localStorage.setItem('userId', response.data.data.user.id)
                localStorage.setItem('dep', response.data.data.user.dep)
                getFavorite()
            } else {
                console.log('tokenSSO Error',response.data)
            }            

          })
          .catch((error) => {
            console.log(error)
          })

    }    

    function checkAuth() {
        const token = localStorage.getItem('token')
        if(token == null) return
        let sUrl = sysConfig.apiUrl + 'Auth/ValiToken?tokenStr=' + token
        let sMethod = 'get'            
        axios({
          method: sMethod,
          url: sUrl
        })
          .then((response) => {
            console.log('ValiToken',response.data)
            if(response.data.code == 200) {
                getFavorite()
            } else {
                console.log('ValiToken Error',response.data)
            }            

          })
          .catch((error) => {
            console.log(error)
          })

    }

    function getFavorite() {
        //event.preventDefault();
        //alert('add ' + id)
       
        const uid = localStorage.getItem('userId')

        let sUrl = sysConfig.apiUrl + 'Favorite?userId=' + uid
        let sMethod = 'get'            
        axios({
          method: sMethod,
          url: sUrl
        })
          .then((response) => {
            console.log('header getFavorite',response.data)
            setFavorite(response.data)                 

          })
          .catch((error) => {
            console.log(error)
          })

    }

    function updName(nn) {
        setSysName(nn)
    }

    function chgLang(lang) {       
        localStorage.setItem("i18n", lang)
        window.location.reload(true)
    }

    function getSSOInfo() {
        if(window.getIsSSOLogin() == true) {
            setIsLogin(true)
        }
    }

    function search(event) {
        event.preventDefault()
        const key = event.target.query.value

        if(event.target.query.value === '') {
            Swal.fire({
              title: '請輸入功能名稱關鍵字',     
              icon: 'warning',
              showCancelButton: false,
              confirmButtonText: '確定'
            })
            return
          }
        
        navigate('/search', { state: key })      
        

    }

    return (
    <>
        <header id="header" className="header fixed-top d-flex align-items-center">
            <div className="d-flex align-items-center justify-content-between">
                <a href="./" className="logo d-flex align-items-center">
                    <img src="assets/img/logo.png" alt="" />
                    <span className="d-none d-lg-block">{sysName}</span>
                </a>
                <i className="bi bi-list toggle-sidebar-btn" onClick={chgSiderBar}></i>
            </div>
             <div className="search-bar">
                <form onSubmit={search} className="search-form d-flex align-items-center" method="POST" action="#">
                    <input type="text" name="query" placeholder="功能名稱"  />
                    <button type="submit" title="Search"><i className="bi bi-search"></i></button>
                </form>
            </div> 
            <nav className="header-nav ms-auto">
                <ul className="d-flex align-items-center">
                    {/* <li className="nav-item d-block d-lg-none">
                        <a className="nav-link nav-icon search-bar-toggle " href="#">
                            <i className="bi bi-search"></i>
                        </a>
                    </li> */}
                    {
                        menu.map(menu => (
                            
                                menu.out == false ?
                                <li className="nav-item pe-3">
                                    <Link className="nav-link nav-profile d-flex align-items-center pe-0" to={menu.path}>
                                            <i className={"bi " + menu.icon}></i>
                                            <span className="d-none d-md-block ps-2">{menu.name}</span>
                                    </Link>            
                                </li>
                                :
                                <li className="nav-item pe-3">
                                    <a className="nav-link nav-profile d-flex align-items-center pe-0" href={menu.path} target="_blank" >                           
                                        <i  className={"bi " + menu.icon}></i>
                                        <span className="d-none d-md-block ps-2">{menu.name}</span>
                                    </a>
                                </li>
                            
                            
                        ))
                    }
                    {
                    isLogin ?
                    <li className="nav-item dropdown pe-3">
                        <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                            {/* <img src="assets/img/profile-img.jpg" alt="Profile" className="rounded-circle" /> */}
                            <i  className="bi bi-star-fill"></i>
                            <span className="d-none d-md-block dropdown-toggle ps-2">{t("常用功能")}</span>
                        </a>

                        {
                        favorite.length > 0 ?
                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">                         
                           {
                            favorite.map(f => {
                                if(f.mode == 'link') {
                                    return (
                                    <li>                                                                                                         
                                        <a  className="dropdown-item d-flex align-items-center" href={f.content} target="_blank">
                                            <i className="bi bi-link"></i>
                                            <span>{f.name}</span>
                                        </a>                                    
                                    </li>
                                    )
                                } else {
                                    return (
                                        <li>                                                                                                         
                                            <a  className="dropdown-item d-flex align-items-center" href={f.content} target="_blank">
                                                <i className="bi bi-grid"></i>
                                                <span>{f.name}</span>
                                            </a>                                    
                                        </li>
                                        )
                                }
                            
                            })                            
                           }                            
                        </ul>
                        :
                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                            <li>                                                                                                         
                                <a  className="dropdown-item d-flex align-items-center" >                                    
                                    <span>無資料</span>
                                </a>                                    
                            </li>
                        </ul>
                        }
                    </li>
                    :
                    <div></div>
                    }
                    <li className="nav-item dropdown pe-3">
                        <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                            {/* <img src="assets/img/profile-img.jpg" alt="Profile" className="rounded-circle" /> */}
                            <i  className="bi bi-globe"></i>
                            <span className="d-none d-md-block dropdown-toggle ps-2">{t("語言")}</span>
                        </a>

                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">                         
                   

                            <li>
                                <a   className="dropdown-item d-flex align-items-center" href="#" onClick={() => chgLang('zh_tw')}>
                                    {
                                        localStorage.getItem('i18n') == null || localStorage.getItem('i18n') == 'zh_tw' ?
                                        <i className="bi bi-flag-fill"></i>
                                        :
                                        <i className="bi bi-flag"></i>
                                    }                                    
                                    <span>{t("中文")}</span>
                                </a>                                
                            </li>
                            <li>
                                <a   className="dropdown-item d-flex align-items-center" href="#" onClick={() => chgLang('en')}>
                                    {
                                        localStorage.getItem('i18n') == 'en' ?
                                        <i className="bi bi-flag-fill"></i>
                                        :
                                        <i className="bi bi-flag"></i>
                                    }
                                    <span>{t("英文")}</span>
                                </a>                                
                            </li>
                            {/* <li>
                                <a href="javascript:void(0)"  className="dropdown-item d-flex align-items-center" onClick={() => updName('test')}>
                                    <i className="bi bi-heptagon-fill"></i>
                                    <span>test</span>
                                </a>                                
                            </li> */}
                        </ul>
                    </li>
                    {
                    isLogin ?
                    <li className="nav-item dropdown pe-3">
                        <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                            {/* <img src="assets/img/profile-img.jpg" alt="Profile" className="rounded-circle" /> */}
                            <i  className="bi bi-person"></i>
                            <span className="d-none d-md-block dropdown-toggle ps-2">{userStr}</span>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">                         
                            <li className="dropdown-header">
                            <h6>{userName}</h6>
                            <span>{userId}</span>
                            </li>
                            <li>
                            <hr className="dropdown-divider"/>
                            </li>

                            <li>
                                <a  className="dropdown-item d-flex align-items-center" href="javascript:chgStyle('def');">
                                    <i className="bi bi-heptagon"></i>
                                    <span>{t("淺色主題")}</span>
                                </a>
                            </li>
                            <li>
                                <a   className="dropdown-item d-flex align-items-center" href="javascript:chgStyle('dark');">
                                    <i className="bi bi-heptagon-fill"></i>
                                    <span>{t("深色主題")}</span>
                                </a>                                
                            </li>     
                            <li>
                                <a   className="dropdown-item d-flex align-items-center" href="#" onClick={() => Logout() } >
                                    <i className="bi bi-box-arrow-left"></i>
                                    <span>{t("登出")}</span>
                                </a>                                
                            </li>                          
                        </ul>
                    </li>
                    :
                    <>
                    <li className="nav-item pe-3">
                        <Link className="nav-link nav-profile d-flex align-items-center pe-0" to="/login">
                                <i className="bi bi-box-arrow-right"></i>
                                <span className="d-none d-md-block ps-2">{t("系統登入")}</span>
                        </Link>            
                    </li>
                    <li className="nav-item pe-3">
                        <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#"  onClick={() => window.loginSSO()} >
                                <i className="bi bi-box-arrow-right"></i>
                                <span className="d-none d-md-block ps-2">{t("Azure登入")}</span>
                        </a>            
                    </li>
                    </>
                    }
                </ul>
            </nav>
           
             
        </header>
    </>
    )
}
export default Header