import React from "react"
import Layout from "../common/Layout"
import { useState, useEffect, useContext } from 'react'
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AppContext } from '../App';
import Swal from 'sweetalert2'
import ReSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { useTranslation } from "react-i18next";

const Role = () => {
  const { t, i18n } = useTranslation();
  const pageName = '角色管理'
  const { sysConfig } = useContext(AppContext)

  const [modalShow, setModalShow] = React.useState(false);
  const [isLoading, setLoading] = useState(true);
  const [mData, setmData] = useState();
  const [selectedFile, setSelectedFile] = useState();
  let isOpen = false

  const [fId, setfId] = useState()
  const [fName, setfName] = useState()
  const [fDesc, setfDesc] = useState()
  const [fGroup, setfGroup] = useState()
  const [cGroup, setcGroup] = useState()
  const [defTag, setDefTag] = useState()
  const [fMenu, setfMenu] = useState()
  const [homeTag, setHomeTag] = useState()

  const [defMenuLeft, setDefMenuLeft] = useState()
  const [defMenuTop, setDefMenuTop] = useState()
  const [defMenuBottom, setDefMenuBottom] = useState()
  const [defMenuBlock, setDefMenuBlock] = useState()
  const [defMenuTag, setDefMenuTag] = useState()

  const initObj = {
    link_id: "",
    link_group_id: "",
    name: "",
    description: "",
    url: "",
    url_target: 0,
    is_system_default: true
  }
  const [fData, setfData] = useState(initObj);

  function getdata() {
    const apiUrl = sysConfig.apiUrl + "Role"

    axios.get(apiUrl).then(response => {
      setTimeout(() => {
        setmData(response.data);
        console.log(response.data)
        setLoading(false);

      }, 1);

    });
  }

  function getMenu() {
    const apiUrl = sysConfig.apiUrl + "Menu"

    axios.get(apiUrl).then(response => {

      console.log('menu', response.data)
      setfMenu(response.data)
    });
  }

  function getHomeTag() {
    const apiUrl = sysConfig.apiUrl + "Page?id=00db220e-ffdf-4a7e-8a42-416adfa55db9"

    axios.get(apiUrl).then(response => {

      console.log('getHomeTag', response.data[0].contents)
      setHomeTag(response.data[0].contents)
    });
  }


  function upsert(index) {
    setDefMenuLeft([])
    setDefMenuTop([])
    setDefMenuBottom([])
    setDefMenuBlock([])
    setDefMenuTag([])
    
    setModalShow(true)
    if (index > -1) {
      const obj = mData[index]
      //const index = mData.findIndex(a => a.function_id == id)
      // setfId(obj.function_id)
      // setfName(obj.name)
      // setfDesc(obj.description)
      setfData(obj)

      let tArrLeft = []
      let tArrTop = []
      let tArrBottom = []
      let tArrBlock = []
      let tArrTag = []
      for (var i in obj.menulist) {
        var mid = obj.menulist[i]
        var menu = fMenu.find(function(item){return item.menu_id == mid})   
        if(typeof(menu) != 'undefined') {
          if(menu.position == 'left')    
            tArrLeft.push({ value: mid, label: menu.name })
          if(menu.position == 'top')    
            tArrTop.push({ value: mid, label: menu.name })
          if(menu.position == 'bottom')    
            tArrBottom.push({ value: mid, label: menu.name })
          if(menu.position == 'block')    
            tArrBlock.push({ value: mid, label: menu.name })         
        }
        var tag = homeTag.find(function(item){return item.contentId == mid})  
        if(typeof(tag) != 'undefined') {
          tArrTag.push({ value: mid, label: tag.name })
        }
      }
     
      setDefMenuLeft(tArrLeft);
      setDefMenuTop(tArrTop);
      setDefMenuBottom(tArrBottom);
      setDefMenuBlock(tArrBlock);
      setDefMenuTag(tArrTag);
    }
    else {
      setfData(initObj)
    }
  }

  function save(event) {
    event.preventDefault();
    console.log(event.target.iName.value)

    let chk = [];

    // left
    for (var j = 0; j < event.target.iMenuLeft.length; j++) {
      chk.push(event.target.iMenuLeft[j].value)
    }
    if (typeof (event.target.iMenuLeft.defaultValue) != 'undefined') {
      if (event.target.iMenuLeft.defaultValue != '')
        chk.push(event.target.iMenuLeft.defaultValue)
    }

    // top
    for (var j = 0; j < event.target.iMenuTop.length; j++) {
      chk.push(event.target.iMenuTop[j].value)
    }
    if (typeof (event.target.iMenuTop.defaultValue) != 'undefined') {
      if (event.target.iMenuTop.defaultValue != '')
        chk.push(event.target.iMenuTop.defaultValue)
    }

    // bottom
    for (var j = 0; j < event.target.iMenuBottom.length; j++) {
      chk.push(event.target.iMenuBottom[j].value)
    }
    if (typeof (event.target.iMenuBottom.defaultValue) != 'undefined') {
      if (event.target.iMenuBottom.defaultValue != '')
        chk.push(event.target.iMenuBottom.defaultValue)
    }

    // tag
    for (var j = 0; j < event.target.iMenuTag.length; j++) {
      chk.push(event.target.iMenuTag[j].value)
    }
    if (typeof (event.target.iMenuTag.defaultValue) != 'undefined') {
      if (event.target.iMenuTag.defaultValue != '')
        chk.push(event.target.iMenuTag.defaultValue)
    }

    console.log('cgroup chk', chk)

    if (event.target.iName.value === '') {
      Swal.fire({
        title: '請輸入名稱',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: '確定'
      }
      )
      return
    }

    let sUrl = sysConfig.apiUrl + 'Role'
    let sMethod = 'post'

    let sData = {     
      name: event.target.iName.value,     
      menulist: chk
    }


    if (fData.id != '') {
      sData.id =  fData.id
      sMethod = 'put'
    }

    axios({
      method: sMethod,
      url: sUrl,
      data: sData
    })
      .then((response) => {

        console.log(response.data);

        setModalShow(false)
        getdata()

      })
      .catch((error) => {
        console.log(error)
      })

  }

  function del(index) {

    Swal.fire({
      title: '確定刪除這筆資料',
      //text: "確定刪除這筆資料?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '確定',
      cancelButtonText: '取消'
    }).then((result) => {
      if (result.isConfirmed) {
        const obj = mData[index]
        let sUrl = sysConfig.apiUrl + 'Link/' + obj.link_id
        let sMethod = 'delete'

        const ndata = mData.filter(item => item.link_id !== obj.link_id)
        setmData(ndata)

        axios({
          method: sMethod,
          url: sUrl
        })
          .then((response) => {
            if (response.data.success) {
              Swal.fire({
                title: '刪除成功',
                icon: 'success',
                showCancelButton: false,
                timer: 2000,
                confirmButtonText: '確定'
              })

            } else {
              const rdata = response.data.data
              let rmsg = ''
              for (var i in rdata) {
                rmsg += rdata[i].name + "[" + t("position_" + rdata[i].position) + "] "
              }
              Swal.fire({
                title: response.data.msg,
                text: rmsg,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: '確定'
              })
            }
            getdata()
          })
          .catch((error) => {
            console.log(error)
          })
      }
    })

    //if(window.confirm('確定刪除?') === false) return


  }

  const handleChange = e => {
    //setcGroup(Array.isArray(e) ? e.map(x => x.value) : []);
    console.log('cgroup', e)
    //setcGroup(e)
  }

  const changeFileHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  function UpsertModal(props) {
    //setOpen(props.show)
    isOpen = props.show
    console.log(isOpen)

    let menu_left = []
    let menu_top = []
    let menu_bottom = []
    let menu_block = []    

    for (var i in fMenu) {
      
      var mobj = fMenu[i]

      if(mobj.position == 'left')
        menu_left.push({ value: fMenu[i].menu_id, label: fMenu[i].name })

      if(mobj.position == 'top')
        menu_top.push({ value: fMenu[i].menu_id, label: fMenu[i].name })

      if(mobj.position == 'bottom')
        menu_bottom.push({ value: fMenu[i].menu_id, label: fMenu[i].name })

      if(mobj.position == 'block')
        menu_block.push({ value: fMenu[i].menu_id, label: fMenu[i].name })

    }

    let menu_tag = []

    for( var i in homeTag) {
      menu_tag.push({ value: homeTag[i].contentId, label: homeTag[i].name })
    }

    return (
      <Modal
        {...props}
        size="lg"
        backdrop="static"
        keyboard="false"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form onSubmit={save}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {pageName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="row g-3">
              <div className="col-md-6">
                <label className="form-label">代號</label>
                <input disabled type="text" defaultValue={fData.id} className="form-control" name="iId" />
              </div>
              <div className="col-md-6">
                <label className="form-label">名稱</label>
                <input type="text" defaultValue={fData.name} className="form-control" name="iName" />
              </div>
             
              <div className="col-md-12">
                <label className="form-label">左側選單</label>
                <ReSelect
                  defaultValue={defMenuLeft}
                  isMulti
                  name="iMenuLeft"
                  options={menu_left}
                  noOptionsMessage={() => "無項目"}
                  placeholder={"請選擇項目"}                 
                  className="basic-multi-select"
                  onChange={handleChange}
                  classNamePrefix="select"
                />
              </div>

              <div className="col-md-12">
                <label className="form-label">上方選單</label>
                <ReSelect
                  defaultValue={defMenuTop}
                  isMulti
                  name="iMenuTop"
                  options={menu_top}
                  noOptionsMessage={() => "無項目"}
                  placeholder={"請選擇項目"}                
                  className="basic-multi-select"
                  onChange={handleChange}
                  classNamePrefix="select"
                />
              </div>

              <div className="col-md-12">
                <label className="form-label">下方選單</label>
                <ReSelect
                  defaultValue={defMenuBottom}
                  isMulti
                  name="iMenuBottom"
                  options={menu_bottom}
                  noOptionsMessage={() => "無項目"}
                  placeholder={"請選擇項目"}                
                  className="basic-multi-select"
                  onChange={handleChange}
                  classNamePrefix="select"
                />
              </div>

              <div className="col-md-12">
                <label className="form-label">首頁連結分類</label>
                <ReSelect
                  defaultValue={defMenuTag}
                  isMulti
                  name="iMenuTag"
                  options={menu_tag}
                  noOptionsMessage={() => "無項目"}
                  placeholder={"請選擇項目"}                
                  className="basic-multi-select"
                  onChange={handleChange}
                  classNamePrefix="select"
                />
              </div>
             
            </div>

          </Modal.Body>
          <Modal.Footer>
            <input type="submit" name="submit" className="btn btn-primary" value="儲存" />
            <Button variant="secondary" onClick={props.onHide}>取消</Button>

          </Modal.Footer>
        </form>
      </Modal>
    );
  }



  useEffect(() => {
    getdata()
    getMenu()
    getHomeTag()

  }, [sysConfig]);

  if (isLoading) {
    return (
      <Layout>
        <main id="main" className="main">Loading...</main>
      </Layout>
    )
  }


  const mTableBody = mData.map(
    (f, index) => (
      <tr key={f.id}>
        <td>
          {f.id}
        </td>
        <td>
          {f.name}
        </td>
        <td>
          <button className="btn btn-warning" onClick={() => upsert(index)}><i className="bi bi-pencil-square"></i></button>&nbsp;          
        </td>
      </tr>
    )
  )
  const mTable = (
    <table className="table">
      <thead>
        <tr>
          <th>代號</th>
          <th>名稱</th>
          {/* <th>分類</th> */}
          <th>編輯</th>
        </tr>
      </thead>
      <tbody>
        {mTableBody}
      </tbody>
    </table>
  )
  const mBody = (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{pageName}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/">首頁</a></li>
              <li className="breadcrumb-item active">{pageName}</li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  {/* <h5 className="card-title">系統功能</h5> */}
                  <div className="row">
                    <div className="col-lg-6">
                      <h5 className="card-title">{pageName}列表</h5>
                    </div>
                    <div className="col-lg-6">
                      {/* <h5 className="card-title">
                        <button className="btn btn-secondary float-end" onClick={() => upsert(-1)}><i className="bi bi-plus-circle"></i> 新增</button>
                      </h5> */}
                    </div>
                  </div>
                  {mTable}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )


  return (
    <Layout>
      {mBody}
      <UpsertModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Layout>

  );

}
export default Role