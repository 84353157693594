import React, { useState, useContext } from "react";
import { AppContext } from '../App';
import Menu from './Siderbar'
import axios from "axios";
import HeaderCgu from './HeaderCgu'
import './Siderbar.css'
import { useTranslation } from "react-i18next";
import { getSkey, AddLog, trans, getToken } from './Util'
import { useLocation } from "react-router-dom";

export default ({ children }) => {

  const { t, i18n } = useTranslation();
  const { loading, showLoading, isLogin } = useContext(AppContext)
  const defMenu = []
  const { sysConfig } = useContext(AppContext)
  const [menu, setMenu] = useState(defMenu)
  const [pathCheck, setPathCheck] = useState(false)
  

  const loadDiv = (
    loading == true ?
      <div className="overlay">
        <div className="overlay__inner">
          <div className="overlay__content"><span className="spinner"></span></div>
        </div>
      </div>
      :
      <div></div>
  )

  const location = useLocation();
  const adminPaths = ['/fun','/weblink','/menu','/page','/tag','/rssblock','/report','/user','/role','/loginrecord']

  React.useEffect(() => {

    setDefHeader()
    
    var aindex = adminPaths.findIndex(x => x == location.pathname.toLowerCase());
    if(aindex > -1) {
      checkPathAuth()   
    } 
    else {
      setPathCheck(true)      
    }

    if(localStorage.getItem('i18n') == null || localStorage.getItem('i18n') == '') {
      if(i18n.language.indexOf('en') > -1) {
        localStorage.setItem('i18n', 'en')
        window.location.reload()
      }
      else
        localStorage.setItem('i18n', 'zh_tw')
    }
    //alert(i18n.language)
    
    
    getBottomMenu()

  }, []);

  function setDefHeader() {

    let token = ''
    token = localStorage.getItem('token')

    axios.defaults.headers.common['token'] = `${token}`;
    console.log('setDefHeader')
  }

  React.useEffect(() => {

    getBottomMenu()

  }, [isLogin]);

  function checkPathAuth() {

    const uid = localStorage.getItem('userId')
    if(uid == '' || uid == null) {
      window.location.href = '/'
    }

    let sUrl = sysConfig.apiUrl + 'User?id=' + uid
    let sMethod = 'get'
    

    axios({
        method: sMethod,
        url: sUrl
    })
        .then((response) => {
            console.log('getUser', response.data)
            
            const user = response.data[0]
            let isAuth = false
            user.roleList.forEach(x => {
              if(x.id == 'admin') {
                isAuth = true
              }
            })
            
            if(isAuth) {
              setPathCheck(true)
              window.checkMobileSider()
            } 
            else {
              window.location.href = '/'
            }
            
        })
        .catch((error) => {
            console.log(error)
            window.location.href = '/'
        })
        .then(() => {
         
      })

}

  function getBottomMenu() {
    const userId = localStorage.getItem('LoginId')
    
    let apiUrl = sysConfig.apiUrl + 'Menu/bottom?userId=' + userId
    if(userId == null) {
      apiUrl = sysConfig.apiUrl + 'Menu/Guest?position=bottom' 
    }
    axios.get(apiUrl).then((response) => {

      console.log('menu', response.data)
      const menus = response.data
      let mrr = []

      for (var i in menus) {
        var obj = menus[i]
        var mobj = {}
        if (obj.mode == 'fun') {
          mobj = {
            id: obj.menu_id,
            cid: obj.content_dictionary[0].content_id,
            mode: obj.mode,
            name: trans(i18n.language, obj.name, obj.ename),
            path: obj.content_dictionary[0].content + getSkey(),
            icon: "bi-grid",
            iconpath: obj.iconpath,
            out: true,
            sub: null
          }
        }
        if (obj.mode == 'link') {
          mobj = {
            id: obj.menu_id,
            cid: obj.content_dictionary[0].content_id,
            mode: obj.mode,
            name: trans(i18n.language, obj.name, obj.ename),
            path: obj.content_dictionary[0].content,
            icon: "bi-link",
            iconpath: obj.iconpath,
            out: true,
            sub: null
          }
        }
        if (obj.mode == 'page') {
          mobj = {
            id: obj.menu_id,
            cid: obj.content_dictionary[0].content_id,
            mode: obj.mode,
            name: trans(i18n.language, obj.name, obj.ename),
            path: '/pageshow?id=' + obj.content_dictionary[0].content_id,
            icon: "bi-card-list",
            iconpath: obj.iconpath,
            out: false,
            sub: null
          }
        }
        mrr.push(mobj)
      }

      setMenu(mrr)
      console.log('getBottomMenu', mrr)

    });
  }


  let w_style = { height: '100vh' }
  //if (isLogin) w_style = { height: '100%' }
  var style = 'def'     
  var sIcon = './img/c-menu-def.png?v=2'

  if(localStorage.getItem('style') != null) {
    style = localStorage.getItem('style')
  } 
  if(style == 'dark') {
    sIcon = './img/c-menu-b.png?v=2'
  }

  const siderbar = (

    <div className="wrapper d-flex align-items-stretch"  >
      <div onClick={() => window.chgSiderBar()} className="cgu-x-btn cgu-c-menu" >
        <img src="./img/m-menu-x.svg" />
      </div>
      <div onClick={() => window.chgSiderBar()} className="cgu-c-btn cgu-c-menu-active" id="cgu-sidebarCollapse-active">
        <img src="./img/m-menu.svg" />
      </div>
      <nav id="cgu-sidebar" className="">
        <div className="custom-menu">
          <img onClick={() => window.chgSiderBar()} src={sIcon} className="cgu-c-btn cgu-c-menu" id="cgu-sidebarCollapse" />
          <img onClick={() => window.chgSiderBar()} src="./img/c-menu-active.png" className="cgu-c-btn cgu-c-menu-active" id="cgu-sidebarCollapse-active" />



        </div>
        <div className="p-4 sidebar-menu">
          <h1><a href="/" className="logo"><img className="cgu-logo" src="./img/cgu-logo3.png" /></a></h1>

          <Menu />


          {/* <div className="footer">
              xxx
            </div> */}
        </div>
      </nav>

      {children}



    </div>


  )

  const menuBottom = (
    <nav className="navbar-mobile fixed-bottom navbar-dark">
      <div className="d-flex align-items-center justify-content-center">
        {
          menu.map((m,index) => (
            <a onClick={() => AddLog(sysConfig, m.cid, m.mode)} class="navbar-brand" href={m.path} target={m.out ? "_blank" : "_self"}>
              {/* <img src="./img/t1.png" title="預設圖示" className="img-block" /> */}
              {
                m.iconpath == '' || m.iconpath == null ?
                <img src="./img/t1.png" title="預設圖示" className="img-block" />
                :
                <img src={sysConfig.imgUrl + 'menu/' + m.iconpath} className="img-block" />
              }
              {m.name}
            </a>
          ))
        }
        
      </div>

    </nav>
  )

  return (
    <div>
      {
        pathCheck ?
        <>
         <HeaderCgu />
          {siderbar}
          {menuBottom}
          {loadDiv}
        </>
        :
        <>
          {loadDiv}
        </>     
      }
    </div>
  )
}