import React from "react"
import Layout from "../common/Layout"
import { useState, useEffect, useContext } from 'react'
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AppContext } from '../App';
import Swal from 'sweetalert2'
import CreatableSelect from 'react-select/creatable'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const Menu2 = () => {

  const pageName = '選單管理Test'
  const { sysConfig } = useContext(AppContext)

  const [modalShow, setModalShow] = React.useState(false);
  const [isLoading, setLoading] = useState(true);
  const [mData, setmData] = useState();
  let isOpen = false

  const [fId, setfId] = useState()
  const [fName, setfName] = useState()
  const [fDesc, setfDesc] = useState()

  const [fMode, setfMode] = useState('fun')
  const [sFun, setsFun] = useState([])
  const [sLink, setsLink] = useState([])

  const [tab, setTab] = useState('left')
  const [tabName, setTabName] = useState('左側')
  const [showTagSelect, setshowTagSelect] = useState(false)
  const [tmode, settmode] = useState('fun')
  const initObj = {
    menu_id: '',
    name: "",
    icon: "",
    content_dictionary: [],
    position: "",
    mode: ""
  }
  const [fData, setfData] = useState(initObj);

  function getdata(position) {

    let apiUrl = sysConfig.apiUrl + "Menu"

    if (typeof (position) != 'undefined') apiUrl += '/' + position

    axios.get(apiUrl).then(response => {
      setTimeout(() => {
        setmData(response.data);
        console.log(response.data)
        setLoading(false);

      }, 0);

    });

    let endpoints = [
      sysConfig.apiUrl + "Function",
      sysConfig.apiUrl + "Link"
    ];
    axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
      (data) => {
        console.log('all', data[0], data[1])
        setsFun(data[0].data)
        setsLink(data[1].data)
      }
    );


  }

  function changeTab(mode) {
    if (mode == 'left') setTabName('左側')
    if (mode == 'top') setTabName('上方')
    if (mode == 'bottom') setTabName('下方')
    setTab(mode)
    getdata(mode)
  }

  function upsert(index) {

    setModalShow(true)

    if (index > -1) {
      const obj = mData[index]
      //const index = mData.findIndex(a => a.function_id == id)
      // setfId(obj.function_id)
      // setfName(obj.name)
      // setfDesc(obj.description)
      setfData(obj)
    }
    else {
      setfData(initObj)
    }


  }

  function save(event) {
    event.preventDefault();
    console.log(event.target.iName.value)

    if (event.target.iName.value === '') {
      Swal.fire({
        title: '請輸入名稱',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: '確定'
      }
      )
      return
    }

    let sUrl = sysConfig.apiUrl + 'Menu'
    let sMethod = 'post'

    let sData = {
      name: event.target.iName.value,
      content_dictionary: [event.target.selContent.value],
      icon: 'def',
      position: tab,
      mode: event.target.selMode.value
    }


    if (fData.menu_id != '') {
      sUrl += '/' + fData.menu_id
      sMethod = 'put'
    }

    axios({
      method: sMethod,
      url: sUrl,
      data: sData
    })
      .then((response) => {
        console.log(response.data);
        setModalShow(false)
        getdata(tab)
      })
      .catch((error) => {
        console.log(error)
      })

  }

  function del(index) {

    Swal.fire({
      title: '確定刪除這筆資料',
      //text: "確定刪除這筆資料?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '確定',
      cancelButtonText: '取消'
    }).then((result) => {
      if (result.isConfirmed) {
        const obj = mData[index]
        let sUrl = sysConfig.apiUrl + 'Menu/' + obj.menu_id
        let sMethod = 'delete'

        const ndata = mData.filter(item => item.menu_id !== obj.menu_id)
        setmData(ndata)

        axios({
          method: sMethod,
          url: sUrl
        })
          .then((response) => {
            console.log(response.data);
            getdata(tab)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    })

    //if(window.confirm('確定刪除?') === false) return


  }

  function changeMode(e) {
    setfMode(e.target.value)
    //menuSel = (<div>xxx</div>)
  }


  let sValue = ''
  function UpsertModal(props) {


    if (fData.content_dictionary.length > 0) {
      sValue = fData.content_dictionary[0].content_id
    }
    setfId(sValue)


    if (fData.menu_id != '') {
      setfMode(fData.mode)
    }

    function chgFun(event) {
      //alert(event.target.value)
      // if(event.target.value == '08db0a62-58a3-4bdc-8dc4-fc5faf1ae07b')
      // {
      //   setshowTagSelect(true)
      // } 
      // else {
      //   setshowTagSelect(false)
      // }
    }

    const menuSel = (
      <>
        {
          fMode === 'fun' ?
            <div className="col-md-6"  >
              <label className="form-label">選擇功能</label>
              <select id="inputState" onChange={chgFun} name="selContent" className="form-select" defaultValue={sValue}>
                {
                  sFun.map((s) => (
                    <option value={s.function_id}>{s.name}</option>
                  ))
                }
              </select>
            </div>
            :
            <div className="col-md-6">
              <label className="form-label">選擇連結</label>
              <select id="inputState" name="selContent" className="form-select" defaultValue={sValue}>
                {
                  sLink.map(s => (
                    <option value={s.link_id}>{s.name}</option>
                  ))
                }
              </select>
            </div>
        }
      </>
    )

    //setOpen(props.show)
    isOpen = props.show
    console.log(isOpen)

    return (
      <Modal
        {...props}
        size="lg"
        backdrop="static"
        keyboard="false"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form onSubmit={save}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {pageName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="row g-3">
              <div className="col-md-3">
                <label className="form-label">類型</label>
                <select id="selMode" name="selMode" defaultValue={fMode} className="form-select" onChange={changeMode}>
                  <option value="fun">功能</option>
                  <option value="link">連結</option>
                </select>
              </div>
              {menuSel}
              <div className="col-md-3">
                <label className="form-label">顯示名稱</label>
                <input type="text" defaultValue={fData.name} className="form-control" name="iName" />
              </div>
              {
                showTagSelect ?
                  <div className="col-md-12">
                    <label className="form-label">連結分類</label>
                    <CreatableSelect
                      defaultValue={[]}
                      isMulti
                      name="iGroup"
                      options={[]}
                      noOptionsMessage={() => "無項目"}
                      placeholder={"請選擇項目"}
                      formatCreateLabel={(inputText) => "新增: " + inputText}
                      className="basic-multi-select"
                      //onChange={handleChange} 
                      classNamePrefix="select"
                    />
                  </div>
                  :
                  <></>
              }

            </div>

          </Modal.Body>
          <Modal.Footer>
            <input type="submit" name="submit" className="btn btn-primary" value="儲存" />
            <Button variant="secondary" onClick={props.onHide}>取消</Button>

          </Modal.Footer>
        </form>
      </Modal>
    );
  }



  useEffect(() => {
    getdata(tab)
    console.log('tab', tab)
  }, [sysConfig]);

  if (isLoading) {
    return (
      <Layout>
        <main id="main" className="main">Loading...</main>
      </Layout>
    )
  }

  function getMenuMode(code) {
    switch (code) {
      case 'fun': return '功能'
      case 'link': return '連結'
    }
  }

  const mTableBody = mData.map(
    (f, index) => (
      <tr key={f.link_id}>
        <td>{f.name}</td>
        <td>{getMenuMode(f.mode)}</td>
        <td>
          <button className="btn btn-warning" onClick={() => upsert(index)}><i className="bi bi-pencil-square"></i></button>&nbsp;
          <button className="btn btn-danger" onClick={() => del(index)}><i className="bi bi-trash"></i></button>
        </td>
      </tr>
    )
  )

  const mTable = (
    <table className="table">
      <thead>
        <tr>
          <th>名稱</th>
          <th>類型</th>
          <th>編輯</th>
        </tr>
      </thead>
      <tbody>
        {mTableBody}
      </tbody>
    </table>
  )

  function chgSel(event) {
    //alert(event.target.value)
    settmode(event.target.value)
  }
  function btnTest() {
    settmode('fun')
  }

  const mBody = (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{pageName}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/">首頁</a></li>
              <li className="breadcrumb-item active">{pageName}</li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  {/* <h5 className="card-title">系統功能</h5> */}
                  <div className="row">
                    <div className="col-lg-3">
                      <h5 className="card-title">{tabName}{pageName}列表</h5>
                    </div>



                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div class="modal-dialog  modal-dialog-centered">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div class="modal-body">

                            <select onChange={chgSel} className="form-select m-2">
                              <option value="fun" selected>fun</option>
                              <option value="link">link</option>
                            </select>
                            {
                              tmode == 'fun' ?
                                <select className="form-select m-2">
                                  <option value="A">A</option>
                                  <option value="B">B</option>
                                </select>
                                :
                                <select className="form-select m-2">
                                  <option value="C">C</option>
                                  <option value="D">D</option>
                                </select>

                            }




                          </div>
                          <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Save changes</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-9">
                      <h5 className="card-title">

                        <button onClick={btnTest} type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                          Launch demo modal
                        </button>

                        <button className="btn btn-secondary" onClick={() => changeTab('left')}> 左側選單</button>&nbsp;
                        <button className="btn btn-secondary" onClick={() => changeTab('top')}> 上方選單</button>&nbsp;
                        <button className="btn btn-secondary" onClick={() => changeTab('bottom')}> 下方選單</button>
                        <button className="btn btn-secondary float-end" onClick={() => upsert(-1)}><i className="bi bi-plus-circle"></i> 新增</button>
                      </h5>
                    </div>
                  </div>
                  {mTable}
                </div>
              </div>
              {/* <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="home" title="左側選單" onClick={index => setTab('left')}  >
                  
                </Tab>
                <Tab eventKey="profile" title="上方選單" onSelect={index => setTab('top')}>
                
                </Tab>
                <Tab eventKey="contact" title="下方選單" onSelect={s => setTab('bottom')}>
               
                </Tab>
              </Tabs> */}


            </div>
          </div>
        </section>
      </main>
    </>
  )


  return (
    <Layout>
      {mBody}
      <UpsertModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Layout>

  );

}
export default Menu2