import ScHeader from "./ScHeader";

import React, {useState, useContext} from "react";
import { AppContext } from '../../App';
import axios from "axios";
import HeaderCgu from "../../common/HeaderCgu";

export default ({ children }) => {

  const {loading, showLoading } = useContext(AppContext) 

  let closeHeader = false
  if(window.location.href.indexOf('course?booking=1') > -1
    || window.location.href.indexOf('course?f=1') > -1) {
    closeHeader = true
  }

  React.useEffect(() => {
    setDefHeader()
  }, []);

  function setDefHeader() {

    let token = ''
    token = localStorage.getItem('tokenSc')

    axios.defaults.headers.common['token'] = `${token}`;
    console.log('setDefHeader')
    
  }

  const loadDiv = (
    loading == true ?
    <div className="overlay">
          <div className="overlay__inner">
              <div className="overlay__content"><span className="spinner"></span></div>
          </div>
      </div>
      :
      <div></div>
  )
  return(
    <div>
      
      {loadDiv}
      {/* <ScHeader/>      */}
      {
        closeHeader == false ?
        <HeaderCgu body='sc'/>
        :
        <></>
      }
      
      {children}
     
    </div>
  )
}