import React from "react"
import Layout from "../common/Layout"
import { useState, useEffect, useContext } from 'react'
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AppContext } from '../App';
import Swal from 'sweetalert2'
import { useTranslation } from "react-i18next";
import { trans, AddLog } from '../common/Util'
const PageShow = () => {
  const { t, i18n } = useTranslation();
  const { sysConfig, favorite, setFavorite, pageid, setpageid } = useContext(AppContext)
  const [loading, openLoading] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [isLoading, setLoading] = useState(true);
  const [functions, setFuntions] = useState({ contents: [] });
  let isOpen = false

  const [fId, setfId] = useState()
  const [fName, setfName] = useState()
  const [fDesc, setfDesc] = useState()  

  const queryParams = new URLSearchParams(window.location.search)
  let id = queryParams.get("id")
  setpageid(id)

  const [fData, setfData] = useState({
    function_id: "",
    name: "",
    description: ""
  });

  function updFavorite() {

    const uid = localStorage.getItem('userId')
    let apiUrl = sysConfig.apiUrl + 'Favorite?userId=' + uid

     // get Favorite
    axios({
        method: 'get',
        url: apiUrl
    })
        .then((response) => {
            console.log('getFavorite', response.data)
            setFavorite(response.data)

            getdata()



        })
        .catch((error) => {
            console.log(error)
        })
        .then(() => {

             
        })


}

  function getdata() {
     openLoading(true)
    const apiUrl = sysConfig.apiUrl + "Page?id=" + pageid
    console.log('pageshow apiUrl', apiUrl)

    axios.get(apiUrl).then(response => {
      setTimeout(() => {
        if(response.status == 200) {
          setFuntions(response.data[0]);
          console.log(response.data)
          setLoading(false);
          openLoading(false)
        } 
        else {
          Swal.fire({
            title: t('請登入長庚大學帳號'),
            icon: 'info',
            showCancelButton: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: t('登入')
          }).then((result) => {
            window.location.href = '/'
          })
        }
      }, 1);

    }).catch((error) => {
      console.log(error)
    })
    //  const uid = localStorage.getItem('userId')
    //     let apiUrl = sysConfig.apiUrl + 'Favorite?userId=' + uid

    //      // get Favorite
    //     axios({
    //         method: 'get',
    //         url: apiUrl
    //     })
    //         .then((response) => {
    //             console.log('getFavorite', response.data)
    //             setFavorite(response.data)

    //             apiUrl = sysConfig.apiUrl + "Page?id=" + id

    //             // get PageContent
    //             axios.get(apiUrl).then((response) => {
    //                // showLoading(false)
    //                 console.log('PageContent', response.data)

    //                 setFuntions(response.data[0].contents)
    //                 setLoading(false);
    //                 openLoading(false)
    //             });


    //         })
    //         .catch((error) => {
    //             console.log(error)
    //         })
    //         .then(() => {

    //             //showLoading(false)
    //         })
  }

  function test() {
    setfName('ff')
  }

  function upsert(index) {
    setModalShow(true)
    if (index > -1) {
      const obj = functions[index]
      //const index = functions.findIndex(a => a.function_id == id)
      setfId(obj.function_id)
      setfName(obj.name)
      setfDesc(obj.description)
      setFuntions(functions)
    }
    else {
      setfId('')
      setfName('')
      setfDesc('')
    }
  }

  function save(event) {
    event.preventDefault();
    console.log(event.target.iName.value)

    if (event.target.iName.value === '') {
      Swal.fire({
        title: '請輸入名稱',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: '確定'
      })
      return
    }

    let sUrl = sysConfig.apiUrl + 'Function'
    let sMethod = 'post'

    let sData = {
      name: event.target.iName.value,
      description: event.target.iDesc.value
    }


    if (fId != '') {
      sUrl += '/' + fId
      sMethod = 'put'
    }

    axios({
      method: sMethod,
      url: sUrl,
      data: sData
    })
      .then((response) => {
        console.log(response.data);
        setModalShow(false)
        getdata()
      })
      .catch((error) => {
        console.log(error)
      })

  }

  function del(index) {

    Swal.fire({
      title: '確定刪除這筆資料',
      //text: "確定刪除這筆資料?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '確定',
      cancelButtonText: '取消'
    }).then((result) => {
      if (result.isConfirmed) {
        const obj = functions[index]
        let sUrl = sysConfig.apiUrl + 'Function/' + obj.function_id
        let sMethod = 'delete'

        const ndata = functions.filter(item => item.function_id !== obj.function_id)
        setFuntions(ndata)

        axios({
          method: sMethod,
          url: sUrl
        })
          .then((response) => {
            console.log(response.data);
            if (response.data.success) {
              Swal.fire({
                title: '刪除成功',
                icon: 'success',
                showCancelButton: false,
                timer: 2000,
                confirmButtonText: '確定'
              })

            } else {
              const rdata = response.data.data
              let rmsg = ''
              for (var i in rdata) {
                rmsg += rdata[i].name + "[" + t("position_" + rdata[i].position) + "] "
              }
              Swal.fire({
                title: response.data.msg,
                text: rmsg,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: '確定'
              })
            }
            getdata()
          })
          .catch((error) => {
            console.log(error)
          })
      }
    })

    //if(window.confirm('確定刪除?') === false) return


  }

  function UpsertModal(props) {
    //setOpen(props.show)
    isOpen = props.show
    console.log(isOpen)
    return (
      <Modal
        {...props}
        size="lg"
        backdrop="static"
        keyboard="false"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form onSubmit={save}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              系統頁面
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="row g-3">
              <div className="col-md-4">
                <label className="form-label">名稱</label>
                <input type="text" defaultValue={fName} className="form-control" name="iName" />
              </div>
              <div className="col-md-8">
                <label className="form-label">描述</label>
                <input type="text" defaultValue={fDesc} className="form-control" name="iDesc" />
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <input type="submit" name="submit" className="btn btn-primary" value="儲存" />
            <Button variant="secondary" onClick={props.onHide}>取消</Button>

          </Modal.Footer>
        </form>
      </Modal>
    );
  }



  useEffect(() => {
    if(pageid != '')
      getdata()
  }, [pageid]);

  useEffect(() => {
    
  }, []);

  if (isLoading) {
    return (
      <Layout>
        <main id="main" className="main">Loading...</main>
      </Layout>
    )
  }

  function upd() {
    alert('upd')
  }

  const mTableBody = functions.contents.map(
    (f, index) => (
      <tr key={f.id}>
        <td>{f.name}</td>
        <td>{t(f.mode)}</td>
        <td>
          {/* <button className="btn btn-warning" onClick={() => upsert(index)}><i  className="bi bi-pencil-square"></i></button>&nbsp;
          <button className="btn btn-danger" onClick={() => del(index)}><i  className="bi bi-trash"></i></button> */}
        </td>
      </tr>
    )
  )
  // const mTable = (
  //   <table className="table">
  //     <thead>
  //       <tr>
  //         <th>名稱</th>
  //         <th>模式</th>
  //         <th>編輯</th>         
  //       </tr>
  //     </thead>
  //     <tbody>
  //       {mTableBody}
  //     </tbody>
  //   </table>
  // )

  function openlink(url) {
    window.open(url, '_blank', 'noreferrer');
  }

  function checkIsAddFavorite(id) {
    let isAdd = false
    favorite.some(f => {
      if (f.contentId == id) {
        isAdd = true
        return true
      }
    })
    return isAdd
  }

  function addFavorite(c_id, c_mode, c_style) {
    //event.preventDefault();
    //alert('add ' + id)

    let sUrl = sysConfig.apiUrl + 'Favorite'
    let sMethod = 'post'

    const uid = localStorage.getItem('userId')

    let sData = {
      userId: parseInt(uid),
      contentId: c_id,
      mode: c_mode
    }

    if (c_style == 'bi bi-star-fill') {
      sMethod = 'delete'
      sData = {
        userId: parseInt(uid),
        contentId: c_id
      }
    }

    axios({
      method: sMethod,
      url: sUrl,
      data: sData
    })
      .then((response) => {
        console.log(response.data);
        getdata()      

        let msg = t("已加入常用功能")
        if (c_style == 'bi bi-star-fill') msg = '已刪除常用功能'

        Swal.fire({
          title: msg,
          icon: 'success',
          showCancelButton: false,
          timer: 2000,
          showConfirmButton: false,
          confirmButtonText: '確定'
        })

        setTimeout(() => {
          
          updFavorite()
          
        }, 2000);
      })
      .catch((error) => {
        console.log(error)
      })

  }

 
  const mTableDef = (


    functions.contents.map((m, index) => (
      m.mode == 'tag' ?
        <div className="col-xxl-4 col-md-4 ">
          <div className={"card card-home info-card home-block-card"
            + (index % 2 == 0 ? " " : "")} >
            <div className="card-body" >
              <h5 class="card-title">{trans(i18n.language, m.name, m.ename)}</h5>
              <ul class="list-group">
                {
                  m.links.map(n => {
                    return (
                      <li class="list-group-item home-block">
                        {
                          checkIsAddFavorite(n.link_id) ?
                            <i  title={t("刪除常用功能")} onClick={() => addFavorite(n.link_id, 'link', 'bi bi-star-fill')} className={"bi bi-star-fill" + " bi-favo"}></i>
                            :
                            <i title={t("加入常用功能")} onClick={() => addFavorite(n.link_id, 'link', 'bi bi-star')} className={"bi bi-star" + " bi-favo"}></i>
                        }
                        <a onClick={() => AddLog(sysConfig, n.link_id, 'link')} href={n.url} target="_blank">
                          {
                            n.iconpath == null || n.iconpath == '' ?
                              <>
                                <img className="home-block-img" src="./img/t1.png" />
                              </>
                              :
                              <>

                                <img className="home-block-img" src={sysConfig.imgUrl + 'link/' + n.iconpath} />
                              </>
                          }
                          <span className="home-block-name">{trans(i18n.language, n.name, n.ename)}</span>
                        </a>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>
        </div>
        :
        <></>
    ))


  )

  const mTable = (
    functions.contents.map((item, index) => (

      item.mode == 'tag' ?
        <ul class="list-group m-2">
          <li class="list-group-item bg-th" aria-current="true">{item.name}</li>
          {
            item.links != null ?
              item.links.map((link) => (
                <li onClick={() => openlink(link.url)} className="list-group-item list-group-item-action" style={{ cursor: "pointer" }}>
                  {
                    link.iconpath == null || link.iconpath == '' ?
                      <img src="./img/t1.png" title="預設圖示" style={{ width: '30px', marginRight: '10px' }} />
                      :
                      <img src={sysConfig.imgUrl + 'link/' + link.iconpath} style={{ width: '30px', marginRight: '10px' }} />
                  }
                  {link.name}
                </li>
              ))
              :
              <></>
          }
        </ul>
        :
        <ul class="list-group m-2">
          <a href={item.content} target="_blank">
            <li class="list-group-item" aria-current="true">
              {
                item.param1 == null || item.param1 == '' ?
                  <img src="./img/t1.png" title="預設圖示" style={{ width: '30px', marginRight: '10px' }} />
                  :
                  <img src={sysConfig.imgUrl + 'link/' + item.param1} style={{ width: '30px', marginRight: '10px' }} />
              }
              {item.name}
            </li></a>
        </ul>


    ))

  )

  const mTable_block = (
    functions.contents.map((item, index) => (

      item.mode == 'tag' ?
        <div className="col-lg-12">
          <div className="row">
            {/* <li class="list-group-item bg-cgu" aria-current="true">{item.name}</li> */}
            {
              item.links != null ?
                item.links.map((link) => (
                  <div class="col-lg-3">
                    <a href={link.url} target="_blank">
                      <div className="card card-home cgu-fun d-flex align-items-center justify-content-center pt-3" >
                        {
                          link.iconpath == null || link.iconpath == '' ?
                            <img src="./img/t1.png" title="預設圖示" className="img-block" />
                            :
                            <img src={sysConfig.imgUrl + 'link/' + link.iconpath} className="img-block" />
                        }
                        <div class="card-body">
                          <div className="link-name text-center">{link.name}</div>
                          <p className="card-text tag-name" ><span className="badge bg-secondary">{item.name}</span></p>
                        </div>
                      </div>
                    </a>
                  </div>
                ))
                :
                <></>
            }
          </div>
        </div>
        :
        <div className="col-lg-12">
          <div className="row">
            <div class="col-lg-3">
              <a href={item.content} target="_blank">
                <div className="cgu-fun card d-flex align-items-center justify-content-center pt-3" >
                  {
                    item.param1 == null || item.param1 == '' ?
                      <img src="./img/t1.png" title="預設圖示" style={{ width: '100px', marginLeft: '10px' }} />
                      :
                      <img src={sysConfig.imgUrl + 'link/' + item.param1} style={{ width: '100px', marginLeft: '10px' }} />
                  }
                  <div class="card-body">
                    <div className="link-name text-center">{item.name}</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
    ))

  )
  let cc = 0;
  const mTable_block2 = (
    functions.contents.map((item, index1) => (

      item.mode == 'tag' ?
        <div className="col-12">
          <div className="row">
            {/* <li class="list-group-item bg-cgu" aria-current="true">{item.name}</li> */}
            {
              item.links != null ?

                item.links.map((link, index) => (
                  <div class="col-md-6">

                    <div className={"card card-home cgu-fun pt-4 "
                      + (cc++ % 2 == 0 ? "cgu-fun-bg1" : "cgu-fun-bg2")} >

                      <div class="card-body">
                        {/* <div className="link-name text-center">{link.name}</div> */}
                        <p className="card-text tag-name" ><span className="badge bg-secondary">{trans(i18n.language, item.name, item.ename)}</span></p>

                        <h5 class="card-title">
                        {
                          checkIsAddFavorite(link.link_id) ?
                            <i title={t("刪除常用功能")} onClick={() => addFavorite(link.link_id, 'link', 'bi bi-star-fill')} className={"bi bi-star-fill" + " bi-favo"}></i>
                            :
                            <i title={t("加入常用功能")} onClick={() => addFavorite(link.link_id, 'link', 'bi bi-star')} className={"bi bi-star" + " bi-favo"}></i>
                        }
                          {
                            link.iconpath == null || link.iconpath == '' ?
                              <img src="./img/t1.png" title="預設圖示" className="img-block" />
                              :
                              <img src={sysConfig.imgUrl + 'link/' + link.iconpath} className="img-block" />
                          }
                          {trans(i18n.language, link.name, link.ename)}
                        </h5>
                        <a onClick={() => AddLog(sysConfig, link.link_id, 'link')} href={link.url} target="_blank">
                          <div className="btn btn-fun">{t("進入")}</div>
                        </a>
                      </div>
                    </div>

                  </div>
                ))
                :
                <></>
            }
          </div>
        </div>
        :
        <div className="col-lg-12">
          <div className="row">
            <div class="col-lg-3">
              <a href={item.content} target="_blank">
                <div className="cgu-fun card d-flex align-items-center justify-content-center pt-3" >
                  {
                    item.param1 == null || item.param1 == '' ?
                      <img src="./img/t1.png" title="預設圖示" style={{ width: '100px', marginLeft: '10px' }} />
                      :
                      <img src={sysConfig.imgUrl + 'link/' + item.param1} style={{ width: '100px', marginLeft: '10px' }} />
                  }
                  <div class="card-body">
                    <div className="link-name text-center">{item.name}</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
    ))

  )

  const mBody = (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{trans(i18n.language, functions.name, functions.eName)}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/">首頁</a></li>
              <li className="breadcrumb-item">{functions.name}</li>

            </ol>
          </nav>
        </div>
        <section className="section dashboard">
          <div className="row">
            {
              functions.mode == 'def' ?
                <>
                  {mTableDef}
                </>

                // <div className="col-md-12">
                //   <div className="card">
                //     <div className="card-body p-2">
                //       {mTableDef}
                //     </div>
                //   </div>
                // </div>
                :
                <></>
            }
            {
              functions.mode == 'block' ?
                <div className="col-12">
                  {mTable_block2}
                </div>
                :
                <></>
            }

          </div>
        </section>
      </main>
    </>
  )


  return (
    <Layout>

      {mBody}
      <UpsertModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

    </Layout>

  );

  //const mTableBody = users.map(users => <tr key={users.id}><td>{users.id}</td><td>{users.name}</td></tr>)


  const Main = (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Dashboard</h1>
          <table>
            { }
          </table>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="index.html">Home</a></li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Default Table</h5>

                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )

  return (
    <>
      <Layout>
        {Main}
      </Layout>
    </>

  )
}

export default PageShow