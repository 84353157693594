import React from "react"
import Layout from "../../common/Layout"
import { useContext, useState } from "react";
import { AppContext } from '../../App';
import axios from "axios";
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import LayoutSc from './LayoutSc'
import {checkLang} from "../../common/Util";
import { useTranslation } from "react-i18next";
const StuGrade = () => {
  const { t, i18n } = useTranslation();
  const { sysConfig } = useContext(AppContext)
  const [course, setCourse] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [sem, setSem] = useState([]);
  const [dep, setDep] = useState([]);
  const [courseDetail, setCourseDetail] = useState({ time: [], subject: [] });
  const [loading, openLoading] = useState(false);
  const [unders, setunders] = useState([]);
  const [tran, settran] = useState([]);

  const queryParams = new URLSearchParams(window.location.search)
  const skey = queryParams.get("skey")
  let stid = atob(skey)

  checkLang()

  React.useEffect(() => {

    getSSOInfo()

  }, []);

  function getSSOInfo() {
      console.log('check login')
      if(window.getLoginInfo() == true) {        
        stid = localStorage.getItem('stid')
        search()
        getStuUnder()
        getStuTran()
      } 
      else {
        if(window.location.href.indexOf('?') == -1 ) {
          window.loginSSO_Redirect()
        }  
      }
  }

  function getSelData() {
    let endpoints = [
      sysConfig.apiUrl_IS + "Course/GetSemester",
      sysConfig.apiUrl_IS + "Course/GetDepartmentByCategory?categoryid=3"
    ];
    axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
      (data) => {
        console.log('all', data[0], data[1])
        setSem(data[0].data)
        setDep(data[1].data)
      }
    );
  }

  function add() {
    setModalShow(true)
  }

  function addCourse() {
    var list = localStorage.getItem('bookingIds')
    console.log('addCourse', list)

    if (list == '' || list == null) {
      Swal.fire({
        title: t('請選擇課程'),
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: t('確定')
      })
      return
    }

    let sUrl = sysConfig.apiUrl_IS + 'Booking/AddBookingList?studentid=' + stid + '&sectionid=' + list
    let sMethod = 'post'


    axios({
      method: sMethod,
      url: sUrl
    })
      .then((response) => {
        console.log(response.data);
        setModalShow(false)
        search()
      })
      .catch((error) => {
        console.log(error)
      })

  }

  function orderBooking(itemid, direction) {
    let sUrl = sysConfig.apiUrl_IS + 'Booking/OrderBooking?itemid=' + itemid + '&direction=' + direction
    let sMethod = 'post'

    axios({
      method: sMethod,
      url: sUrl
    })
      .then((response) => {
        console.log(response.data);
        search()
      })
      .catch((error) => {
        console.log(error)
      })
      .then(() => {

      })
  }

  function del(itemid) {


    Swal.fire({
      title: '確定刪除這筆資料',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '確定',
      cancelButtonText: '取消'
    }).then((result) => {
      if (result.isConfirmed) {
        let sUrl = sysConfig.apiUrl_IS + 'Booking/DelBookingList?itemid=' + itemid
        let sMethod = 'post'

        openLoading(true)

        axios({
          method: sMethod,
          url: sUrl
        })
          .then((response) => {
            console.log(response.data);
            search()
          })
          .catch((error) => {
            console.log(error)
          })
          .then(() => {
            openLoading(false)
          })
      }
    })


  }

  function showDetail(index, itemid, sectionid) {
    console.log(index, itemid, sectionid)

    let endpoints = [
      sysConfig.apiUrl_IS + "Course/GetSectionTime?sectionid=" + sectionid,
      sysConfig.apiUrl_IS + "Course/GetSectionSchedules?sectionid=" + sectionid
    ];
    axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
      (data) => {
        console.log('all', data[0], data[1])
        let cobj = course[index]
        cobj.time = data[0].data[0]
        cobj.subject = data[1].data
        setCourseDetail(cobj)

        setModalShow(true)
      }
    );

  }

  function DetailModal(props) {

    return (
      <Modal
        {...props}
        dialogClassName="modalStyle"
        keyboard="false"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form onSubmit="">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              課程查詢
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="row">
              <iframe className="frame1" id="cframe" src="/course?booking=1"></iframe>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <input type="button" onClick={() => addCourse()} className="btn btn-primary" value="加入" />
            <Button variant="secondary" onClick={props.onHide}>取消</Button>

          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  // function search(event) {
  //   event.preventDefault();
  //   getCourse(event.target.p_TERMID.value, event.target.p_DEPARTMENTID.value)
  // }

  function reset() {
    window.location.href = window.location.href
  }

  function search() {
    openLoading(true)
    //event.preventDefault();
    let param = '?studentid=' + stid
    // param += '&departmentid=' + event.target.departmentid.value   

    // if (event.target.departmentid.value == ''     
    // ) {
    //   Swal.fire({
    //     title: '請輸入查詢條件',
    //     icon: 'warning',
    //     showCancelButton: false,
    //     confirmButtonText: '確定'
    //   })
    //   return
    // }

    const apiUrl = sysConfig.apiUrl_IS + 'Course/GetStudentGrade' + param

    axios.get(apiUrl).then((response) => {

      const res = response.data
      console.log('course', res)
      setCourse(res)

    })
      .catch((error) => {
        console.log(error)
      }).then(() => {
        openLoading(false)
      })
  }

  function getStuUnder() {
    openLoading(true)
    //event.preventDefault();
    let param = '?studentid=' + stid
    
    const apiUrl = sysConfig.apiUrl_IS + 'Course/GetStudentUnderStudies' + param

    axios.get(apiUrl).then((response) => {

      const res = response.data
      console.log('getStuUnder', res)
      setunders(res)

    })
      .catch((error) => {
        console.log(error)
      }).then(() => {
        openLoading(false)
      })
  }

  function getStuTran() {
    openLoading(true)
    //event.preventDefault();
    let param = '?studentid=' + stid
    
    const apiUrl = sysConfig.apiUrl_IS + 'Course/GetStudentTranscript' + param

    axios.get(apiUrl).then((response) => {

      const res = response.data
      console.log('getStuTran', res)
      settran(res)

    })
      .catch((error) => {
        console.log(error)
      }).then(() => {
        openLoading(false)
      })
  }



  const bodyStyle = {
    height: '500px'
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    console.log('handleOnDragEnd', result)

    orderBookingSetIndex(result.draggableId, result.destination.index + 1)
  }

  function orderBookingSetIndex(itemid, order) {
    let sUrl = sysConfig.apiUrl_IS + 'Booking/OrderBookingSet?itemid=' + itemid + '&itemorder=' + order
    let sMethod = 'post'

    axios({
      method: sMethod,
      url: sUrl
    })
      .then((response) => {
        console.log(response.data);
        search()
      })
      .catch((error) => {
        console.log(error)
      })
      .then(() => {

      })
  }

  const mTableBody = (
    <tbody>
      {course.map((item, index) => {
        return (
          <tr >

            <td>{item.ACADMICYEAR}</td>
            <td>{item.ACADMICTERM}</td>
            <td className="td-stext">{item.CALL_ID}</td>
            <td className="td-stext">{item.CCOURSENAME}</td>
            <td className="td-stext">{item.SCORE}</td>
            <td>{item.CREDIT}</td>
            <td>&nbsp;{item.FOOTNOTES}</td>
            <td>{item.ACHVDAT}</td>

          </tr>
        );
      })}

    </tbody>


  )

  let tdColName = ""
  tdColName += '.mt1 td:nth-of-type(1):before {content : "'+ t("學年") +'"}'
  tdColName += '.mt1 td:nth-of-type(2):before {content : "'+ t("學期") +'"}'
  tdColName += '.mt1 td:nth-of-type(3):before {content : "'+ t("科目代號") +'"}'
  tdColName += '.mt1 td:nth-of-type(4):before {content : "'+ t("課程名稱") +'"}'
  tdColName += '.mt1 td:nth-of-type(5):before {content : "'+ t("成績") +'"}'
  tdColName += '.mt1 td:nth-of-type(6):before {content : "'+ t("實得學分") +'"}'
  tdColName += '.mt1 td:nth-of-type(7):before {content : "'+ t("備註") +'"}'
  tdColName += '.mt1 td:nth-of-type(8):before {content : "'+ t("登錄日") +'"}'

  const mTable = (
    <table className="table m-table mt1">
      <thead>
        <style>{tdColName}</style>
        <tr>
          <th nowrap="nowrap">{t("學年")}</th>
          <th nowrap="nowrap">{t("學期")}</th>
          <th nowrap="nowrap">{t("科目代號")}</th>
          <th nowrap="nowrap">{t("課程名稱")}</th>
          <th nowrap="nowrap">{t("成績")}</th>
          <th nowrap="nowrap">{t("實得學分")}</th>
          <th nowrap="nowrap">{t("備註")}</th>
          <th nowrap="nowrap">{t("登錄日")}</th>
        </tr>
      </thead>
      <>
        {
          course.length > 0 ?
            (
              <>{mTableBody}</>
            )
            :
            <tbody>
              <tr><td align="center" colSpan={10}>{t("無資料")}</td></tr>
            </tbody>
        }

      </>
    </table>
  )

  let tdColName2 = ""
  tdColName2 += '.mt2 td:nth-of-type(1):before {content : "'+ t("學年") +'"}'
  tdColName2 += '.mt2 td:nth-of-type(2):before {content : "'+ t("學期") +'"}'
  tdColName2 += '.mt2 td:nth-of-type(3):before {content : "'+ t("科目代號") +'"}'
  tdColName2 += '.mt2 td:nth-of-type(4):before {content : "'+ t("課程名稱") +'"}'
  tdColName2 += '.mt2 td:nth-of-type(5):before {content : "'+ t("學分") +'"}'
  tdColName2 += '.mt2 td:nth-of-type(6):before {content : "'+ t("核准") +'"}'

  const mTableUnder = (
    <table className="table m-table mt2">
      <thead>
        <style>{tdColName2}</style>
        <tr>
          <th nowrap="nowrap">{t("學年")}</th>
          <th nowrap="nowrap">{t("學期")}</th>
          <th nowrap="nowrap">{t("科目代號")}</th>          
          <th nowrap="nowrap">{t("課程名稱")}</th>                         
          <th nowrap="nowrap">{t("學分")}</th>
          <th nowrap="nowrap">{t("核准")}</th>
        </tr>
      </thead>
      <>
        {
          unders.length > 0 ?
            (
              <tbody>
                {unders.map((item, index) => {
                  return (
                    <tr >

                      <td>{item.ACADMICYEAR}</td>
                      <td>{item.ACADMICTERM}</td>
                      <td className="td-stext">{item.CALL_ID}</td>
                      <td className="td-stext">{item.CCOURSENAME}</td>                     
                      <td>{item.CREDITS}</td>
                      <td className="td-stext">{item.APPROVED}</td>                      

                    </tr>
                  );
                })}

              </tbody>
            )
            :
            <tbody>
              <tr><td align="center" colSpan={10}>{t("無資料")}</td></tr>
            </tbody>
        }

      </>
    </table>
  )

  let tdColName3 = ""
  tdColName3 += '.mt3 td:nth-of-type(1):before {content : "'+ t("學年") +'"}'
  tdColName3 += '.mt3 td:nth-of-type(2):before {content : "'+ t("學期") +'"}'
  tdColName3 += '.mt3 td:nth-of-type(3):before {content : "'+ t("體育") +'"}'
  tdColName3 += '.mt3 td:nth-of-type(4):before {content : "'+ t("操行") +'"}'
  tdColName3 += '.mt3 td:nth-of-type(5):before {content : "'+ t("軍訓") +'"}'
  tdColName3 += '.mt3 td:nth-of-type(6):before {content : "'+ t("實習") +'"}'
  tdColName3 += '.mt3 td:nth-of-type(7):before {content : "'+ t("總平均") +'"}'
  tdColName3 += '.mt3 td:nth-of-type(8):before {content : "'+ t("名次") +'"}'

  const mTableTran = (
    <table className="table m-table mt3">
      <thead>
        <style>{tdColName3}</style>
        <tr>
          <th nowrap="nowrap">{t("學年")}</th>
          <th nowrap="nowrap">{t("學期")}</th>
          <th nowrap="nowrap">{t("體育")}</th>          
          <th nowrap="nowrap">{t("操行")}</th>                         
          <th nowrap="nowrap">{t("軍訓")}</th>
          <th nowrap="nowrap">{t("實習")}</th>
          <th nowrap="nowrap">{t("總平均")}</th>
          <th nowrap="nowrap">{t("名次")}</th>
        </tr>
      </thead>
      <>
        {
          tran.length > 0 ?
            (
              <tbody>
                {tran.map((item, index) => {
                  return (
                    <tr >

                      <td>{item.ACADMICYEAR}</td>
                      <td>{item.ACADMICTERM}</td>
                      <td>{item.SPORTSSCORE}</td>
                      <td>{item.BEHAVIORALSCORE}</td>
                      <td>{item.MILITARYSCORE}</td>
                      <td>{item.PRACTICALSCORE}</td>
                      <td>{item.MEANSCORE}</td>
                      <td>{item.RANK}</td>

                    </tr>
                  );
                })}

              </tbody>
            )
            :
            <tbody>
              <tr><td align="center" colSpan={10}>{t("無資料")}</td></tr>
            </tbody>
        }

      </>
    </table>
  )

  const Main = (
    <>
      <main>
        <div className="container">
          <section className="section  min-vh-100 d-flex flex-column py-4" >


            <div className="container">

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">

                      <ul class="nav nav-tabs pt-3" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button className={"nav-link active"} id={"tabh0"} data-bs-toggle="tab" data-bs-target={"#tab0"} type="button" aria-selected={"true"}>
                            {t("成績查詢")}
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button className={"nav-link"} id={"tabh2"} data-bs-toggle="tab" data-bs-target={"#tab2"} type="button" aria-selected={"false"}>
                            {t("成績摘要")}
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button className={"nav-link"} id={"tabh1"} data-bs-toggle="tab" data-bs-target={"#tab1"} type="button" aria-selected={"false"}>
                            {t("抵免學分核准結果")}
                          </button>
                        </li>                        
                      </ul>

                      <div class="tab-content pt-3" id="myTabjustifiedContent">
                        <div className={"tab-pane fade show active"} id={"tab0"} >
                          {/* <div className="text-danger m-2">
                            ※ 網站資料僅供查詢參考，不具任何證明效力。※ <br />
                            ※ 若對各科成績有疑問，請聯繫授課教師確認。※<br />
                            ※ 教務處將於學期結束後三至四週郵寄該學期成績單。※<br />
                            ※ 名次將於新學期第一週公告，若有成績更正，名次可能隨之變動。※<br />
                            ※ 學生各科成績一律以成績單登載之內容為準。※<br />
                            ※ 如需成績證明文件，請向教務處申請。※
                          </div> */}
                          <div class="text-danger m-2">
                            <ul className="ul-1">
                              <li>{t("grade_desc1")}</li>
                              <li>{t("grade_desc2")}</li>
                              <li>{t("grade_desc3")}</li>
                              <li>{t("grade_desc4")}</li>
                              <li>{t("grade_desc5")}</li>     
                              <li>{t("grade_desc6")}</li>                      
                            </ul>
                          </div>
                          {mTable}
                        </div>
                        <div className={"tab-pane fade"} id={"tab1"} >
                          {mTableUnder}
                        </div>
                        <div className={"tab-pane fade"} id={"tab2"} >
                          {mTableTran}
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>
        </div>
      </main>
    </>
  )

  return (
    <LayoutSc>
      {Main}


      <DetailModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

    </LayoutSc> 

  )
}

export default StuGrade