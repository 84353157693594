import React from "react"
import Layout from "../common/Layout"
import { useState, useEffect, useContext } from 'react'
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AppContext } from '../App';
import Swal from 'sweetalert2'
import { useTranslation } from "react-i18next";
import CreatableSelect from 'react-select/creatable'
import {StTable} from '../common/StTable'


const TableTest = () => {

  useEffect(() => {

  }, []);

  const rows = [
    { id: 1, name: 'Liz Lemon', age: 36, is_manager: true, start_date: '02-28-1999' },
    { id: 2, name: 'Jack Donaghy', age: 40, is_manager: true, start_date: '03-05-1997' },
    { id: 3, name: 'Tracy Morgan', age: 39, is_manager: false, start_date: '07-12-2002' },
    { id: 4, name: 'Jenna Maroney', age: 40, is_manager: false, start_date: '02-28-1999' },
    { id: 5, name: 'Kenneth Parcell', age: Infinity, is_manager: false, start_date: '01-01-1970' },
    { id: 6, name: 'Pete Hornberger', age: null, is_manager: true, start_date: '04-01-2000' },
    { id: 7, name: 'Frank Rossitano', age: 36, is_manager: false, start_date: null },
    { id: 8, name: null, age: null, is_manager: null, start_date: null },
  ]

  const columns = [
    { accessor: 'name', label: '姓名', filter: true },
    { accessor: 'age', label: 'Age' , filter: true },
    { accessor: 'is_manager', label: 'Manager',   filter: false, format: value => (value ? '✔️' : '✖️') },
    { accessor: 'start_date', label: 'Start Date' , filter: false},
  ]


  return (
    <>
      <StTable rows={rows} columns={columns} isSearch={true} isPage={true} pageSize={5} />
    </>
  )

}

export default TableTest