import React from "react"
import Layout from "../../common/Layout"
import { useContext, useState, useMemo } from "react";
import { AppContext } from '../../App';
import axios from "axios";
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
import LayoutSc from './LayoutSc'
import { sortRows, filterRows, paginateRows } from '../../common/Helpers'
import CourseDetail from './CourseDetail'
import Moment from 'moment'
const CoursePrint = () => {
  const { t, i18n } = useTranslation();
  const [loading, openLoading] = useState(false);
  const { sysConfig, showLoading } = useContext(AppContext)
  const [course, setCourse] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [sem, setSem] = useState([]);
  const [dep, setDep] = useState([]);
  const [courseDetail, setCourseDetail] = useState({ time: [], subject: [], preCourse: [] });
  const [d_cobj, setd_cobj] = React.useState(null);

  const queryParams = new URLSearchParams(window.location.search)
  let sectionid = queryParams.get("sectionid").trim()

  React.useEffect(() => {

    if (sectionid != null && sectionid != '') {
      openCourseDetail(sectionid)
    }    

  }, []);

  function openCourseDetail(sectionid) {
    //const cobj = course[index]    

    let sUrl = sysConfig.apiUrl_IS + 'Course/GetCourseSections?sectionid=' + sectionid
    let sMethod = 'get'

    axios({
      method: sMethod,
      url: sUrl
    })
      .then((response) => {
        console.log(response.data);
        loadDetail(response.data[0])
      })
      .catch((error) => {
        console.log(error)
      })


    //alert(sectionid)
  }

  function loadDetail(cobj) {

    //openLoading(true)
    let endpoints = [
      sysConfig.apiUrl_IS + "Course/GetSectionTime?sectionid=" + cobj.SECTIONID,
      sysConfig.apiUrl_IS + "Course/GetSectionSchedules?sectionid=" + cobj.SECTIONID,
      sysConfig.apiUrl_IS + "Course/GetPreCourse?call_id=" + cobj.CALL_ID
    ];
    axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
      (data) => {
        cobj.time = data[0].data
        cobj.subject = data[1].data
        cobj.preCourse = data[2].data
        console.log('cobj', cobj)
        setCourseDetail(cobj)

        setTimeout(() => {
          window.print()
        }, 1000);
      }
    ).then(() => {
      //openLoading(false)
    })
  }

  const mTable = (
    <>
      <div class="card card-br">

        <div class="card-body">
          <h5 class="card-title">{t("課程資訊")}</h5>

          <table class="table">
            <tbody>
              <tr><td className="table-active" scope="col">{t("學年")}/{t("學期")}</td><td>{courseDetail.ACADMICYEAR}/{courseDetail.ACADMICTERM}</td></tr>
              <tr><td className="table-active" scope="col">{t("授課教師")}</td><td>{i18n.language == 'en' ? courseDetail.ENAME : courseDetail.NAME}</td></tr>
              <tr><td className="table-active" scope="col">{t("開課單位")}</td><td>{i18n.language == 'en' ? courseDetail.DEPARTMENTNAME_E : courseDetail.DEPARTMENTNAME_C}</td></tr>
              <tr><td className="table-active" scope="col">{t("年級")}-{t("班別")}</td><td>{courseDetail.YEAR}-{courseDetail.GROUPID}</td></tr>
              <tr><td className="table-active" scope="col">{t("科目代號")}({t("學分")})</td><td>{courseDetail.CALL_ID}({courseDetail.CREDITS})</td></tr>
              <tr><td className="table-active" scope="col">{t("課程名稱")}</td><td>{i18n.language == 'en' ? courseDetail.ECOURSENAME : courseDetail.CCOURSENAME}</td></tr>
              <tr><td className="table-active" scope="col">{t("開課序號")}</td><td>{courseDetail.SECTIONID}</td></tr>
              <tr><td className="table-active" scope="col">{t("主要開課序號")}</td><td>{courseDetail.MAINSECTIONID}</td></tr>
              <tr><td className="table-active" scope="col">{t("修課學生")}</td><td>{t("本校")}: {courseDetail.CURRENRL}{t("人")}<>；</>{t('外校')}: {courseDetail.CROENRL}{t("人")}</td></tr>
              <tr><td className="table-active" scope="col">{t("人數限制")}</td><td>{courseDetail.MINENRL}~{courseDetail.MAXENRL}</td></tr>
              <tr>
                <td className="table-active" scope="col">{t("上課時間地點")}</td>
                <td>
                  <table className="table table-bordered">
                    <thead>
                      <tr className="table-primary"><th>{t("星期")}</th><th>{t("開始")} / {t("結束")}</th><th>{t("教室")} [<a href="https://academic.cgu.edu.tw/p/412-1009-8470.php" target="_blank">{t("代碼說明")}</a>]</th><th></th></tr>
                    </thead>
                    <tbody>

                      {
                        typeof (courseDetail.time) != 'undefined' && courseDetail.time != null && courseDetail.time.length > 0 ?
                          courseDetail.time.map(item => (
                            <tr>

                              <td>{i18n.language == 'en' ? item.EWEEKDAY : item.CWEEKDAY}</td>
                              <td>{item.BEGINSEC} ({item.STARTTIME}) ~  {item.ENDSEC} ({item.ENDTIME})</td>
                              <td>{item.LOCATIONID}</td>
                              <td>{t(item.CATEGORYNAME)}</td>
                            </tr>
                          ))

                          :
                          <tr>
                            <td colSpan="4" align="center">{t("無")}</td>
                          </tr>
                      }

                    </tbody>
                  </table>
                </td>
              </tr>
              <tr><td className="table-active" scope="col">{t("核心能力")}</td><td><a href={"https://ep.cgu.edu.tw/Webfolio/checkCID.aspx?cid=" + courseDetail.CALL_ID} target="_blank">{t("按此查詢")}</a></td></tr>
              <tr><td className="table-active" scope="col">{t("備註")}</td><td>{courseDetail.ANNOTATION}</td></tr>
            </tbody>

          </table>
        </div>
      </div>

      <div class="card card-br">

        <div class="card-body">
          <h5 class="card-title">{t("教學大綱")}</h5>

          <table class="table">
            <tbody>
              <tr><td className="table-active" scope="col">{t("教科書")}</td><td>{courseDetail.TEXTBOOK}</td></tr>
              <tr><td className="table-active" scope="col">{t("教科書")}ISBN</td><td>{"0000000000"}</td></tr>
              <tr><td className="table-active" scope="col">{t("參考書")}</td><td>{courseDetail.REFBOOK}</td></tr>
              <tr><td className="table-active" scope="col">{t("課程講義網址")}</td><td>{courseDetail.LECTURE}</td></tr>
              <tr><td className="table-active" scope="col">{t("整體教學目標")}</td><td>{courseDetail.OBJECTIVE}</td></tr>
              <tr><td className="table-active" scope="col">{t("教學方法")}</td><td>{courseDetail.PEDAGOGY}</td></tr>
              <tr><td className="table-active" scope="col">{t("課程簡介")}</td><td>{i18n.language == 'en' ? courseDetail.ENGINTRO : courseDetail.CHTINTRO}</td></tr>
              <tr><td className="table-active" scope="col">{t("彈性學習")}</td><td>{courseDetail.ALTLEARNING}</td></tr>
              <tr><td className="table-active" scope="col">{t("成績考核")}</td><td>{courseDetail.SCOREMETHOD}</td></tr>
              <tr><td className="table-active" scope="col">{t("師生互動時間")}</td><td>{courseDetail.OFFICEHOUR}</td></tr>

            </tbody>
          </table>
        </div>
      </div>


      <div class="card card-br">

        <div class="card-body">
          <h5 class="card-title">{t("教學進度")}</h5>

          <table class="table">

            {
              courseDetail.subject.length > 0 ?
                <thead>
                  <tr className="table-active"><th nowrap="nowrap">{t("項次")}</th><th>{t("教學主題")}</th></tr>
                </thead>
                :
                <tr><th className="text-center">{t("無資料")}</th></tr>
            }

            <tbody>
              {
                courseDetail.subject.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      {
                        item.SECTIONDAY != null && item.SECTIONDAY != '' ?
                          <div>{Moment(item.SECTIONDAY).format('yyyy/MM/DD')}</div>
                          :
                          <></>
                      }
                      {item.SECTIONCONTENT}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>

        </div>
      </div>

      <div class="card card-br">

        <div class="card-body">
          <h5 class="card-title">{t("先修課程")}</h5>

          <table class="table">

            {
              courseDetail.preCourse.length > 0 ?
                <thead>
                  <tr className="table-active">
                    <th>{t("適用學年度")}</th>
                    <th>{t("科目代號")}</th>
                    <th>{t("課程名稱")}</th>
                    <th>{t("及格分數")}</th>
                  </tr>
                </thead>
                :
                <tr><th className="text-center">{t("本課程沒有先修課程規定")}</th></tr>
            }

            <tbody>
              {
                courseDetail.preCourse.map((item, index) => (
                  <tr>
                    <td>{item.ACADMICYEAR}</td>
                    <td>{item.PREQCOURSEID}</td>
                    <td>{i18n.language == 'en' ? item.PREQECOURSENAME : item.PREQCCOURSENAME}</td>
                    <td>{item.PASSSCORE}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>

        </div>
      </div>


    </>
  )
  const Main = (
    <>
      <div className="p-3">
        {mTable}
      </div>
    </>
  )

  return (
    <>
      {Main}
    </>

  )
}

export default CoursePrint