import Swal from 'sweetalert2'
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useContext } from "react";
import { AppContext } from '../App'

const ek2 = 'd25243b984b2'
function getAzureToken() {    
    let res = '';
    let aKey = '';
    for(var i=0;i<localStorage.length;i++) {    
        if(localStorage.key(i).indexOf('login.windows.net-accesstoken') > -1) {
            aKey = localStorage.key(i)
        }
    }
    if(aKey != '') {
        let aValObj = JSON.parse(localStorage.getItem(aKey));
        //console.log(aValObj.secret);
        res = aValObj.secret
    }
    return res;
}

function GetE1(str) {
    const { sysConfig } = useContext(AppContext)
    return btoa(str.replaceAll('ey', '3d7b97e3494c').replaceAll('.',ek2)).replaceAll('a','343951dec9eb');
}

export function getToken() {    
    return getAzureToken()
}

export function checkLang() {    
    const queryParams = new URLSearchParams(window.location.search)
    let lang = queryParams.get("lang")        
    if(window.getLoginInfo() == true) {
        let at = getToken()
        let e1 = GetE1(at)
        localStorage.setItem('tokenSc', e1)        
    }

    if (lang == null) {        
        return
    }
    else
    {
        localStorage.setItem('i18n', lang)

        let sso = queryParams.get("sso")
        localStorage.setItem('sso', sso)        
    
        setTimeout(() => {            
            window.location.href = window.location.href.split('?')[0]            
        }, 100);
       
    }
    
}

export function trans(lang, cname, ename) {

    if(lang == 'en') {
        if(ename != '' && ename != null)
            return ename
        else
            return cname
    } 
    else 
    {
        return cname;
    }
}

export function getSkey() {
    
    let lang = 'zh_tw'
    if(localStorage.getItem('i18n') != null)    lang = localStorage.getItem('i18n')

    let resParam = '?lang='+lang

    if(localStorage.getItem('token') != null)
    {
        resParam += '&sso=1'
    }

    return resParam

    // var param = '?skey=' + btoa(localStorage.getItem('LoginId').split('@')[0])
    // param += '&lang=' + lang
    // param += '&atoken=' + getAzureToken()
    // return param
}

export function AddLog(sysConfig, id, mode) {
        
    let sUrl = sysConfig.apiUrl + 'OpLog'
    let sMethod = 'post'

    let sData = {    
        contentId: id,
        mode: mode
    }

    axios({
      method: sMethod,
      url: sUrl,
      data: sData
    })
    .then((response) => {
        console.log('addlog', response.data);       
    })
    .catch((error) => {
        console.log(error)
    })
}

export function ShowMsg(icon, title, sText) {
    
    useEffect(() => {
        Swal.fire({
            title: title,
            text: sText,
            icon: icon,
            showCancelButton: false,
            confirmButtonText: '確定'
          })
    })
   
}

