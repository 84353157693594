import React from "react"
import Layout from "../common/Layout"
import { useState, useEffect, useContext } from 'react'
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AppContext } from '../App';
import Swal from 'sweetalert2'
import { useTranslation } from "react-i18next";
import CreatableSelect from 'react-select/creatable'

const Fun = () => {
  const { t, i18n } = useTranslation();
  const { sysConfig } = useContext(AppContext) 
  const [loading, openLoading] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [isLoading, setLoading] = useState(true);
  const [functions, setFuntions] = useState();
  let isOpen = false

  const [fId, setfId] = useState()
  const [fName, setfName] = useState()
  const [fEName, setfEName] = useState()
  const [fDesc, setfDesc] = useState()
  const [defTag, setDefTag] = useState()
  const [fGroup, setfGroup] = useState()

  const [fData, setfData] = useState({
    function_id: "",
    name: "",
    description: ""
  });

  function getdata() {
    openLoading(true)
    const apiUrl = sysConfig.apiUrl + "Function"
    
    axios.get(apiUrl).then(response => {
      setTimeout(() => {
        setFuntions(response.data);
        console.log(response.data)
        setLoading(false);
        openLoading(false)
      }, 1);
      
    });
  }

  function getTags() {
    const apiUrl = sysConfig.apiUrl + "Tag?mode=link"
    
    axios.get(apiUrl).then(response => {
      
      console.log('group', response.data)
      setfGroup(response.data)      
    });
  }

  function test() {
    setfName('ff')
  }

  const handleChange = e => {
    //setcGroup(Array.isArray(e) ? e.map(x => x.value) : []);
    console.log('cgroup', e)
    //setcGroup(e)
  }

  function upsert(index) {   
    setDefTag([])
    getTags()
    setModalShow(true)
    if(index > -1) {
      const obj = functions[index]
      //const index = functions.findIndex(a => a.function_id == id)
      setfId(obj.function_id)
      setfName(obj.name)
      setfEName(obj.ename)
      setfDesc(obj.description)
      setFuntions(functions)

      let tArr = []
      for(var i in obj.tags) {
        tArr.push({ value: obj.tags[i].id, label: obj.tags[i].name })
      }
      setDefTag(tArr)
    } 
    else {
      setfId('')
      setfName('')
      setfEName('')
      setfDesc('')
    }
  }

  function save(event) {
    event.preventDefault();
    console.log(event.target.iName.value)

    let chk = [];

    for(var j=0;j< event.target.iGroup.length;j++) {    
      chk.push(event.target.iGroup[j].value)   
    }
    if(typeof(event.target.iGroup.defaultValue) != 'undefined') {
      if(event.target.iGroup.defaultValue != '')
        chk.push(event.target.iGroup.defaultValue)
    }
    console.log('cgroup chk', chk)

    if(event.target.iName.value === '') {
      Swal.fire({
        title: '請輸入名稱',     
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: '確定'
      })
      return
    }
    
    let sUrl = sysConfig.apiUrl + 'Function'
    let sMethod = 'post'

    let sData = {    
      name: event.target.iName.value,
      ename: event.target.iEName.value,
      description: event.target.iDesc.value,
      tags: chk
    }
    

    if(fId != '') {
      sUrl += '/' + fId
      sMethod = 'put'
    }

    axios({
      method: sMethod,
      url: sUrl,
      data: sData
    })
    .then((response) => {
        console.log(response.data);
        setModalShow(false)
        getdata()
    })
    .catch((error) => {
        console.log(error)
    })

  }

  function del(index) {

    Swal.fire({
      title: '確定刪除這筆資料',
      //text: "確定刪除這筆資料?",
      icon: 'warning',
      showCancelButton: true,    
      confirmButtonText: '確定',
      cancelButtonText: '取消'
    }).then((result) => {
      if (result.isConfirmed) {
        const obj = functions[index]
        let sUrl = sysConfig.apiUrl + 'Function/' + obj.function_id
        let sMethod = 'delete'   
        
        const ndata = functions.filter(item => item.function_id !== obj.function_id)
        setFuntions(ndata)

        axios({
          method: sMethod,
          url: sUrl
        })
        .then((response) => {
            console.log(response.data);   
            if(response.data.success) {
              Swal.fire({
                title: '刪除成功',     
                icon: 'success',
                showCancelButton: false,
                timer: 2000,
                confirmButtonText: '確定'
              })
              
            } else {
              const rdata = response.data.data
              let rmsg = ''
              for(var i in rdata) {
                rmsg += rdata[i].name + "[" + t("position_"+rdata[i].position) + "] "
              }
              Swal.fire({
                title: response.data.msg,    
                text: rmsg, 
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: '確定'
              })
            }
            getdata()
        })
        .catch((error) => {
            console.log(error)
        })
      }
    })

    //if(window.confirm('確定刪除?') === false) return
    
    
  }

  function UpsertModal(props) {
    //setOpen(props.show)
    isOpen = props.show
    console.log(isOpen)

    let groups = []
    for(var i in fGroup) {
      groups.push({ value: fGroup[i].id, label: fGroup[i].name })
    }

    return (
      <Modal
        {...props}
        size="lg"
        backdrop="static"
        keyboard="false"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form  onSubmit={save}>      
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            系統功能
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <div className="row g-3">
            <div className="col-md-4">
              <label   className="form-label">名稱</label>
              <input type="text" defaultValue={fName} className="form-control" name="iName"/>
            </div>
            <div className="col-md-8">
                <label className="form-label">英文名稱</label>
                <input type="text" defaultValue={fEName} className="form-control" name="iEName" />
              </div>
            <div className="col-md-12">
              <label   className="form-label">描述</label>
              <input type="text" defaultValue={fDesc} className="form-control" name="iDesc"/>
            </div>               
          </div>
          <div className="col-md-12">
              <label   className="form-label">分類</label>             
              <CreatableSelect
                    defaultValue={defTag}
                    isMulti
                    name="iGroup"
                    options={groups}                    
                    noOptionsMessage={() => "無項目"}
                    placeholder={"請選擇項目"}
                    formatCreateLabel={(inputText) => "新增: "+inputText}
                    className="basic-multi-select"
                    onChange={handleChange} 
                    classNamePrefix="select"
                  />
            </div>    
          
        </Modal.Body>
        <Modal.Footer>
        <input type="submit" name="submit" className="btn btn-primary" value="儲存"  />
        <Button variant="secondary" onClick={props.onHide}>取消</Button>
        
        </Modal.Footer>
        </form>
      </Modal>
    );
  }

  

  useEffect(() => {       
    getdata()
  }, [sysConfig]);

  if (isLoading) {
    return (
      <Layout>
        <main id="main" className="main">Loading...</main>
      </Layout>
    )
  }

  function upd() {
    alert('upd')
  }

  const mTableBody = functions.map(
    (f,index) => (
      <tr key={f.function_id}>
        <td>
          {f.name}
          {
            f.tags.map((item,index) => (
              <span className="badge bg-secondary m-1">{item.name}</span>
            ))
          }
        </td>
        <td>{f.description}</td>
        <td>
          <button className="btn btn-warning" onClick={() => upsert(index)}><i  className="bi bi-pencil-square"></i></button>&nbsp;
          <button className="btn btn-danger" onClick={() => del(index)}><i  className="bi bi-trash"></i></button>
        </td>       
      </tr>
    )
  )
  const mTable = (
    <table className="table">
      <thead>
        <tr>
          <th>名稱</th>
          <th>描述</th>
          <th>編輯</th>         
        </tr>
      </thead>
      <tbody>
        {mTableBody}
      </tbody>
    </table>
  )
  const mBody = (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>系統功能</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/">首頁</a></li>
              <li className="breadcrumb-item active">系統功能</li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  {/* <h5 className="card-title">系統功能</h5> */}
                  <div className="row">
                    <div className="col-lg-6">
                      <h5 className="card-title">系統功能列表</h5>
                    </div>
                    <div className="col-lg-6">
                      <h5 className="card-title">                   
                        <button className="btn btn-secondary float-end" onClick={() => upsert(-1)}><i  className="bi bi-plus-circle"></i> 新增</button>
                      </h5>                      
                    </div>
                  </div>
                  {mTable}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )


  return (
    <Layout>
       
       {mBody}
       <UpsertModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
       
    </Layout>
    
  );

  //const mTableBody = users.map(users => <tr key={users.id}><td>{users.id}</td><td>{users.name}</td></tr>)
       

    const Main = (
        <>
          <main id="main" className="main">
            <div className="pagetitle">
              <h1>Dashboard</h1>
              <table>
                {}
              </table>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                  <li className="breadcrumb-item active">Dashboard</li>
                </ol>
              </nav>
            </div>
            <section className="section dashboard">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Default Table</h5>
                     
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )
    
    return (
        <>
        <Layout>
        {Main}
        </Layout>        
        </>
        
    )
}

export default Fun