import React from "react"
import Layout from "../../common/Layout"
import { useContext, useState, useMemo } from "react";
import { AppContext } from '../../App';
import axios from "axios";
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
import LayoutSc from './LayoutSc'
import { sortRows, filterRows, paginateRows } from '../../common/Helpers'
import {checkLang} from "../../common/Util";
import { forEach } from "lodash";
const DepCourse = () => {
  const { t, i18n } = useTranslation();
  const [loading, openLoading] = useState(false);
  const { sysConfig, showLoading } = useContext(AppContext)
  const [course, setCourse] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [sem, setSem] = useState([]);
  const [depsem, setDepsem] = useState([]);
  const [termid, setTermid] = useState(null); 
  const [dep, setDep] = useState([]);
  const [courseDetail, setCourseDetail] = useState({ time: [], subject: [] });

  const queryParams = new URLSearchParams(window.location.search)
  // let lang = queryParams.get("lang")
  // if (lang == null) lang = 'zh_tw'
  // if (localStorage.getItem('i18n') != lang) {
  //   localStorage.setItem('i18n', lang)
  //   i18n.language = lang
  //   window.location.href = window.location.href
  // }
  checkLang()

  React.useEffect(() => {

    getSelData()
    checkSSOInfo()

  }, []);

  function checkSSOInfo() {
    console.log('check login')

    if (window.location.href.indexOf('?') == -1 
          && localStorage.getItem('sso') == '1'
          && window.getLoginInfo() == false) 
    {
      window.loginSSO_Redirect()
    }    

  }

  function getSelData() {
    showLoading(true)
    let endpoints = [
      sysConfig.apiUrl_IS + "Course/GetSemester",
      sysConfig.apiUrl_IS + "Course/GetDepartmentByCategory?categoryid=3"
    ];
    axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
      (data) => {
        //console.log('all', data[0], data[1])

        let sems = data[0].data
        let srr = []
        sems.forEach(item => {
          let index = srr.findIndex(x => x.ACADMICYEAR == item.ACADMICYEAR)
          if(index == -1) {
            srr.push(item)
          } else {
            srr[index].TERMID = item.TERMID
            srr[index].CEYEAR = item.CEYEAR
          }
        })
        
        setTermid(srr[0].TERMID)
        setSem(srr)
        setDep(data[1].data)
        showLoading(false)
      }
    );
  }

  function getDepSem(tid,did) {
    showLoading(true)
    let endpoints = [
      sysConfig.apiUrl_IS + "Course/GetDepCourseSeterms?termid="+tid+"&departmentid="+did
    ];
    axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
      (data) => {
        console.log('all', data[0])        

        let sems = data[0].data
        let srr = []
        let sobj = null
        sems.forEach(item => {
          if(item.TERMID != 0) {
            srr.push(item)
          } 
          else {
            sobj = item
          }
        })

        if(sobj != null)  
          srr.push(sobj)

        setDepsem(srr)

        showLoading(false)
      }
    );
  }

  function handleOnChangeDep(e) {    
    let did = e.target.value
    if(did == '') {
      setDepsem([])
      return
    }
    getDepSem(termid, did)    
  }

  function handleOnChangeTerm(e) {    
    setDep([])
    setDepsem([])   
    getSelData()
  }
  
  function showDetail(index, itemid, sectionid) {
    console.log(index, itemid, sectionid)

    let endpoints = [
      sysConfig.apiUrl_IS + "Course/GetSectionTime?sectionid=" + sectionid,
      sysConfig.apiUrl_IS + "Course/GetSectionSchedules?sectionid=" + sectionid
    ];
    axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
      (data) => {
        console.log('all', data[0], data[1])
        let cobj = course[index]
        cobj.time = data[0].data[0]
        cobj.subject = data[1].data
        setCourseDetail(cobj)

        setModalShow(true)
      }
    );





  }

  function DetailModal(props) {

    return (
      <Modal
        {...props}
        size="lg"

        keyboard="false"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form onSubmit="">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              課程詳細資料
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="row">
              <ul class="nav nav-tabs d-flex" id="myTabjustified" role="tablist">
                <li class="nav-item flex-fill" role="presentation">
                  <button class="nav-link w-100 active" id="detail_1" data-bs-toggle="tab" data-bs-target="#home-justified" type="button" role="tab" aria-controls="detail_1" aria-selected="true">課程資訊</button>
                </li>
                <li class="nav-item flex-fill" role="presentation">
                  <button class="nav-link w-100" id="detail_2" data-bs-toggle="tab" data-bs-target="#profile-justified" type="button" role="tab" aria-controls="detail_2" aria-selected="false">教學大綱</button>
                </li>
                <li class="nav-item flex-fill" role="presentation">
                  <button class="nav-link w-100" id="detail_3" data-bs-toggle="tab" data-bs-target="#contact-justified" type="button" role="tab" aria-controls="detail_3" aria-selected="false">教學進度</button>
                </li>
                <li class="nav-item flex-fill" role="presentation">
                  <button class="nav-link w-100" id="detail_4" data-bs-toggle="tab" data-bs-target="#contact-justified2" type="button" role="tab" aria-controls="detail_4" aria-selected="false">先修課程</button>
                </li>
              </ul>
              <div class="tab-content pt-2" id="myTabjustifiedContent">
                <div class="tab-pane fade show active" id="home-justified" role="tabpanel" aria-labelledby="detail_1">
                  <table class="table">
                    <tbody>
                      <tr><td className="table-active" scope="col">學年/學期</td><td>{courseDetail.ACADMICYEAR}/{courseDetail.ACADMICTERM}</td></tr>
                      <tr><td className="table-active" scope="col">授課教師</td><td>{courseDetail.NAME}</td></tr>
                      <tr><td className="table-active" scope="col">開課單位</td><td>{courseDetail.DEPARTMENTID}</td></tr>
                      <tr><td className="table-active" scope="col">年級-班別</td><td>{courseDetail.YEAR}-{courseDetail.GROUPID}</td></tr>
                      <tr><td className="table-active" scope="col">科目代號(學分)</td><td>{courseDetail.CALL_ID}({courseDetail.CREDITS})</td></tr>
                      <tr><td className="table-active" scope="col">科目名稱</td><td>{courseDetail.CCOURSENAME}</td></tr>
                      <tr><td className="table-active" scope="col">開課序號</td><td>{courseDetail.SECTIONID}</td></tr>
                      <tr><td className="table-active" scope="col">主要開課序號</td><td>{courseDetail.MAINSECTIONID}</td></tr>
                      <tr><td className="table-active" scope="col">修課學生</td><td>本校{courseDetail.CURRENRL}人</td></tr>
                      <tr><td className="table-active" scope="col">人數限制</td><td>{courseDetail.MINENRL}~{courseDetail.MAXENRL}</td></tr>
                      <tr>
                        <td className="table-active" scope="col">上課時間地點</td>
                        <td>
                          <table className="table table-bordered">
                            <thead>
                              <tr className="table-primary"><th>星期</th><th>開始 / 結束</th><th>教室 [<a href="https://academic.cgu.edu.tw/p/412-1009-8470.php" target="_blank">代碼說明</a>]</th><th></th></tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{courseDetail.time.CWEEKDAY}</td>
                                <td>{courseDetail.time.BEGINSEC} ({courseDetail.time.STARTTIME}) ~  {courseDetail.time.ENDSEC} ({courseDetail.time.ENDTIME})</td>
                                <td>{courseDetail.time.LOCATIONID}</td>
                                <td>{courseDetail.time.CATEGORYNAME}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr><td className="table-active" scope="col">核心能力</td><td><a href={"https://ep.cgu.edu.tw/Webfolio/checkCID.aspx?cid=" + courseDetail.CALL_ID} target="_blank">按此查詢</a></td></tr>
                      <tr><td className="table-active" scope="col">備註</td><td>{courseDetail.ANNOTATION}</td></tr>
                    </tbody>

                  </table>
                </div>
                <div class="tab-pane fade" id="profile-justified" role="tabpanel" aria-labelledby="detail_2">
                  <table class="table">
                    <tbody>
                      <tr><td className="table-active" scope="col">教科書</td><td>{courseDetail.TEXTBOOK}/{courseDetail.ACADMICTERM}</td></tr>
                      <tr><td className="table-active" scope="col">教科書ISBN</td><td>{"0000000000"}</td></tr>
                      <tr><td className="table-active" scope="col">參考書</td><td>{courseDetail.REFBOOK}</td></tr>
                      <tr><td className="table-active" scope="col">課程講義網址</td><td>{courseDetail.LECTURE}</td></tr>
                      <tr><td className="table-active" scope="col">整體教學目標</td><td>{courseDetail.OBJECTIVE}({courseDetail.CREDITS})</td></tr>
                      <tr><td className="table-active" scope="col">教學方法</td><td>{courseDetail.PEDAGOGY}</td></tr>
                      <tr><td className="table-active" scope="col">課程簡介</td><td>{courseDetail.CHTINTRO}</td></tr>
                      <tr><td className="table-active" scope="col">彈性學習</td><td>{""}</td></tr>
                      <tr><td className="table-active" scope="col">成績考核</td><td>本校{courseDetail.SCOREMETHOD}人</td></tr>
                      <tr><td className="table-active" scope="col">師生互動時間</td><td>{courseDetail.OFFICEHOUR}</td></tr>

                    </tbody>
                  </table>
                </div>
                <div class="tab-pane fade" id="contact-justified" role="tabpanel" aria-labelledby="detail_3">
                  <table class="table">

                    {
                      courseDetail.subject.length > 0 ?
                        <thead>
                          <tr className="table-active"><th>項次</th><th>教學主題</th></tr>
                        </thead>
                        :
                        <tr><th className="text-center">無資料</th></tr>
                    }

                    <tbody>
                      {
                        courseDetail.subject.map((item, index) => (
                          <tr><td>{index + 1}</td><td>{item.SECTIONCONTENT}</td></tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
                <div class="tab-pane fade text-center" id="contact-justified2" role="tabpanel" aria-labelledby="detail_4">
                  本課程沒有先修課程規定
                </div>
              </div>
            </div>

          </Modal.Body>
          {/* <Modal.Footer>
        <input type="submit" name="submit" className="btn btn-primary" value="儲存"  />
        <Button variant="secondary" onClick={props.onHide}>取消</Button>
        
        </Modal.Footer> */}
        </form>
      </Modal>
    );
  }

  // function search(event) {
  //   event.preventDefault();
  //   getCourse(event.target.p_TERMID.value, event.target.p_DEPARTMENTID.value)
  // }

  function reset() {
    window.location.href = window.location.href
  }

  function search(event) {

    event.preventDefault();
    let param = '?termid=' + event.target.termid.value
    param += '&departmentid=' + event.target.departmentid.value
    param += '&coursetermid=' + event.target.ctermid.value

    if (event.target.departmentid.value == ''
    ) {
      Swal.fire({
        title: t('請輸入查詢條件'),
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: t('確定')
      })
      return
    }

    const apiUrl = sysConfig.apiUrl_IS + 'Course/GetDepCourseSchedule' + param
    showLoading(true)
    axios.get(apiUrl).then((response) => {

      const res = response.data
      console.log('course', res)
      setCourse(res)

    })
      .catch((error) => {
        console.log(error)
      })
      .then(() => {
        showLoading(false)
      })
  }

  const bodyStyle = {
    height: '500px'
  }

  // ---- <Sort> ----
  const [sort, setSort] = useState({ order: 'asc', orderBy: 'CALL_ID' })
  const sortedRows = useMemo(() => sortRows(course, sort), [course, sort])

  const handleSort = (accessor) => {
    setSort((prevSort) => ({
      order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
      orderBy: accessor,
    }))

  }

  let tdColName = ""

  const columns = [
    { accessor: 'CALL_ID', label: t("科目代號") },
    { accessor: 'CCOURSENAME', label: t("課程名稱") },
    { accessor: 'CREDITS', label: t("學分") },
    { accessor: 'CATEGORYNAME', label: t("必選修") }

  ]

  // ---- </Sort> ----

  const mTableBody = sortedRows.map((item, index) =>
    <tr>
      <td className="td-stext">{item.CALL_ID}</td>
      <td>{i18n.language == 'en' ? item.ECOURSENAME : item.CCOURSENAME}</td>
      <td>
        {
          item.CATEGORYNAME.indexOf('必修') > -1 ?
            <span className="badge bg-danger">{item.CREDITS}</span>
            :
            <span className="badge bg-info">{item.CREDITS}</span>
        }
      </td>
      <td>
      {t(item.CATEGORYNAME)}
      </td>
    </tr>
  )

  

  const mTable = (
    <table className="table m-table">
      <thead>
        <tr>
          {columns.map((column,index) => {
            const sortIcon = () => {
              if (column.accessor === sort.orderBy) {
                if (sort.order === 'asc') {
                  return <i class="bi bi-sort-up"></i>
                }
                return <i class="bi bi-sort-down"></i>
              } else {
                return '️'
              }
            }

            tdColName += '.m-table td:nth-of-type(' + (index + 1) + '):before {content : "' + column.label + '"}'
            
            return (
              <th style={{ cursor: 'pointer' }} key={column.accessor} onClick={() => handleSort(column.accessor)}>
                <style>{tdColName}</style>
                <span>{column.label} {sortIcon()}</span>               
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {
          course.length > 0 ?
            (
              <>{mTableBody}</>
            )
            :
            <tr><td align="center" colSpan={10}>無資料</td></tr>
        }
      </tbody>
    </table>
  )  

  function formatSemester(year, term) {
    if(i18n.language == 'en') {
      year = year + 1911
      switch(term) {
        case '1':
          term = 'FALL'
          break
        case '2':
          term = 'SPRING'
          break
        case '3':
          term = 'SUMMER'
          break          
      }
    } 
    return year + '/' + term
  }

  const Main = (
    <>
      <main>
        <div className="container">
          <section className="section  min-vh-100 d-flex flex-column   py-4">


            <div className="container">

              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">{t("系所必選修科目")}</h5>
                  <form class="row g-3" onSubmit={search}>
                  <div class="col-6 col-md-4">
                      <div class="form-floating">
                        {/* <input type="text" class="form-control" name="p_TERMID" placeholder="開課學期"/> */}
                        <select class="form-select" name="termid" aria-label="State" onChange={handleOnChangeTerm}>
                          {
                            sem.map(item => (
                              <option value={item.TERMID}>
                                {i18n.language == 'en' ? item.CEYEAR : item.ACADMICYEAR}                              
                              </option>
                            ))
                          }
                        </select>
                        <label for="">{t("入學年度")}</label>
                      </div>
                    </div>                    
                    <div class="col-6 col-md-4">
                      <div class="form-floating">
                        {/* <input type="text" class="form-control" name="p_DEPARTMENTID" placeholder="開課單位"/> */}
                        <select class="form-select" name="departmentid" aria-label="State" onChange={handleOnChangeDep}>
                          <option value="">- {t("請選擇")} -</option>
                          {
                            dep.map(item => (
                              <option value={item.DEPARTMENTID}>{i18n.language == 'en' && item.ENGDEPALIAS != null ? item.ENGDEPALIAS : item.CHTDEPNAME}</option>
                            ))
                          }
                        </select>
                        <label for="">{t("系所組別")}</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="form-floating">
                        {/* <input type="text" class="form-control" name="p_TERMID" placeholder="開課學期"/> */}
                        <select class="form-select" name="ctermid" aria-label="State" >
                        
                          {
                            depsem.length == 0 ?
                            <option value="">- {t("請選擇")} -</option>
                            :
                            depsem.map(item => (
                              
                                item.TERMID != 0 ?
                                <option value={item.TERMID}>{formatSemester(item.ACADMICYEAR, item.ACADMICTERM)}</option>
                                :
                                <>
                                <option value={0}>{t("未確定")}</option>
                                </>
                                                            
                            ))
                          }
                        </select>
                        <label for="">{t("開課學期")}</label>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div class="col-6 col-md-10">
                      </div>
                      <div class="col-6 col-md-2 d-grid gap-2">
                        <button className="btn btn-primary" type="submit"><i className="bi bi-search"></i> {t("搜尋")}</button>
                      </div>
                    </div>


                    {/* <div className="row">
                      <div className="col-lg-12 text-center py-2">
                        
                        <button className="btn btn-primary m-1" type="submit"><i className="bi bi-search"></i> {t("搜尋")}</button>
                        <button className="btn btn-secondary m-1" onClick={() => reset()}><i className="bi bi-x"></i> {t("清除")}</button>
                      </div>
                    </div> */}

                  </form>


                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                          <div className="card-title">
                            {t("系所必選修科目列表")}
                            <div className="d-inline text-tip float-end" >{t("圖示")}: <span className="badge bg-danger">&nbsp;</span> {t("必修")}  <span className="badge bg-info">&nbsp;</span> {t("選修")}</div>
                          </div>
                      {mTable}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>
        </div>
      </main>
    </>
  )

  return (
    <LayoutSc>
      {Main}

      <DetailModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

    </LayoutSc>

  )
}

export default DepCourse