import React from "react"
import Layout from "../../common/Layout"
import { useContext, useState, useMemo } from "react";
import { AppContext } from '../../App';
import axios from "axios";
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
import LayoutSc from './LayoutSc'
import { sortRows, filterRows, paginateRows } from '../../common/Helpers'
import CourseDetail from './CourseDetail'
const CourseData = () => {
  const { t, i18n } = useTranslation();
  const [loading, openLoading] = useState(false);
  const { sysConfig, showLoading } = useContext(AppContext)
  const [course, setCourse] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [sem, setSem] = useState([]);
  const [dep, setDep] = useState([]);
  const [courseDetail, setCourseDetail] = useState({ time: [], subject: [] });
  const [d_cobj, setd_cobj] = React.useState(null);

  const queryParams = new URLSearchParams(window.location.search)
  let sectionid = queryParams.get("sectionid").trim()

  React.useEffect(() => {

    if(sectionid != null && sectionid != '') {
      openCourseDetail(sectionid)
    }

  }, []);

  function openCourseDetail(sectionid) {
    //const cobj = course[index]    

    let sUrl = sysConfig.apiUrl_IS + 'Course/GetCourseSections?sectionid=' + sectionid
    let sMethod = 'get'

    axios({
      method: sMethod,
      url: sUrl
    })
      .then((response) => {
        console.log(response.data);
        setd_cobj(response.data[0])
      })
      .catch((error) => {
        console.log(error)
      })


    //alert(sectionid)
  }


  const Main = (
    <>
      <main>
        <div className="container">
          <section className="section  min-vh-100 d-flex flex-column   py-4">


            <div className="container">

            <CourseDetail
                      cobj={d_cobj}
                      show={true}
                     
                    />          

            </div>
          </section>
        </div>
      </main>
    </>
  )

  return (
   <>
   {Main}
   </>

  )
}

export default CourseData