import React from "react"
import Layout from "../../common/Layout"
import { useContext, useState } from "react";
import { AppContext } from '../../App';
import axios from "axios";
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
//import {ShowMsg} from "./../../common/Util";

const CourseTest = () => {
  const { t, i18n } = useTranslation();
  const [loading, openLoading] = useState(false);
  const { sysConfig } = useContext(AppContext)
  const [course, setCourse] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [sem, setSem] = useState([]);
  const [dep, setDep] = useState([]);
  const [courseDetail, setCourseDetail] = useState({ time: [], subject: [] });
  const [isBooking, setIsBooking] = useState(false);
  const [chkList, setChkList] = useState([]);
  const [accVal1, setAccVel1] = useState('')
  const [accVal2, setAccVel2] = useState('show')
  const [isReg, setIsReg] = useState(false)
  const [stuCourse, setStuCourse] = useState([])
  const [isLogin, setisLogin] = useState(false)
  
  localStorage.removeItem('bookingIds')

  const queryParams = new URLSearchParams(window.location.search)
  let lang = queryParams.get("lang")
  if (lang == null) lang = 'zh_tw'
  if (localStorage.getItem('i18n') != lang) {
    localStorage.setItem('i18n', lang)
    i18n.language = lang
    window.location.href = window.location.href
  }
  const skey = queryParams.get("skey")
  let stid = ''
  try {
    stid = atob(skey)
  } 
  catch {
    window.location.replace('./autherror')
  }

  console.log('isBooking', isBooking)
 
  React.useEffect(() => {

    if (window.location.href.indexOf('booking=1') > -1) {
      setIsBooking(true)
    }
    else {
      setIsBooking(false)
    }
    if(checkToken()) {    
      checkReg();
      getSelData();
    }

  }, []);

  function checkToken() {
    const atoken = queryParams.get("atoken")

    const apiUrl = sysConfig.apiUrl + 'Auth/ValiAzureToken?tokenStr=' + atoken

    axios.get(apiUrl).then((response) => {

      const res = response.data
      console.log('checkToken', res)    
      if(res.code == 200 && stid != '')   {
        setisLogin(true)
        Swal.fire({
          title: '驗證成功！',
          text: '帳號: '+stid,
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: '確定'
        })        
      } else {
        window.location.replace('./autherror')
      }

      
      
    })
      .catch((error) => {
        console.log(error)
      }).then(() => {
       
      })
  }

  function checkStuCourse(sid) {
    var sindex = stuCourse.findIndex(x => x.SECTIONID == sid);
    if (sindex > -1)
      return true
    else
      return false
  }

  function getStudentSection(termid) {
    openLoading(true)
    //event.preventDefault();
    let param = '?termid=' + termid + '&studentid=' + stid

    const apiUrl = sysConfig.apiUrl_IS + 'Course/GetStudentSection' + param

    axios.get(apiUrl).then((response) => {

      const res = response.data
      console.log('getStudentSection', res)
      setStuCourse(res)

    })
      .catch((error) => {
        console.log(error)
      }).then(() => {
        openLoading(false)
      })
  }

  function checkReg() {

    let sUrl = sysConfig.apiUrl_IS + 'Course/CheckTermRegSchedule'
    let sMethod = 'post'

    axios({
      method: sMethod,
      url: sUrl
    })
      .then((response) => {
        console.log('CheckTermRegSchedule', response.data);
        if (response.data.RESULT == 1) {
          setIsReg(true)
        }

        search()

      })
      .catch((error) => {
        console.log(error)
      })

  }
  
  function addCourse(item) {

    let disName = ""
    if (i18n.language == 'en') {
      disName = item.SECTIONID + " " + item.ECOURSENAME
    } else {
      disName = item.SECTIONID + " " + item.CCOURSENAME
    }

    Swal.fire({
      title: t("確定加選這筆課程"),
      text: disName,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t("確定"),
      cancelButtonText: t("取消")
    }).then((result) => {
      if (result.isConfirmed) {

        let sUrl = sysConfig.apiUrl_IS + 'Course/AddCourse?studentid=' + stid + '&sectionid=' + item.SECTIONID
        let sMethod = 'post'

        axios({
          method: sMethod,
          url: sUrl
        })
          .then((response) => {
            console.log(response.data);
            if (response.data[0].RESULT == 1) {
              Swal.fire({
                title: response.data[0].MESSAGE.split('msg:')[1],
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: '確定'
              })
            } else {            
              
              Swal.fire({
                title: response.data[0].MESSAGE.split('msg:')[1],
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: '確定'
              })
             
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    })

  }

  function delCourse(item) {

    let disName = ""
    if (i18n.language == 'en') {
      disName = item.SECTIONID + " " + item.ECOURSENAME
    } else {
      disName = item.SECTIONID + " " + item.CCOURSENAME
    }

    Swal.fire({
      title: '確定退選這筆課程',
      text: disName,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '確定',
      cancelButtonText: '取消'
    }).then((result) => {
      if (result.isConfirmed) {
        let sUrl = sysConfig.apiUrl_IS + 'Course/DelCourse?sectionid=' + item.SECTIONID + '&studentid=' + stid
        let sMethod = 'post'

        openLoading(true)

        axios({
          method: sMethod,
          url: sUrl
        })
          .then((response) => {
            console.log(response.data);
            if (response.data[0].RESULT == 1) {
              Swal.fire({
                title: response.data[0].MESSAGE.split('msg:')[1],
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: '確定'
              })
            } else {
              ///util.showMsg('error', response.data[0].MESSAGE.split('msg:')[1])
            }

          })
          .catch((error) => {
            console.log(error)           
          })
          .then(() => {
            openLoading(false)
          })
      }
    })

  }

  function getSelData() {
    let endpoints = [
      sysConfig.apiUrl_IS + "Course/GetSemester",
      sysConfig.apiUrl_IS + "Course/GetDepartment"
    ];
    axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
      (data) => {
        console.log('all', data[0], data[1])
        setSem(data[0].data)
        setDep(data[1].data)

        const tid = data[0].data[0].TERMID
        getStudentSection(tid)
      }
    );
  }

  function showDetail(index, itemid, sectionid) {
    console.log(index, itemid, sectionid)
    openLoading(true)
    let endpoints = [
      sysConfig.apiUrl_IS + "Course/GetSectionTime?sectionid=" + sectionid,
      sysConfig.apiUrl_IS + "Course/GetSectionSchedules?sectionid=" + sectionid
    ];
    axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
      (data) => {
        console.log('all', data[0], data[1])
        let cobj = course[index]
        cobj.time = data[0].data[0]
        cobj.subject = data[1].data
        setCourseDetail(cobj)

        setModalShow(true)
      }
    ).then(() => {
      openLoading(false)
    })





  }

  function DetailModal(props) {

    return (
      <Modal
        {...props}
        size="lg"

        keyboard="false"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form onSubmit="">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              課程詳細資料
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="row">
              <ul class="nav nav-tabs d-flex" id="myTabjustified" role="tablist">
                <li class="nav-item flex-fill" role="presentation">
                  <button class="nav-link w-100 active" id="detail_1" data-bs-toggle="tab" data-bs-target="#home-justified" type="button" role="tab" aria-controls="detail_1" aria-selected="true">課程資訊</button>
                </li>
                <li class="nav-item flex-fill" role="presentation">
                  <button class="nav-link w-100" id="detail_2" data-bs-toggle="tab" data-bs-target="#profile-justified" type="button" role="tab" aria-controls="detail_2" aria-selected="false">教學大綱</button>
                </li>
                <li class="nav-item flex-fill" role="presentation">
                  <button class="nav-link w-100" id="detail_3" data-bs-toggle="tab" data-bs-target="#contact-justified" type="button" role="tab" aria-controls="detail_3" aria-selected="false">教學進度</button>
                </li>
                <li class="nav-item flex-fill" role="presentation">
                  <button class="nav-link w-100" id="detail_4" data-bs-toggle="tab" data-bs-target="#contact-justified2" type="button" role="tab" aria-controls="detail_4" aria-selected="false">先修課程</button>
                </li>
              </ul>
              <div class="tab-content pt-2" id="myTabjustifiedContent">
                <div class="tab-pane fade show active" id="home-justified" role="tabpanel" aria-labelledby="detail_1">
                  <table class="table">
                    <tbody>
                      <tr><td className="table-active" scope="col">學年/學期</td><td>{courseDetail.ACADMICYEAR}/{courseDetail.ACADMICTERM}</td></tr>
                      <tr><td className="table-active" scope="col">授課教師</td><td>{courseDetail.NAME}</td></tr>
                      <tr><td className="table-active" scope="col">開課單位</td><td>{courseDetail.DEPARTMENTID}</td></tr>
                      <tr><td className="table-active" scope="col">年級-班別</td><td>{courseDetail.YEAR}-{courseDetail.GROUPID}</td></tr>
                      <tr><td className="table-active" scope="col">科目代號(學分)</td><td>{courseDetail.CALL_ID}({courseDetail.CREDITS})</td></tr>
                      <tr><td className="table-active" scope="col">科目名稱</td><td>{courseDetail.CCOURSENAME}</td></tr>
                      <tr><td className="table-active" scope="col">開課序號</td><td>{courseDetail.SECTIONID}</td></tr>
                      <tr><td className="table-active" scope="col">主要開課序號</td><td>{courseDetail.MAINSECTIONID}</td></tr>
                      <tr><td className="table-active" scope="col">修課學生</td><td>本校{courseDetail.CURRENRL}人</td></tr>
                      <tr><td className="table-active" scope="col">人數限制</td><td>{courseDetail.MINENRL}~{courseDetail.MAXENRL}</td></tr>
                      <tr>
                        <td className="table-active" scope="col">上課時間地點</td>
                        <td>
                          <table className="table table-bordered">
                            <thead>
                              <tr className="table-primary"><th>星期</th><th>開始 / 結束</th><th>教室 [<a href="https://academic.cgu.edu.tw/p/412-1009-8470.php" target="_blank">代碼說明</a>]</th><th></th></tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{courseDetail.time.CWEEKDAY}</td>
                                <td>{courseDetail.time.BEGINSEC} ({courseDetail.time.STARTTIME}) ~  {courseDetail.time.ENDSEC} ({courseDetail.time.ENDTIME})</td>
                                <td>{courseDetail.time.LOCATIONID}</td>
                                <td>{courseDetail.time.CATEGORYNAME}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr><td className="table-active" scope="col">核心能力</td><td><a href={"https://ep.cgu.edu.tw/Webfolio/checkCID.aspx?cid=" + courseDetail.CALL_ID} target="_blank">按此查詢</a></td></tr>
                      <tr><td className="table-active" scope="col">備註</td><td>{courseDetail.ANNOTATION}</td></tr>
                    </tbody>

                  </table>
                </div>
                <div class="tab-pane fade" id="profile-justified" role="tabpanel" aria-labelledby="detail_2">
                  <table class="table">
                    <tbody>
                      <tr><td className="table-active" scope="col">教科書</td><td>{courseDetail.TEXTBOOK}</td></tr>
                      <tr><td className="table-active" scope="col">教科書ISBN</td><td>{"0000000000"}</td></tr>
                      <tr><td className="table-active" scope="col">參考書</td><td>{courseDetail.REFBOOK}</td></tr>
                      <tr><td className="table-active" scope="col">課程講義網址</td><td>{courseDetail.LECTURE}</td></tr>
                      <tr><td className="table-active" scope="col">整體教學目標</td><td>{courseDetail.OBJECTIVE}({courseDetail.CREDITS})</td></tr>
                      <tr><td className="table-active" scope="col">教學方法</td><td>{courseDetail.PEDAGOGY}</td></tr>
                      <tr><td className="table-active" scope="col">課程簡介</td><td>{courseDetail.CHTINTRO}</td></tr>
                      <tr><td className="table-active" scope="col">彈性學習</td><td>{""}</td></tr>
                      <tr><td className="table-active" scope="col">成績考核</td><td>本校{courseDetail.SCOREMETHOD}人</td></tr>
                      <tr><td className="table-active" scope="col">師生互動時間</td><td>{courseDetail.OFFICEHOUR}</td></tr>

                    </tbody>
                  </table>
                </div>
                <div class="tab-pane fade" id="contact-justified" role="tabpanel" aria-labelledby="detail_3">
                  <table class="table">

                    {
                      courseDetail.subject.length > 0 ?
                        <thead>
                          <tr className="table-active"><th>項次</th><th>教學主題</th></tr>
                        </thead>
                        :
                        <tr><th className="text-center">無資料</th></tr>
                    }

                    <tbody>
                      {
                        courseDetail.subject.map((item, index) => (
                          <tr><td>{index + 1}</td><td>{item.SECTIONCONTENT}</td></tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
                <div class="tab-pane fade text-center" id="contact-justified2" role="tabpanel" aria-labelledby="detail_4">
                  本課程沒有先修課程規定
                </div>
              </div>
            </div>

          </Modal.Body>
          {/* <Modal.Footer>
        <input type="submit" name="submit" className="btn btn-primary" value="儲存"  />
        <Button variant="secondary" onClick={props.onHide}>取消</Button>
        
        </Modal.Footer> */}
        </form>
      </Modal>
    );
  }

  // function search(event) {
  //   event.preventDefault();
  //   getCourse(event.target.p_TERMID.value, event.target.p_DEPARTMENTID.value)
  // }

  function reset() {
    window.location.href = window.location.href
  }

  function search(event) {

    event.preventDefault();
    let param = '?termid=' + event.target.termid.value
    param += '&departmentid=' + event.target.departmentid.value
    param += '&call_id=' + event.target.call_id.value
    param += '&keyward=' + event.target.keyward.value
    param += '&sectionid=' + event.target.sectionid.value
    param += '&teaName=' + event.target.teaName.value
    param += '&cName=' + event.target.cName.value
    param += '&year=' + event.target.year.value
    param += '&engdepalias=' + event.target.engdepalias.value
    param += '&week=' + event.target.week.value
    param += '&stime=' + event.target.stime.value
    param += '&etime=' + event.target.etime.value
    if (event.target.lang.checked) param += '&lang=E'

    if (event.target.departmentid.value == ''
      && event.target.call_id.value == ''
      && event.target.keyward.value == ''
      && event.target.sectionid.value == ''
      && event.target.teaName.value == ''
      && event.target.cName.value == ''
      && event.target.engdepalias.value == ''
      && event.target.year.value == ''
      && event.target.lang.checked == false
    ) {
      Swal.fire({
        title: '請輸入查詢條件',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: '確定'
      })
      return
    }

    if (event.target.stime.value > event.target.etime.value) {
      Swal.fire({
        title: '上課時間(結束時間)不可小於上課時間(開始時間)',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: '確定'
      })
      return
    }

    const apiUrl = sysConfig.apiUrl_IS + 'Course/GetCourseSections' + param

    openLoading(true)
    axios.get(apiUrl).then((response) => {

      const res = response.data
      console.log('course', res)
      setCourse(res)

    })
      .catch((error) => {
        console.log(error)
      })
      .then(() => {
        openLoading(false)
        setAccVel1('collapsed')
        setAccVel2('')
      })
  }

  const bodyStyle = {
    height: '500px'
  }

  function handleOnChange(id) {
    let list = chkList
    list.push(id)
    setChkList(list)
    console.log('chklist', chkList)
    localStorage.setItem('bookingIds', list)
  }

  function getChkList() {
    return chkList
  }

  const mTableBody = course.map((item, index) =>
    <tr>
      {
        isBooking == true ?
          <td><input type="checkbox" name="chkCourse" value={item.SECTIONID} onChange={() => handleOnChange(item.SECTIONID)} /></td>
          :
          <></>
      }

      {/* <td></td> */}
      {/* <td></td>
      <td></td>
      <td></td> */}
      {/* <td>{item.YEAR}</td> */}

      <td>{item.CALL_ID}</td>
      <td>{item.SECTIONID}</td>
      <td>
        <a href="javascript:void(0)" onClick={() => showDetail(index, item.TERMID, item.SECTIONID)}>

          {
            item.CLASSIFICATIONCATNAME.indexOf('必修') > -1 ?
              <span className="badge bg-primary">{t("必修")}</span>
              :
              <span className="badge bg-info">{t("選修")}</span>
          } &nbsp;

          {i18n.language == 'en' ? item.DEPARTMENTNAME_E : item.DEPARTMENTNAME_C}
          <span className="">[{item.YEAR}]</span>
          {
            i18n.language == 'en' ? item.ECOURSENAME : item.CCOURSENAME
          }
          &nbsp;
          <span className="badge bg-secondary">{item.NAME}</span>&nbsp;
        </a>
        {
          //isReg ?
          true ?
            <>
              {
                checkStuCourse(item.SECTIONID) == false ?
                  <a href="javascript:void(0);" onClick={() => addCourse(item)}>
                    <span className="badge bg-success"><i className="bi bi-plus-circle"></i> {t("加選")}</span>
                  </a>
                  :
                  <></>
              }
              {
                checkStuCourse(item.SECTIONID) == true ?
                  <>                    
                    <a href="javascript:void(0);" onClick={() => delCourse(item)}>
                      <span className="badge bg-danger"><i className="bi bi-plus-circle"></i> {t("退選")}</span>
                    </a>
                  </>
                  :
                  <></>
              }
            </>
            :
            <></>
        }


      </td>
      {/* <td>{item.NAME}</td> */}
      <td>{item.CREDITS}</td>
      <td>
        {
          item.C_PTIME != null ?
            item.C_PTIME.split('\n').map((line, i) => (
              <div className="" key={i}>
                {line}
              </div>
            ))
            :
            <>{t("無")}</>
        }
      </td>
      <td>{item.CURRENRL}/{item.MAXENRL}</td>
    </tr>
  )

  const mTable = (
    <table className="table">
      <thead>
        <tr>
          {
            isBooking == true ?
              <th nowrap="nowrap">選擇</th>
              :
              <></>
          }
          {/* <th nowrap="nowrap">學年/學期</th> */}
          {/* 
          <th nowrap="nowrap">開課單位</th> */}
          {/* <th nowrap="nowrap">年級</th> */}
          <th nowrap="nowrap">{t("科目代號")}</th>
          <th nowrap="nowrap">{t("開課序號")}</th>
          <th nowrap="nowrap">{t("課程名稱")}</th>
          {/* <th nowrap="nowrap">授課教師</th> */}
          <th nowrap="nowrap">{t("學分")}</th>
          <th nowrap="nowrap">{t("上課時間")}</th>
          <th nowrap="nowrap">{t("選課人數")}</th>
        </tr>
      </thead>
      <tbody>
        {
          course.length > 0 ?
            (
              <>{mTableBody}</>
            )
            :
            <tr><td align="center" colSpan={10}>無資料</td></tr>
        }
      </tbody>
    </table>
  )

  const Main = (
    <>
      <main>
        <div className="container">
          <section className="section  min-vh-100 d-flex flex-column   py-4">


            <div className="container">

              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">{t("課程查詢")} | <a href="/depcourse" target="_blank">{t("系所必選修科目")}</a></h5>
                  <form class="row g-3" onSubmit={search}>
                    <div class="col-md-3">
                      <div class="form-floating">
                        {/* <input type="text" class="form-control" name="p_TERMID" placeholder="開課學期"/> */}
                        <select class="form-select" name="termid" aria-label="State" disabled={isBooking}>
                          {
                            sem.map(item => (
                              <option value={item.TERMID}>{item.CTERMNAME}</option>
                            ))
                          }
                        </select>
                        <label for="">{t("開課學期")}</label>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-floating">
                        {/* <input type="text" class="form-control" name="p_DEPARTMENTID" placeholder="開課單位"/> */}
                        <select class="form-select" name="departmentid" aria-label="State">
                          <option value="">- {t("請選擇")} -</option>
                          {
                            dep.map(item => (
                              <option value={item.DEPARTMENTID}>
                                {i18n.language == 'en' ?
                                  item.ENGDEPALIAS != null ? item.ENGDEPALIAS : item.CHTDEPALIAS
                                  :
                                  item.CHTDEPALIAS}
                              </option>
                            ))
                          }
                        </select>
                        <label for="">{t("開課單位")}</label>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-floating">
                        <input type="text" class="form-control" name="keyward" placeholder={t("關鍵字")} />
                        <label for="floatingPassword">{t("關鍵字")}</label>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-floating">
                        <input type="text" class="form-control" name="call_id" placeholder={t("科目代號")} />
                        <label for="floatingPassword">{t("科目代號")}</label>
                      </div>
                    </div>


                    <div class="accordion accordion-flush" id="accordionFlushExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                          <button className={'accordion-button ' + accVal1} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                            更多條件
                          </button>
                        </h2>
                        <div id="flush-collapseOne" className={'accordion-collapse collapse ' + accVal2} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                          <div class="accordion-body col-md-12">
                            <div className="row">
                              <div class="col-md-2">
                                <div class="form-floating">
                                  <input type="text" class="form-control" name="sectionid" placeholder="x" />
                                  <label for="floatingEmail">{t("開課序號")}</label>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="form-floating">
                                  <input type="text" class="form-control" name="teaName" placeholder="x" />
                                  <label for="floatingPassword">{t("授課教師")}</label>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="form-floating">
                                  <input type="text" class="form-control" name="cName" placeholder="x" />
                                  <label for="floatingPassword">{t("課程名稱")}</label>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="form-floating">
                                  <input type="text" class="form-control" name="year" placeholder="x" />
                                  <label for="floatingPassword">{t("年級")}</label>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="form-floating">
                                  <select name="engdepalias" class="form-select" >
                                    <option value="">- {t("請選擇")} -</option>
                                    <option value="SM">{t("軍訓課程")}</option>
                                    <option value="SP">{t("體育課程")}</option>
                                  </select>
                                  <label for="floatingPassword">{t("通識領域")}</label>
                                </div>
                              </div>
                              <div class="col-2 d-flex align-items-center">
                                <div class="form-check">
                                  <input class="form-check-input" type="checkbox" value="E" name="lang" />
                                  <label class="form-check-label" for="gridCheck">
                                    {t("英語授課")}
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-2">
                              <div class="col-md-4">
                                <div class="form-floating mb-3">
                                  <select class="form-select" name="week" id="floatingSelect" aria-label="State">
                                    <option value="">- {t("請選擇")} -</option>
                                    <option value="2">{t("一")}</option>
                                    <option value="3">{t("二")}</option>
                                    <option value="4">{t("三")}</option>
                                    <option value="5">{t("四")}</option>
                                    <option value="6">{t("五")}</option>
                                    <option value="7">{t("六")}</option>
                                    <option value="1">{t("日")}</option>
                                  </select>
                                  <label for="floatingSelect">{t("上課時間")}({t("星期")})</label>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-floating mb-3">
                                  <select class="form-select" name="stime">
                                    <option value="">- {t("請選擇")} -</option>
                                    <option value="1">&nbsp;&nbsp;1 (08:10)</option>
                                    <option value="2">&nbsp;&nbsp;2 (09:10)</option>
                                    <option value="3">&nbsp;&nbsp;3 (10:10)</option>
                                    <option value="4">&nbsp;&nbsp;4 (11:10)</option>
                                    <option value="5">&nbsp;&nbsp;5 (13:10)</option>
                                    <option value="6">&nbsp;&nbsp;6 (14:10)</option>
                                    <option value="7">&nbsp;&nbsp;7 (15:10)</option>
                                    <option value="8">&nbsp;&nbsp;8 (16:10)</option>
                                    <option value="9">&nbsp;&nbsp;9 (17:10)</option>
                                    <option value="10">10 (18:10)</option>
                                    <option value="11">11 (19:10)</option>
                                    <option value="12">12 (20:10)</option>
                                    <option value="13">13 (21:10)</option>
                                  </select>
                                  <label for="floatingSelect">{t("上課時間")}({t("開始時間")})</label>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-floating mb-3">
                                  <select class="form-select" name="etime" aria-label="State">
                                    <option value="">- {t("請選擇")} -</option>
                                    <option value="1">&nbsp;&nbsp;1 (09:00)</option>
                                    <option value="2">&nbsp;&nbsp;2 (10:00)</option>
                                    <option value="3">&nbsp;&nbsp;3 (11:00)</option>
                                    <option value="4">&nbsp;&nbsp;4 (12:00)</option>
                                    <option value="5">&nbsp;&nbsp;5 (14:00)</option>
                                    <option value="6">&nbsp;&nbsp;6 (15:00)</option>
                                    <option value="7">&nbsp;&nbsp;7 (16:00)</option>
                                    <option value="8">&nbsp;&nbsp;8 (17:00)</option>
                                    <option value="9">&nbsp;&nbsp;9 (18:00)</option>
                                    <option value="10">10 (19:00)</option>
                                    <option value="11">11 (20:00)</option>
                                    <option value="12">12 (21:00)</option>
                                    <option value="13">13 (22:00)</option>

                                  </select>
                                  <label for="floatingSelect">{t("上課時間")}({t("結束時間")})</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="row">
                    <div className="col-lg-4">
                      <div class="row mb-3">
                        <label for="inputText" class="col-sm-4 col-form-label">開課學期</label>
                        <div class="col-sm-8">
                          <input type="text" name="p_TERMID" class="form-control"/>
                        </div>
                      </div>                     
                    </div>
                    <div className="col-lg-8">                     
                      <div class="row mb-2">
                        <label for="inputText" class="col-sm-4 col-form-label">開課單位</label>
                        <div class="col-sm-10">
                          <input type="text" name="p_DEPARTMENTID" class="form-control"/>
                        </div>
                      </div>                      
                    </div>
                  </div> */}



                    <div className="row">
                      <div className="col-lg-12 text-center py-2">

                        {/* <input type="submit" name="submit" className="btn btn-primary " value="搜尋"  /> */}
                        <button className="btn btn-primary m-1" type="submit"><i className="bi bi-search"></i> 搜尋</button>
                        <button className="btn btn-secondary m-1" onClick={() => reset()}><i className="bi bi-x"></i> 清除</button>
                      </div>
                    </div>

                  </form>


                </div>
              </div>

              {
                course.length > 0 ?
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">課程列表</h5>
                          {mTable}
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <></>
              }

            </div>
          </section>
        </div>
      </main>
    </>
  )

  return (
    <>
    
    {
      isLogin ?
      <>{Main}</>
      :
      <>
        <h3 className="text-center p-10"><br></br>驗證中...</h3> 
      </>
    }
      

      <DetailModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

    </>

  )
}

export default CourseTest