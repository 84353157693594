import React from "react"
import Layout from "../../common/Layout"
import { useContext, useState, useMemo } from "react";
import { AppContext } from '../../App';
import axios from "axios";
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
import LayoutSc from './LayoutSc'
import { sortRows, filterRows, paginateRows } from '../../common/Helpers'
import { checkLang, getToken } from "../../common/Util";
import CourseDetail from './CourseDetail'
import { forEach } from "lodash";

const Course = () => {
  const { t, i18n } = useTranslation();
  const [loading, openLoading] = useState(false);
  const { sysConfig, showLoading } = useContext(AppContext)
  const [course, setCourse] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [sem, setSem] = useState([]);
  const [dep, setDep] = useState([]);
  const [genlist, setGenlist] = useState([]);
  const [gencourse, setGencourse] = useState([]);
  const [courseDetail, setCourseDetail] = useState({ time: [], subject: [] });
  const [isBooking, setIsBooking] = useState(false);
  const [chkList, setChkList] = useState([]);
  const [accVal1, setAccVel1] = useState('')
  const [accVal2, setAccVel2] = useState('show')
  const [isReg, setIsReg] = useState(false)
  const [stuCourse, setStuCourse] = useState([])
  const [currTid, setCurrTid] = useState(0)
  const [d_cobj, setd_cobj] = React.useState(null);
  const [modalShowCourseDetail, setModalShowCourseDetail] = React.useState(false);
  const [def_dep, setdef_dep] = useState("");
  const [def_tea, setdef_tea] = useState("");
  const [isSearch, setisSearch] = useState(false);
  const [sTermid, setsTermid] = useState(0);
  const [rTermid, setrTermid] = useState(0);
  const [checked, setchecked] = useState(false);
  localStorage.removeItem('bookingIds')
  

  checkLang()

  const queryParams = new URLSearchParams(window.location.search)
  let lang = queryParams.get("lang")
  // if (lang == null) lang = 'zh_tw'
  // if (localStorage.getItem('i18n') != lang) {
  //   localStorage.setItem('i18n', lang)
  //   i18n.language = lang
  //   window.location.href = window.location.href
  // }
  //const skey = queryParams.get("skey")
  //const stid = atob(skey)
  let stid = ''

  console.log('isBooking', isBooking)

  React.useEffect(() => {
    showLoading(true)
    if (window.location.href.indexOf('booking=1') > -1) {
      setIsBooking(true)
    }
    else {
      setIsBooking(false)
    }
    
    getSelData()
    checkSSOInfo()

  }, []);

  function checkSSOInfo() {
    console.log('check login')

    if (window.location.href.indexOf('?') == -1 
          && localStorage.getItem('sso') == '1'
          && window.getLoginInfo() == false) 
    {
      window.loginSSO_Redirect()
      // Swal.fire({
      //   title: t('請登入長庚大學帳號'),
      //   icon: 'info',
      //   showCancelButton: false,
      //   allowOutsideClick: false,
      //   showConfirmButton: true,
      //   confirmButtonText: t('登入')
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     window.loginSSO()
      //   }
      // })
    }    

  }

  var style = 'def'     
  var searchIcon = './img/m-search.png'

  if(localStorage.getItem('style') != null) {
    style = localStorage.getItem('style')
  } 
  if(style == 'dark') {
    searchIcon = './img/m-search-b.png'
  }

  function openCourseDetail(cobj) {
    //const cobj = course[index]    

    let sUrl = sysConfig.apiUrl_IS + 'Course/GetCourseSections?sectionid=' + cobj.sectionid
    let sMethod = 'get'

    axios({
      method: sMethod,
      url: sUrl
    })
      .then((response) => {
        console.log(response.data);
        setd_cobj(response.data[0])
        setModalShowCourseDetail(true)
      })
      .catch((error) => {
        console.log(error)
      })


    //alert(sectionid)
  }

  function checkToken() {
    const atoken = queryParams.get("atoken")

    const apiUrl = sysConfig.apiUrl + 'Auth/ValiAzureToken?tokenStr=' + atoken

    axios.get(apiUrl).then((response) => {

      const res = response.data
      console.log('checkToken', res)
      if (res.code == 200) {
        return true
      }
      else {
        window.location.replace('./autherror');
      }
    })
      .catch((error) => {
        console.log(error)
      }).then(() => {
        return true
      })
  }

  function checkStuCourse(sid) {
    var sindex = stuCourse.findIndex(x => x.SECTIONID == sid);
    if (sindex > -1)
      return true
    else
      return false
  }

  function getStudentSection(termid) {
    openLoading(true)   

    //event.preventDefault();
    let param = '?termid=' + termid + '&studentid=' + stid

    const apiUrl = sysConfig.apiUrl_IS + 'Course/GetStudentSection' + param

    axios.get(apiUrl).then((response) => {

      const res = response.data
      console.log('getStudentSection', res)
      setStuCourse(res)

    })
      .catch((error) => {
        console.log(error)
      }).then(() => {
        openLoading(false)
      })
  }

  function checkReg() {

    let sUrl = sysConfig.apiUrl_IS + 'Course/CheckTermRegSchedule'
    let sMethod = 'post'

    axios({
      method: sMethod,
      url: sUrl
    })
      .then((response) => {
        console.log('CheckTermRegSchedule', response.data);
        setrTermid(parseInt(response.data.DATA))
        let reg = false        
        if (response.data.RESULT == 1) {
          reg = true                   
        }
        if(reg == true) {
          if (window.getLoginInfo() == true) {
            stid = localStorage.getItem('stid') 
            
            if(stid != '' && stid != null) {
              let role = localStorage.getItem('role')
              if(role.toLowerCase().indexOf('student') > -1) {
                setIsReg(true)              
                getStudentSection(tid)
              }              
            }
          } 
          
        } 

        //search()

      })
      .catch((error) => {
        console.log(error)
      })

  }

  function getGenList() {
    stid = localStorage.getItem('stid') 

    let endpoints = [
      sysConfig.apiUrl_IS + "Course/GetStudentGenfieldList?studentid="+stid
    ];
    axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
      (data) => {
        console.log('getGenList', data[0])
        setGenlist(data[0].data)       
      }
    );
  }

  function addCourse(item) {

    stid = localStorage.getItem('stid') 

    let disName = ""
    if (i18n.language == 'en') {
      disName = item.SECTIONID + " " + item.ECOURSENAME
    } else {
      disName = item.SECTIONID + " " + item.CCOURSENAME
    }

    Swal.fire({
      title: t("確定加選這門課程"),
      text: disName,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t("確定"),
      cancelButtonText: t("取消")
    }).then((result) => {
      if (result.isConfirmed) {

        let sUrl = sysConfig.apiUrl_IS + 'Course/AddCourse?studentid=' + stid + '&sectionid=' + item.SECTIONID
        let sMethod = 'post'

        axios({
          method: sMethod,
          url: sUrl
        })
          .then((response) => {
            console.log(response.data);
            if (response.data[0].RESULT == 1) {
              Swal.fire({
                title: response.data[0].MESSAGE.split('msg:')[1],
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: '確定'
              })
              window.document.getElementById("search").click()
            } else {

              Swal.fire({
                title: response.data[0].MESSAGE.split('msg:')[1],
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: '確定'
              })

            }
            getStudentSection(currTid)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    })

  }

  function delCourse(item) {

    stid = localStorage.getItem('stid') 

    let disName = ""
    if (i18n.language == 'en') {
      disName = item.SECTIONID + " " + item.ECOURSENAME
    } else {
      disName = item.SECTIONID + " " + item.CCOURSENAME
    }

    Swal.fire({
      title: t('確定退選這門課程'),
      text: disName,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('確定'),
      cancelButtonText: t('取消')
    }).then((result) => {
      if (result.isConfirmed) {
        let sUrl = sysConfig.apiUrl_IS + 'Course/DelCourse?sectionid=' + item.SECTIONID + '&studentid=' + stid
        let sMethod = 'post'

        openLoading(true)

        axios({
          method: sMethod,
          url: sUrl
        })
          .then((response) => {
            console.log(response.data);
            if (response.data[0].RESULT == 1) {
              Swal.fire({
                title: response.data[0].MESSAGE.split('msg:')[1],
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: '確定'
              })
              window.document.getElementById("search").click()
            } else {
              ///util.showMsg('error', response.data[0].MESSAGE.split('msg:')[1])
            }

            getStudentSection(currTid)

          })
          .catch((error) => {
            console.log(error)
          })
          .then(() => {
            openLoading(false)
          })
      }
    })

  }
  let tid = ''
  function getSelData() {
    let endpoints = [
      sysConfig.apiUrl_IS + "Course/GetSemester",
      sysConfig.apiUrl_IS + "Course/GetDepartment"
    ];
    axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
      (data) => {
        console.log('all', data[0], data[1])
        setSem(data[0].data)
        setDep(data[1].data)

        tid = data[0].data[0].TERMID        
        setCurrTid(tid)
                
        if(stid != null) {
          checkReg()
          getGenList()
        }
        showLoading(false)

        let filter_d = queryParams.get("d")
        if(filter_d != null && filter_d != '') {
          filter_d = atob(filter_d)
          setdef_dep(filter_d)       
          setTimeout(() => {
            window.document.getElementById("search").click()
          }, 200);          
        }

        let filter_t = queryParams.get("t")
        if(filter_t != null && filter_t != '') {
          filter_t = decodeURIComponent(atob(filter_t))
          setdef_tea(filter_t)       
          setTimeout(() => {
            window.document.getElementById("search").click()
          }, 200);          
        }

      }
    );
  }

  function showDetail(index, itemid, sectionid) {
    console.log(index, itemid, sectionid)
    openLoading(true)
    let endpoints = [
      sysConfig.apiUrl_IS + "Course/GetSectionTime?sectionid=" + sectionid,
      sysConfig.apiUrl_IS + "Course/GetSectionSchedules?sectionid=" + sectionid
    ];
    axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
      (data) => {
        console.log('all', data[0], data[1])
        let cobj = course[index]
        cobj.time = data[0].data[0]
        cobj.subject = data[1].data
        setCourseDetail(cobj)

        setModalShow(true)
      }
    ).then(() => {
      openLoading(false)
    })





  }

  function DetailModal(props) {

    return (
      <Modal
        {...props}
        size="lg"

        keyboard="false"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form onSubmit="">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              課程詳細資料
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="row">
              <ul class="nav nav-tabs d-flex" id="myTabjustified" role="tablist">
                <li class="nav-item flex-fill" role="presentation">
                  <button class="nav-link w-100 active" id="detail_1" data-bs-toggle="tab" data-bs-target="#home-justified" type="button" role="tab" aria-controls="detail_1" aria-selected="true">課程資訊</button>
                </li>
                <li class="nav-item flex-fill" role="presentation">
                  <button class="nav-link w-100" id="detail_2" data-bs-toggle="tab" data-bs-target="#profile-justified" type="button" role="tab" aria-controls="detail_2" aria-selected="false">教學大綱</button>
                </li>
                <li class="nav-item flex-fill" role="presentation">
                  <button class="nav-link w-100" id="detail_3" data-bs-toggle="tab" data-bs-target="#contact-justified" type="button" role="tab" aria-controls="detail_3" aria-selected="false">教學進度</button>
                </li>
                <li class="nav-item flex-fill" role="presentation">
                  <button class="nav-link w-100" id="detail_4" data-bs-toggle="tab" data-bs-target="#contact-justified2" type="button" role="tab" aria-controls="detail_4" aria-selected="false">先修課程</button>
                </li>
              </ul>
              <div class="tab-content pt-2" id="myTabjustifiedContent">
                <div class="tab-pane fade show active" id="home-justified" role="tabpanel" aria-labelledby="detail_1">
                  <table class="table">
                    <tbody>
                      <tr><td className="table-active" scope="col">學年/學期</td><td>{courseDetail.ACADMICYEAR}/{courseDetail.ACADMICTERM}</td></tr>
                      <tr><td className="table-active" scope="col">授課教師</td><td>{courseDetail.NAME}</td></tr>
                      <tr><td className="table-active" scope="col">開課單位</td><td>{courseDetail.DEPARTMENTID}</td></tr>
                      <tr><td className="table-active" scope="col">年級-班別</td><td>{courseDetail.YEAR}-{courseDetail.GROUPID}</td></tr>
                      <tr><td className="table-active" scope="col">科目代號(學分)</td><td>{courseDetail.CALL_ID}({courseDetail.CREDITS})</td></tr>
                      <tr><td className="table-active" scope="col">科目名稱</td><td>{courseDetail.CCOURSENAME}</td></tr>
                      <tr><td className="table-active" scope="col">開課序號</td><td>{courseDetail.SECTIONID}</td></tr>
                      <tr><td className="table-active" scope="col">主要開課序號</td><td>{courseDetail.MAINSECTIONID}</td></tr>
                      <tr><td className="table-active" scope="col">修課學生</td><td>本校{courseDetail.CURRENRL}人</td></tr>
                      <tr><td className="table-active" scope="col">人數限制</td><td>{courseDetail.MINENRL}~{courseDetail.MAXENRL}</td></tr>
                      <tr>
                        <td className="table-active" scope="col">上課時間地點</td>
                        <td>
                          <table className="table table-bordered">
                            <thead>
                              <tr className="table-primary"><th>星期</th><th>開始 / 結束</th><th>教室 [<a href="https://academic.cgu.edu.tw/p/412-1009-8470.php" target="_blank">代碼說明</a>]</th><th></th></tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{courseDetail.time.CWEEKDAY}</td>
                                <td>{courseDetail.time.BEGINSEC} ({courseDetail.time.STARTTIME}) ~  {courseDetail.time.ENDSEC} ({courseDetail.time.ENDTIME})</td>
                                <td>{courseDetail.time.LOCATIONID}</td>
                                <td>{courseDetail.time.CATEGORYNAME}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr><td className="table-active" scope="col">核心能力</td><td><a href={"https://ep.cgu.edu.tw/Webfolio/checkCID.aspx?cid=" + courseDetail.CALL_ID} target="_blank">按此查詢</a></td></tr>
                      <tr><td className="table-active" scope="col">備註</td><td>{courseDetail.ANNOTATION}</td></tr>
                    </tbody>

                  </table>
                </div>
                <div class="tab-pane fade" id="profile-justified" role="tabpanel" aria-labelledby="detail_2">
                  <table class="table">
                    <tbody>
                      <tr><td className="table-active" scope="col">教科書</td><td>{courseDetail.TEXTBOOK}</td></tr>
                      <tr><td className="table-active" scope="col">教科書ISBN</td><td>{"0000000000"}</td></tr>
                      <tr><td className="table-active" scope="col">參考書</td><td>{courseDetail.REFBOOK}</td></tr>
                      <tr><td className="table-active" scope="col">課程講義網址</td><td>{courseDetail.LECTURE}</td></tr>
                      <tr><td className="table-active" scope="col">整體教學目標</td><td>{courseDetail.OBJECTIVE}({courseDetail.CREDITS})</td></tr>
                      <tr><td className="table-active" scope="col">教學方法</td><td>{courseDetail.PEDAGOGY}</td></tr>
                      <tr><td className="table-active" scope="col">課程簡介</td><td>{courseDetail.CHTINTRO}</td></tr>
                      <tr><td className="table-active" scope="col">彈性學習</td><td>{""}</td></tr>
                      <tr><td className="table-active" scope="col">成績考核</td><td>本校{courseDetail.SCOREMETHOD}人</td></tr>
                      <tr><td className="table-active" scope="col">師生互動時間</td><td>{courseDetail.OFFICEHOUR}</td></tr>

                    </tbody>
                  </table>
                </div>
                <div class="tab-pane fade" id="contact-justified" role="tabpanel" aria-labelledby="detail_3">
                  <table class="table">

                    {
                      courseDetail.subject.length > 0 ?
                        <thead>
                          <tr className="table-active"><th>項次</th><th>教學主題</th></tr>
                        </thead>
                        :
                        <tr><th className="text-center">無資料</th></tr>
                    }

                    <tbody>
                      {
                        courseDetail.subject.map((item, index) => (
                          <tr><td>{index + 1}</td><td>{item.SECTIONCONTENT}</td></tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
                <div class="tab-pane fade text-center" id="contact-justified2" role="tabpanel" aria-labelledby="detail_4">
                  本課程沒有先修課程規定
                </div>
              </div>
            </div>

          </Modal.Body>
          {/* <Modal.Footer>
        <input type="submit" name="submit" className="btn btn-primary" value="儲存"  />
        <Button variant="secondary" onClick={props.onHide}>取消</Button>
        
        </Modal.Footer> */}
        </form>
      </Modal>
    );
  }

  // function search(event) {
  //   event.preventDefault();
  //   getCourse(event.target.p_TERMID.value, event.target.p_DEPARTMENTID.value)
  // }

  function reset() {
    window.location.href = window.location.href
  }

  
  function search(event) {
    localStorage.removeItem('bookingIds')
    setChkList([])
    setCourse([])

    setisSearch(true)
    setSort({ order: 'asc', orderBy: 'CALL_ID' })
    event.preventDefault();
    let param = '?termid=' + event.target.termid.value
    param += '&departmentid=' + event.target.departmentid.value
    param += '&call_id=' + event.target.call_id.value.trim()
    param += '&keyward=' + event.target.keyward.value.trim()
    param += '&sectionid=' + event.target.sectionid.value.trim()
    param += '&teaName=' + event.target.teaName.value.trim()
    param += '&cName=' + event.target.cName.value.trim()
    param += '&year=' + event.target.year.value
    param += '&fieldid=' + event.target.fieldid.value
    param += '&week=' + event.target.week.value
    param += '&stime=' + event.target.stime.value
    param += '&etime=' + event.target.etime.value

    setsTermid(event.target.termid.value)
    
    if(localStorage.getItem('stid') != null)
      param += '&stid=' + localStorage.getItem('stid')

    if (event.target.lang.checked) param += '&lang=E'

    setCurrTid(event.target.termid.value)

    if (
      (event.target.departmentid.value == ''
      && event.target.call_id.value == ''
      && event.target.keyward.value == ''
      && event.target.sectionid.value == ''
      && event.target.teaName.value == ''
      && event.target.cName.value == ''
      && event.target.fieldid.value == ''
      && event.target.year.value == ''
      && event.target.lang.checked == false
      )
      &&
      (
        event.target.week.value == '' ||
        event.target.stime.value == '' ||
        event.target.etime.value == ''
      )
    ) {
      
      Swal.fire({
        title: t("請輸入查詢條件"),
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: t('確定')
      })
      return
    }

    if (parseInt(event.target.stime.value) > parseInt(event.target.etime.value)) {
      Swal.fire({
        title: '上課時間(結束時間)不可小於上課時間(開始時間)',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: '確定'
      })
      return
    }    

    const apiUrl = sysConfig.apiUrl_IS + 'Course/GetCourseSections' + param

    showLoading(true)
    axios.get(apiUrl).then((response) => {

      const res = response.data
      console.log('course', res)

      let arr = []
      res.forEach(item => {
        item.checked = false
        arr.push(item)
      })

      setCourse(arr)
      

      //

    })
      .catch((error) => {
        console.log(error)
      })
      .then(() => {
        showLoading(false)
        setAccVel1('collapsed')
        setAccVel2('')
      })
  }

  const bodyStyle = {
    height: '500px'
  }

  function handleOnChange(e) {
    let cc = e.target.checked
    let id = e.target.value
    let list = chkList
    let index = list.indexOf(id)
    if(index == -1) {
      list.push(id)
      setChkList(list)
      console.log('chklist', chkList)
      localStorage.setItem('bookingIds', list)
    } 
    else {
      list.splice(index,1)
      setChkList(list)
      console.log('chklist', chkList)
      localStorage.setItem('bookingIds', list)
    }
    
  }

  function getChkList() {
    return chkList
  }

  // ---- <Sort> ----
  const [sort, setSort] = useState({ order: 'asc', orderBy: 'CALL_ID' })
  const sortedRows = useMemo(() => sortRows(course, sort), [course, sort])

  const handleSort = (accessor) => {
    setSort((prevSort) => ({
      order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
      orderBy: accessor,
    }))

  }

  function formatSem(termid) {
    var res = termid
    sem.forEach(s => {
      if(s.TERMID == termid) {
        if(i18n.language == 'en')        
          res = s.ETERMNAME
        else
          res = s.CTERMNAME
      }
      
    });
    return res
  } 

  const columns = [       
    { accessor: 'TERMID', label: t("學年") + '/' + t("學期") },
    { accessor: 'CALL_ID', label: t("科目代號") },
    { accessor: 'SECTIONID', label: t("開課序號") },
    { accessor: 'DEPARTMENTID', label: t("開課單位") },
    { accessor: 'YEAR', label: t("年級") },
    { accessor: 'CCOURSENAME', label: t("課程名稱") },
    { accessor: 'NAME', label: t("授課教師") },
    { accessor: 'CREDITS', label: t("學分") },
    { accessor: 'NTIMEMASK', label: t("上課時間") },
    { accessor: 'CURRENRL', label: t("選課人數") },    
  ]

  function courseLan(lang) {
    if(lang == 'E')
    {
      return '(' + t('英語授課') + ')'
    } 
    else {
      return ''
    }
  }

  if(isBooking == true) 
    columns.splice(0,0,{ accessor: 'CALL_ID', label: t("選擇")  })
  if(isReg == true && isBooking == false && sTermid == rTermid)
    columns.push({ accessor: 'CALL_ID', label: t("加退選")  })

  // ---- </Sort> ----

  const mTableBody = sortedRows.map((item, index) =>
    <tr>
      {
        isBooking == true ?
          <td><input type="checkbox" name="chkCourse" defaultChecked={item.checked} value={item.SECTIONID} onChange={handleOnChange} /></td>
          :
          <></>
      }

      <td>{formatSem(item.TERMID)}</td>
      <td className="td-stext">{item.CALL_ID}</td>
      <td>{item.SECTIONID}</td>
      <td className="">
        {i18n.language == 'en' ? 
        (
          <a className="td-slink" href={"/course?d=" + btoa(item.DEPARTMENTID)}>{item.DEPARTMENTNAME_E}</a>
        )          
          : 
          <a className="td-slink" href={"/course?d=" + btoa(item.DEPARTMENTID)}>{item.DEPARTMENTNAME_C}</a>
        }
      </td>
      <td>{item.YEAR}</td>
      <td>


        <a className="td-slink" href="javascript:void(0)" onClick={() => openCourseDetail({ sectionid: item.SECTIONID })}>
          {
            i18n.language == 'en' ? 
              item.ECOURSENAME + courseLan(item.LANGUAGE)
            : 
              item.CCOURSENAME + courseLan(item.LANGUAGE)
          }
        </a>
      </td>
      <td>
        <a className="td-slink" href={"/course?t=" + btoa(encodeURIComponent(item.NAME))}>{item.NAME}</a>
      </td>
      <td>
        {
          item.CLASSIFICATIONCATNAME.indexOf('必修') > -1 ?
            <span className="badge bg-danger">{item.CREDITS}</span>
            :
            <span className="badge bg-info">{item.CREDITS}</span>
        }

      </td>
      <td>
        {
          item.C_PTIME != null ?
            item.C_PTIME.split('\n').map((line, i) => (
              <div className="" key={i}>
                {line}
              </div>
            ))
            :
            <>{t("未指定")}</>
        }
      </td>
      <td>{item.CURRENRL}/{item.MAXENRL}</td>
      {
          isReg && isBooking == false && sTermid == rTermid  ?
            //true ?
            <td>
              {
                isBooking == false && checkStuCourse(item.SECTIONID) == false && item.CURRENRL < item.MAXENRL ?
                  <a href="javascript:void(0);" onClick={() => addCourse(item)}>
                    <span className="badge bg-success"><i className="bi bi-plus-circle"></i> {t("加選")}</span>
                  </a>
                  :
                  <></>
              }
              {
                isBooking == false && checkStuCourse(item.SECTIONID) == true ?
                  <>
                    <a href="javascript:void(0);" onClick={() => delCourse(item)}>
                      <span className="badge bg-danger"><i className="bi bi-dash-circle"></i> {t("退選")}</span>
                    </a>
                  </>
                  :
                  <></>
              }
            </td>
            :
            <></>
        }

      {/* <td>
        <a href="javascript:void(0)" onClick={() => openCourseDetail({sectionid: item.SECTIONID})}>

          {
            item.CLASSIFICATIONCATNAME.indexOf('必修') > -1 ?
              <span className="badge bg-primary">{t("必修")}</span>
              :
              <span className="badge bg-info">{t("選修")}</span>
          } &nbsp;

         
          <span className="">[{item.YEAR}]</span>
          {
            i18n.language == 'en' ? item.ECOURSENAME : item.CCOURSENAME
          }
          &nbsp;
          <span className="badge bg-secondary">{item.NAME}</span>&nbsp;
        </a>
        {
          isReg ?
            //true ?
            <>
              {
                isBooking == false && checkStuCourse(item.SECTIONID) == false ?
                  <a href="javascript:void(0);" onClick={() => addCourse(item)}>
                    <span className="badge bg-success"><i className="bi bi-plus-circle"></i> {t("加選")}</span>
                  </a>
                  :
                  <></>
              }
              {
                isBooking == false && checkStuCourse(item.SECTIONID) == true ?
                  <>
                    <a href="javascript:void(0);" onClick={() => delCourse(item)}>
                      <span className="badge bg-danger"><i className="bi bi-dash-circle"></i> {t("退選")}</span>
                    </a>
                  </>
                  :
                  <></>
              }
            </>
            :
            <></>
        }


      </td> */}
      {/* <td>{item.NAME}</td> */}

    </tr>
  )

  let tdColName = ""

  const mTable = (
    <table className="table m-table" id="tb1">
      <thead>
      <tr>
        {/* { isBooking ? <th>{t("選擇")}</th> : ""} */}
        {columns.map((column, index) => {          
          const sortIcon = () => {
            if (column.accessor === sort.orderBy) {
              if (sort.order === 'asc') {
                return <i class="bi bi-sort-up"></i>
              }
              return <i class="bi bi-sort-down"></i>
            } else {
              return '️'
            }
          }

          tdColName += '.m-table td:nth-of-type(' + (index + 1) + '):before {content : "' + column.label + '"}'

          return (
            <>
              <style>{tdColName}</style>
              <th style={{ cursor: 'pointer' }} key={column.accessor} onClick={() => handleSort(column.accessor)}>
                <span>{column.label} {sortIcon()}</span>
                {/* <button onClick={() => handleSort(column.accessor)}>{sortIcon()}</button> */}
              </th>
            </>

          )
        })}
     
        </tr>
        {/* <tr>
          {
            isBooking == true ?
              <th nowrap="nowrap">選擇</th>
              :
              <></>
          }         
          <th nowrap="nowrap">{t("科目代號")}</th>
          <th nowrap="nowrap">{t("開課序號")}</th>
          <th nowrap="nowrap">{t("課程名稱")}</th>      
          <th nowrap="nowrap">{t("學分")}</th>
          <th nowrap="nowrap">{t("上課時間")}</th>
          <th nowrap="nowrap">{t("選課人數")}</th>
        </tr> */}
      </thead>
      <tbody>
        {
          sortedRows.length > 0 ?
            (
              <>{mTableBody}</>
            )
            :
            <tr><td align="center" colSpan={10}>無資料</td></tr>
        }
        {/* {sortedRows.map((row) => {
            return (
              <tr key={row.SECTIONID}>
                {columns.map((column) => {
                  if (column.format) {
                    return <td key={column.accessor}>{column.format(row[column.accessor])}</td>
                  }
                  return <td key={column.accessor}>{row[column.accessor]}</td>
                })}
              </tr>
            )
          })} */}
      </tbody>
    </table>
  )

  const Main = (
    <>
      <main>
        <div className="container">
          <section className="section  min-vh-100 d-flex flex-column   py-4">


            <div className="container">

              <div className="row">
              <div class="col">              
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">{t("課程查詢")}
                      {/* | <a href="/depcourse" target="_blank">{t("系所必選修科目")}</a> */}
                    </h5>
                    <form class="row g-3" onSubmit={search}>
                      <div class="col-6 col-md-3">
                        <div class="form-floating">
                          {/* <input type="text" class="form-control" name="p_TERMID" placeholder="開課學期"/> */}
                          <select class="form-select" name="termid" aria-label="State" disabled={isBooking}>
                            {
                              sem.map(item => (
                                <option value={item.TERMID}>{i18n.language == 'en' ? item.ETERMNAME : item.CTERMNAME}</option>
                              ))
                            }
                          </select>
                          <label for="">{t("開課學期")}</label>
                        </div>
                      </div>
                      <div class="col-6 col-md-3">
                        <div class="form-floating">
                          {/* <input type="text" class="form-control" name="p_DEPARTMENTID" placeholder="開課單位"/> */}
                          <select class="form-select" value={def_dep} onChange={(e) => setdef_dep(e.target.value)} name="departmentid" aria-label="State">
                            <option value="">- {t("請選擇")} -</option>
                            {
                              dep.map(item => (
                                <option value={item.DEPARTMENTID}>
                                  {i18n.language == 'en' ?
                                    item.ENGDEPALIAS != null ? item.ENGDEPALIAS : item.CHTDEPALIAS
                                    :
                                    item.CHTDEPALIAS}
                                </option>
                              ))
                            }
                          </select>
                          <label for="">{t("開課單位")}</label>
                        </div>
                      </div>
                      <div class="col-6 col-md-3">
                        <div class="form-floating">
                          <input type="text" 
                            minLength={2} maxLength={20} 
                            pattern="[\u4E00-\u9FFFa-zA-Z0-9]{2,20}" 
                            title={t('請輸入2~20個字的中英文')}
                            class="form-control" name="keyward" placeholder={t("關鍵字")} />
                          <label for="floatingPassword">{t("關鍵字")}</label>
                        </div>
                      </div>
                      <div class="col-6 col-md-3">
                        <div class="form-floating">
                            <input type="text" 
                            minLength={1} maxLength={8}
                            pattern="[0-9]{1,8}"     
                            title={t("請輸入數字")}
                            class="form-control" name="sectionid" placeholder="x" />
                            <label for="floatingEmail">{t("開課序號")}</label>                        
                        </div>
                      </div>


                      <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="flush-headingOne">
                            <button className={'accordion-button ' + accVal1} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                              {t("更多條件")}
                            </button>
                          </h2>
                          <div id="flush-collapseOne" className={'accordion-collapse collapse ' + accVal2} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body col-md-12">
                              <div className="row">
                                <div class="col-6 col-md-2">
                                  <div class="form-floating">
                                  <input type="text" 
                                  minLength={1} maxLength={8}
                                  pattern="[a-zA-Z0-9]{1,8}"    
                                  title={t("請輸入英文或數字")}                                          
                                  class="form-control" name="call_id" placeholder={t("科目代號")} />
                                  <label for="floatingPassword">{t("科目代號")}</label>
                                  </div>
                                </div>
                                <div class="col-6 col-md-2">
                                  <div class="form-floating">
                                    <input defaultValue={def_tea} 
                                    minLength={2} maxLength={10} 
                                    pattern="[\u4E00-\u9FFFa-zA-Z0-9]{2,10}" 
                                    title={t('請輸入2~10個字的中英文')}
                                    type="text" class="form-control" name="teaName" placeholder="x" />
                                    <label for="floatingPassword">{t("授課教師")}</label>
                                  </div>
                                </div>
                                <div class="col-md-2">
                                  <div class="form-floating">
                                    <input type="text" 
                                    minLength={2} maxLength={20} 
                                    pattern="[\u4E00-\u9FFFa-zA-Z0-9]{2,20}" 
                                    title={t('請輸入2~20個字的中英文')}
                                    class="form-control" name="cName" placeholder="x" />
                                    <label for="floatingPassword">{t("課程名稱")}</label>
                                  </div>
                                </div>
                                <div class="col-6 col-md-2">
                                  <div class="form-floating">
                                    <input type="text" 
                                    minLength={1} maxLength={1}
                                    pattern="[0-9]{1,1}"     
                                    title={t("請輸入數字")}
                                    class="form-control" name="year" placeholder="x" />
                                    <label for="floatingPassword">{t("年級")}</label>
                                  </div>
                                </div>

                                <div class="col-6 col-md-4">
                                  <div class="form-floating mb-3">
                                    <select class="form-select" name="week" id="floatingSelect" aria-label="State">
                                      <option value="">- {t("請選擇")} -</option>
                                      <option value="2">{t("一")}</option>
                                      <option value="3">{t("二")}</option>
                                      <option value="4">{t("三")}</option>
                                      <option value="5">{t("四")}</option>
                                      <option value="6">{t("五")}</option>
                                      <option value="7">{t("六")}</option>
                                      <option value="1">{t("日")}</option>
                                    </select>
                                    <label for="floatingSelect">{t("上課時間")}({t("星期")})</label>
                                  </div>
                                </div>
                                <div class="col-6 col-md-3">
                                  <div class="form-floating mb-3">
                                    <select class="form-select" name="stime">
                                      <option value="">- {t("請選擇")} -</option>
                                      <option value="1">&nbsp;&nbsp;1 (08:10)</option>
                                      <option value="2">&nbsp;&nbsp;2 (09:10)</option>
                                      <option value="3">&nbsp;&nbsp;3 (10:10)</option>
                                      <option value="4">&nbsp;&nbsp;4 (11:10)</option>
                                      <option value="5">&nbsp;&nbsp;5 (13:10)</option>
                                      <option value="6">&nbsp;&nbsp;6 (14:10)</option>
                                      <option value="7">&nbsp;&nbsp;7 (15:10)</option>
                                      <option value="8">&nbsp;&nbsp;8 (16:10)</option>
                                      <option value="9">&nbsp;&nbsp;9 (17:10)</option>
                                      <option value="10">10 (18:10)</option>
                                      <option value="11">11 (19:10)</option>
                                      <option value="12">12 (20:10)</option>
                                      <option value="13">13 (21:10)</option>
                                    </select>
                                    <label for="floatingSelect">{t("上課時間")}({t("開始時間")})</label>
                                  </div>
                                </div>
                                <div class="col-6 col-md-3">
                                  <div class="form-floating mb-3">
                                    <select class="form-select" name="etime" aria-label="State">
                                      <option value="">- {t("請選擇")} -</option>
                                      <option value="1">&nbsp;&nbsp;1 (09:00)</option>
                                      <option value="2">&nbsp;&nbsp;2 (10:00)</option>
                                      <option value="3">&nbsp;&nbsp;3 (11:00)</option>
                                      <option value="4">&nbsp;&nbsp;4 (12:00)</option>
                                      <option value="5">&nbsp;&nbsp;5 (14:00)</option>
                                      <option value="6">&nbsp;&nbsp;6 (15:00)</option>
                                      <option value="7">&nbsp;&nbsp;7 (16:00)</option>
                                      <option value="8">&nbsp;&nbsp;8 (17:00)</option>
                                      <option value="9">&nbsp;&nbsp;9 (18:00)</option>
                                      <option value="10">10 (19:00)</option>
                                      <option value="11">11 (20:00)</option>
                                      <option value="12">12 (21:00)</option>
                                      <option value="13">13 (22:00)</option>

                                    </select>
                                    <label for="floatingSelect">{t("上課時間")}({t("結束時間")})</label>
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="form-floating">
                                    <select name="fieldid" class="form-select" >
                                      <option value="">- {t("請選擇")} -</option>
                                      {
                                        genlist.length > 0?
                                        genlist.map(m => (
                                          <option value={m.FIELDID}>{m.FIELDNAME}</option>
                                        ))
                                        :
                                        <></>
                                      }
                                      {/* <option value="">- {t("請選擇")} -</option>
                                      <option value="SM">{t("軍訓課程")}</option>
                                      <option value="SP">{t("體育課程")}</option> */}
                                    </select>
                                    <label for="floatingPassword">{t("通識領域")}</label>
                                  </div>
                                </div>
                                <div class="col-6 col-md-2 d-flex align-items-center">
                                  <div class="form-check mt-3">

                                    <label class="form-check-label text-nowrap" >
                                      <input class="form-check-input" type="checkbox" value="E" name="lang" />
                                      {t("英語授課")}
                                    </label>
                                  </div>
                                </div>

                              </div>


                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div class="col-6 col-md-10">
                        </div>
                        <div class="col-6 col-md-2 d-grid gap-2">
                          <button id="search" className="btn btn-primary" type="submit"><i className="bi bi-search"></i> {t("搜尋")}</button>
                        </div>
                      </div>

                      {/* <div className="row">
                      <div className="col-lg-4">
                        <div class="row mb-3">
                          <label for="inputText" class="col-sm-4 col-form-label">開課學期</label>
                          <div class="col-sm-8">
                            <input type="text" name="p_TERMID" class="form-control"/>
                          </div>
                        </div>                     
                      </div>
                      <div className="col-lg-8">                     
                        <div class="row mb-2">
                          <label for="inputText" class="col-sm-4 col-form-label">開課單位</label>
                          <div class="col-sm-10">
                            <input type="text" name="p_DEPARTMENTID" class="form-control"/>
                          </div>
                        </div>                      
                      </div>
                    </div> */}



                      <div className="row">
                        <div className="text-right">

                          {/* <input type="submit" name="submit" className="btn btn-primary " value="搜尋"  /> */}

                          {/* <button className="btn btn-secondary m-1" onClick={() => reset()}><i className="bi bi-x"></i> {t("清除")}</button> */}
                        </div>
                      </div>

                    </form>


                  </div>
                </div>
              </div>
              
              {/* <div className="card col-md-4 m-app-info">
                <div className="card-body">                  
                  <div className="row  mt-4">
                    <div className="col-8">     
                      {t("長庚大學 APP 提供課程查詢功能")}<br/>
                      {t("請參考")} <span className="td-stext">
                        <a href="https://mycgu.blogspot.com/2023/04/app_12.html" target="_blank">[{t("APP 課程查詢操作說明")}]</a>
                        </span>
                    </div>
                    <div  className="col-4">
                      <img className="c-search" src={searchIcon} />
                    </div>
                  </div>

                </div>
              </div> */}

              </div>

              {
                course.length > 0 ?
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="card-title">
                            {t("課程列表")}
                            <div className="d-inline text-tip float-end" >{t("圖示")}: <span className="badge bg-danger">&nbsp;</span> {t("必修")}  <span className="badge bg-info">&nbsp;</span> {t("選修")}</div>
                          </div>
                          {mTable}
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <><center>{isSearch ? t('沒有符合查詢規則的課程, 請修改後重新查詢') : ''}</center></>
              }

            </div>
          </section>
        </div>
      </main>
    </>
  )

  return (
    <>
      <LayoutSc>
        {Main}

        <DetailModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        <CourseDetail
          cobj={d_cobj}
          show={modalShowCourseDetail}
          onHide={() => setModalShowCourseDetail(false)}
        />
      </LayoutSc>
    </>

  )
}

export default Course