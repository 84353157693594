
import { useState, useEffect } from 'react'
import { useNavigate, useLocation, useRoutes } from 'react-router-dom'

const LogoutRe = () => {

  localStorage.clear()

  useEffect(() => {

  }, [])

  return (
    <div>
      LogoutRe
    </div>

  );

}

export default LogoutRe;