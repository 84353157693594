import React from "react"
import Layout from "../common/Layout"
import { useState, useEffect, useContext } from 'react'
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AppContext } from '../App';
import Swal from 'sweetalert2'
import { useTranslation } from "react-i18next";

const Page = () => {
  const { t, i18n } = useTranslation();
  const { sysConfig } = useContext(AppContext) 
  const [loading, openLoading] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [isLoading, setLoading] = useState(true);
  const [functions, setFuntions] = useState();
  let isOpen = false

  const [fId, setfId] = useState()
  const [fName, setfName] = useState()
  const [fEName, setfEName] = useState()
  const [fDesc, setfDesc] = useState()
  const [fMode, setfMode] = useState()

  const [fData, setfData] = useState({
    function_id: "",
    name: "",
    description: ""
  });

  function getdata() {
    openLoading(true)
    const apiUrl = sysConfig.apiUrl + "Page"
    
    axios.get(apiUrl).then(response => {
      setTimeout(() => {
        setFuntions(response.data);
        console.log(response.data)
        setLoading(false);
        openLoading(false)
      }, 1);
      
    });
  }

  function test() {
    setfName('ff')
  }

  function updcontent(id) {
    window.location.replace('./pagecontent?id='+id)
  }

  function upsert(index) {       
    setModalShow(true)
    if(index > -1) {
      const obj = functions[index]
      //const index = functions.findIndex(a => a.function_id == id)
      setfId(obj.id)
      setfName(obj.name)      
      setfEName(obj.eName)   
      setfMode(obj.mode)
    } 
    else {
      setfId('')
      setfName('')
      setfEName('')
      setfMode('def') 
    }
  }

  function save(event) {
    event.preventDefault();
    console.log(event.target.iName.value)

    if(event.target.iName.value === '') {
      Swal.fire({
        title: '請輸入名稱',     
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: '確定'
      })
      return
    }
    
    let sUrl = sysConfig.apiUrl + 'Page'
    let sMethod = 'post'

    let sData = {    
      name: event.target.iName.value,
      ename: event.target.iEName.value,
      mode: event.target.iMode.value
    }    

    if(fId != '') {
      //sUrl += '/' + fId
      sData.id = fId
      sMethod = 'put'
    }

    axios({
      method: sMethod,
      url: sUrl,
      data: sData
    })
    .then((response) => {
        console.log(response.data);
        setModalShow(false)
        getdata()
    })
    .catch((error) => {
        console.log(error)
    })

  }

  function del(index) {

    Swal.fire({
      title: '確定刪除這筆資料',
      //text: "確定刪除這筆資料?",
      icon: 'warning',
      showCancelButton: true,    
      confirmButtonText: '確定',
      cancelButtonText: '取消'
    }).then((result) => {
      if (result.isConfirmed) {
        const obj = functions[index]
        let sUrl = sysConfig.apiUrl + 'Page/' + obj.id
        let sMethod = 'delete'   
        
        const ndata = functions.filter(item => item.id !== obj.id)
        setFuntions(ndata)

        axios({
          method: sMethod,
          url: sUrl
        })
        .then((response) => {
            console.log(response.data);   
            if(response.data.success) {
              Swal.fire({
                title: '刪除成功',     
                icon: 'success',
                showCancelButton: false,
                timer: 2000,
                confirmButtonText: '確定'
              })
              
            } else {
              const rdata = response.data.data
              let rmsg = ''
              for(var i in rdata) {
                rmsg += rdata[i].name + "[" + t("position_"+rdata[i].position) + "] "
              }
              Swal.fire({
                title: response.data.msg,    
                text: rmsg, 
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: '確定'
              })
            }
            getdata()
        })
        .catch((error) => {
            console.log(error)
        })
      }
    })

    //if(window.confirm('確定刪除?') === false) return
    
    
  }

  function UpsertModal(props) {
    //setOpen(props.show)
    isOpen = props.show
    console.log(isOpen)
    return (
      <Modal
        {...props}
        size="lg"
        backdrop="static"
        keyboard="false"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form  onSubmit={save}>      
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            系統頁面
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <div className="row g-3">
            <div className="col-md-4">
              <label   className="form-label">名稱</label>
              <input type="text" defaultValue={fName} className="form-control" name="iName"/>
            </div>
            <div className="col-md-8">
                <label className="form-label">英文名稱</label>
                <input type="text" defaultValue={fEName} className="form-control" name="iEName" />
            </div>
            <div className="col-md-12">
              <label   className="form-label">模式</label>
              {/* <input type="text" defaultValue={fDesc} className="form-control" name="iDesc"/> */}
              <select id="iMode"  name="iMode" className="form-select" defaultValue={fMode}  >
                <option value="def">{t("page_def")}</option>
                <option value="block">{t("page_block")}</option>
              </select>
            </div>               
          </div>
          
        </Modal.Body>
        <Modal.Footer>
        <input type="submit" name="submit" className="btn btn-primary" value="儲存"  />
        <Button variant="secondary" onClick={props.onHide}>取消</Button>
        
        </Modal.Footer>
        </form>
      </Modal>
    );
  }

  

  useEffect(() => {       
    getdata()
  }, [sysConfig]);

  if (isLoading) {
    return (
      <Layout>
        <main id="main" className="main">Loading...</main>
      </Layout>
    )
  }

  function upd() {
    alert('upd')
  }

  const mTableBody = functions.map(
    (f,index) => (
      <tr key={f.id}>
        <td>{f.name}</td>
        <td>{t("page_"+f.mode)}</td>
        <td>
          <button className="btn btn-info" onClick={() => updcontent(f.id)}><i  className="bi bi-card-list"></i></button>&nbsp;         
        </td> 
        <td>
          {
            f.id != '00db220e-ffdf-4a7e-8a42-416adfa55db9'?
            <>
              <button className="btn btn-warning" onClick={() => upsert(index)}><i  className="bi bi-pencil-square"></i></button>&nbsp;
              <button className="btn btn-danger" onClick={() => del(index)}><i  className="bi bi-trash"></i></button>
            </>
            :
            <></>
          }
          
        </td>  
           
      </tr>
    )
  )
  const mTable = (
    <table className="table">
      <thead>
        <tr>
          <th>名稱</th>
          <th>模式</th>
          <th>頁面內容</th>      
          <th>編輯</th>                 
        </tr>
      </thead>
      <tbody>
        {mTableBody}
      </tbody>
    </table>
  )
  const mBody = (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>頁面管理</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/">首頁</a></li>
              <li className="breadcrumb-item active">頁面管理</li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  {/* <h5 className="card-title">系統功能</h5> */}
                  <div className="row">
                    <div className="col-lg-6">
                      <h5 className="card-title">頁面列表</h5>
                    </div>
                    <div className="col-lg-6">
                      <h5 className="card-title">                   
                        <button className="btn btn-secondary float-end" onClick={() => upsert(-1)}><i  className="bi bi-plus-circle"></i> 新增</button>
                      </h5>                      
                    </div>
                  </div>
                  {mTable}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )


  return (
    <Layout>
       
       {mBody}
       <UpsertModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
       
    </Layout>
    
  );

  //const mTableBody = users.map(users => <tr key={users.id}><td>{users.id}</td><td>{users.name}</td></tr>)
       

    const Main = (
        <>
          <main id="main" className="main">
            <div className="pagetitle">
              <h1>Dashboard</h1>
              <table>
                {}
              </table>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                  <li className="breadcrumb-item active">Dashboard</li>
                </ol>
              </nav>
            </div>
            <section className="section dashboard">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Default Table</h5>
                     
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )
    
    return (
        <>
        <Layout>
        {Main}
        </Layout>        
        </>
        
    )
}

export default Page