import React from "react"
import Layout from "../common/Layout"
import { useState, useEffect, useContext } from 'react'
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AppContext } from '../App';
import Swal from 'sweetalert2'
import ReSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { useTranslation } from "react-i18next";

const User = () => {
  const { t, i18n } = useTranslation();
  const pageName = '使用者管理'
  const { sysConfig } = useContext(AppContext)

  const [modalShow, setModalShow] = React.useState(false);
  const [isLoading, setLoading] = useState(true);
  const [mData, setmData] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  let isOpen = false

  const [fId, setfId] = useState()
  const [fName, setfName] = useState()
  const [fDesc, setfDesc] = useState()
  const [fGroup, setfGroup] = useState()
  const [cGroup, setcGroup] = useState()
  const [defTag, setDefTag] = useState()
  const [fMenu, setfMenu] = useState()
  const [fRole, setfRole] = useState()

  const [defRole, setDefRole] = useState()
  const [qId, setqid] = useState('')
  const [qName, setqname] = useState('')
  const [qStatus, setqstatus] = useState('')
 

  const initObj = {
    link_id: "",
    link_group_id: "",
    name: "",
    description: "",
    url: "",
    url_target: 0,
    is_system_default: true
  }
  const [fData, setfData] = useState(initObj);

  function getdata() {
    const apiUrl = sysConfig.apiUrl + "User"

    axios.get(apiUrl).then(response => {
      setTimeout(() => {
        setmData(response.data);
        console.log(response.data)
        setLoading(false);

      }, 1);

    });
  }

  function getRole() {
    const apiUrl = sysConfig.apiUrl + "Role"

    axios.get(apiUrl).then(response => {

      console.log('role', response.data)
      setfRole(response.data)
      setLoading(false);
    });
  }


  function upsert(index) {
    setDefRole([])   
    
    setModalShow(true)
    if (index > -1) {
      const obj = mData[index]
      //const index = mData.findIndex(a => a.function_id == id)
      // setfId(obj.function_id)
      // setfName(obj.name)
      // setfDesc(obj.description)
      setfData(obj)

      let tArr = []

      for (var i in obj.roleList) {
        var role = obj.roleList[i]
        tArr.push({ value: role.id, label: role.name })
      }
      setDefRole(tArr);
     
    }
    else {
      setfData(initObj)
    }
  }

  function save(event) {
    event.preventDefault();
    console.log(event.target.iName.value)

    let chk = [];

    // role
    for (var j = 0; j < event.target.iRole.length; j++) {
      chk.push(event.target.iRole[j].value)
    }
    if (typeof (event.target.iRole.defaultValue) != 'undefined') {
      if (event.target.iRole.defaultValue != '')
        chk.push(event.target.iRole.defaultValue)
    }

    console.log('cgroup chk', chk)

    if (event.target.iName.value === '') {
      Swal.fire({
        title: '請輸入名稱',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: '確定'
      }
      )
      return
    }

    let uStatus = '2'
    if(event.target.iStatus.checked) {
      uStatus = '3'
    }

    let sUrl = sysConfig.apiUrl + 'User'
    let sMethod = 'post'

    let sData = {     
      name: event.target.iName.value,     
      status: uStatus,
      roleids: chk
    }


    if (fData.id != '') {
      sData.id =  fData.id
      sMethod = 'put'
    }

    axios({
      method: sMethod,
      url: sUrl,
      data: sData
    })
      .then((response) => {

        console.log(response.data);

        setModalShow(false)
        window.document.getElementById("search").click()

      })
      .catch((error) => {
        console.log(error)
      })

  }

  function del(index) {

    Swal.fire({
      title: '確定刪除這筆資料',
      //text: "確定刪除這筆資料?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '確定',
      cancelButtonText: '取消'
    }).then((result) => {
      if (result.isConfirmed) {
        const obj = mData[index]
        let sUrl = sysConfig.apiUrl + 'User/' + obj.id
        let sMethod = 'delete'

        const ndata = mData.filter(item => item.id !== obj.id)
        setmData(ndata)

        axios({
          method: sMethod,
          url: sUrl
        })
          .then((response) => {
            if (response.status == 200) {
              Swal.fire({
                title: '刪除成功',
                icon: 'success',
                showCancelButton: false,
                timer: 2000,
                confirmButtonText: '確定'
              })

            } else {
              const rdata = response.data.data
       
              Swal.fire({
                title: '刪除失敗',               
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: '確定'
              })
            }
            window.document.getElementById("search").click()
          })
          .catch((error) => {
            console.log(error)
          })
      }
    })

    //if(window.confirm('確定刪除?') === false) return


  }

  const handleChange = e => {
    //setcGroup(Array.isArray(e) ? e.map(x => x.value) : []);
    console.log('cgroup', e)
    //setcGroup(e)
  }

  const changeFileHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  function UpsertModal(props) {
    //setOpen(props.show)
    isOpen = props.show
    console.log(isOpen)

    let roles = []  

    for (var i in fRole) {
      
      var mobj = fRole[i]
      roles.push({ value: mobj.id, label: mobj.name })

    }

    return (
      <Modal
        {...props}
        size="lg"
        backdrop="static"
        keyboard="false"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form onSubmit={save}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {pageName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="row g-3">
              <div className="col-md-6">
                <label className="form-label">登人帳號</label>
                <input disabled type="text" defaultValue={fData.loginId} className="form-control" name="iId" />
              </div>
              <div className="col-md-6">
                <label className="form-label">名稱</label>
                <input type="text" defaultValue={fData.username} className="form-control" name="iName" />
              </div>              
             
              <div className="col-md-6">
                <label className="form-label">角色</label>
                <ReSelect
                  defaultValue={defRole}
                  isMulti
                  name="iRole"
                  options={roles}
                  noOptionsMessage={() => "無項目"}
                  placeholder={"請選擇項目"}                 
                  className="basic-multi-select"
                  onChange={handleChange}
                  classNamePrefix="select"
                />
              </div>

              <div className="col-md-6">
                    <div class="form-check mt-3">
                    <label class="form-check-label text-nowrap" >
                      <input class="form-check-input" type="checkbox" defaultChecked={fData.status == '3' ? 'checked' : ''} value="3" name="iStatus" />
                      {t("不同步AD角色")}
                    </label>
                    </div>
              </div>

             
            </div>

          </Modal.Body>
          <Modal.Footer>
            <input type="submit" name="submit" className="btn btn-primary" value="儲存" />
            <Button variant="secondary" onClick={props.onHide}>取消</Button>

          </Modal.Footer>
        </form>
      </Modal>
    );
  }



  useEffect(() => {
    //getdata()
    getRole()

  }, [sysConfig]);

  if (isLoading) {
    return (
      <Layout>
        <main id="main" className="main">Loading...</main>
      </Layout>
    )
  }

  function getRoleName(list) {
    let rname = ''
    list.forEach(e => {
      rname += e.name + ','
    })
    if(rname != '') rname = rname.substring(0, rname.length-1)
    return rname
  }

  function handleStatus() {
    if(window.document.getElementsByName("status")[0].checked) {
      window.document.getElementsByName("id")[0].value = ''
      window.document.getElementsByName("name")[0].value = ''
    }
  }

  function search(event) {
    event.preventDefault();
    if(event.target.id.value == '' && event.target.name.value == '' && event.target.status.checked == false) {
      Swal.fire({
        title: t("請輸入查詢條件"),
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: t('確定')
      })
      return;
    }
     
    let id = event.target.id.value
    if(id.indexOf('@') == -1 && id != '')  {
      id = id + '@cgu.edu.tw'
    }

   
    let param = '?loginId=' + id
    param += '&userName=' + event.target.name.value  
    if(event.target.status.checked) {
      param += '&status=3'
    }

    setqid(id)
    setqname(event.target.name.value)
    if(event.target.status.checked) { 
      setqstatus('checked')
    } else {
      setqstatus('')
    }


    const apiUrl = sysConfig.apiUrl + "User" + param

    setLoading(true)
    axios.get(apiUrl).then(response => {
      setTimeout(() => {
        setmData(response.data);
        console.log(response.data)
        setLoading(false);

      }, 1);

    });

  }


  const mTableBody = mData.map(
    (f, index) => (
      <tr key={f.id}>
        <td>
          {f.loginId}
        </td>
        <td>
          {f.username}
        </td>
        <td>{getRoleName(f.roleList)}</td>
        <td>{f.status == '3' ? 'v' : ''}</td>
        <td>
          <button className="btn btn-warning" onClick={() => upsert(index)}><i className="bi bi-pencil-square"></i></button>&nbsp;          
          {
            f.loginId != 'admin' ?
            <button className="btn btn-danger" onClick={() => del(index)}><i className="bi bi-trash"></i></button>
            :
            <></>
          }
          
        </td>
      </tr>
    )
  )
  const mTable = (
    <table className="table">
      <thead>
        <tr>
          <th>登入帳號</th>
          <th>姓名</th>
          <th>角色</th>
          <th>不同步AD角色</th>
          {/* <th>分類</th> */}
          <th>編輯</th>
        </tr>
      </thead>
      <tbody>
        {mTableBody}
      </tbody>
    </table>
  )
  const mBody = (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{pageName}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/">首頁</a></li>
              <li className="breadcrumb-item active">{pageName}</li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  {/* <h5 className="card-title">系統功能</h5> */}                  
                  <div className="row">
                    <div className="col-lg-6">
                      <h5 className="card-title">{pageName}列表</h5>
                    </div>
                    <div className="col-lg-6">
                      {/* <h5 className="card-title">
                        <button className="btn btn-secondary float-end" onClick={() => upsert(-1)}><i className="bi bi-plus-circle"></i> 新增</button>
                      </h5> */}
                    </div>
                  </div>

                  <form class="row g-3"  onSubmit={search}>
                    <div class="col-12 col-md-4">
                        <div class="form-floating">
                          <input type="text" 
                            minLength={5} maxLength={30}       
                            defaultValue={qId}                      
                            title={t('請輸入5~30個字的英文或數字')}
                            class="form-control" name="id" placeholder={t("請輸入登入帳號")} />
                          <label for="floatingPassword">{t("請輸入登入帳號")}</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-4">
                        <div class="form-floating">
                          <input type="text" 
                            minLength={1} maxLength={10} 
                            defaultValue={qName}
                            pattern="[\u4E00-\u9FFFa-zA-Z0-9]{1,10}" 
                            title={t('請輸入1~10個字的中英文')}
                            class="form-control" name="name" placeholder={t("請輸入姓名")} />
                          <label for="floatingPassword">{t("請輸入姓名")}</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-4">
                        <div class="form-check mt-3">
                          <label class="form-check-label text-nowrap" >
                            <input class="form-check-input" onChange={handleStatus}  defaultChecked={qStatus} type="checkbox" value="3" name="status" />
                            {t("不同步AD角色")}
                          </label>
                        </div>
                      </div>

                      <div className="row m-2">
                        <div class="col-6 col-md-10">
                        </div>
                        <div class="col-6 col-md-2 d-grid gap-2">
                          <button id="search" className="btn btn-primary" type="submit"><i className="bi bi-search"></i> {t("搜尋")}</button>
                        </div>
                      </div>

                  </form>

                  

                  {
                  mData.length > 0 ?
                  <>{mTable}</>
                  :
                  <></>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )


  return (
    <Layout>
      {mBody}
      <UpsertModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Layout>

  );

}
export default User