import React from "react"


const SSO = () => {

   function login() {
    window.location.replace('/sso.html');
   }
    
    return (
        <>
        <div><button onClick={login}>SSO</button></div>
       
        </>
        
    )
}

export default SSO