import React from "react"
import Layout from "../../common/Layout"
import { useContext, useState } from "react";
import { AppContext } from '../../App';
import axios from "axios";
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
import Moment from 'moment';
import parse from 'html-react-parser';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import CourseDetail from './CourseDetail'
import LayoutSc from './LayoutSc'
import {checkLang} from "../../common/Util";
const StuCourse = () => {
  const { t, i18n } = useTranslation();
  const { sysConfig } = useContext(AppContext)
  const [course, setCourse] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [sem, setSem] = useState([]);
  const [dep, setDep] = useState([]);
  const [courseDetail, setCourseDetail] = useState({ time: [], subject: [] });
  const [loading, openLoading] = useState(false);
  const [isReg, setIsReg] = useState(false)
  const [courseLog, setcourseLog] = useState([])
  const [terms, setterms] = useState([])
  const [tid, settid] = useState('')
  const [calendar, setcalendar] = useState([])
  const [d_cobj, setd_cobj] = React.useState(null);
  const [modalShowCourseDetail, setModalShowCourseDetail] = React.useState(false);


  const queryParams = new URLSearchParams(window.location.search)
  //const skey = queryParams.get("skey")
  let stid = localStorage.getItem('stid')

  checkLang()

  React.useEffect(() => {

    getSSOInfo()
    //getSemester()

    //search()

  }, []);

  function getSSOInfo() {
      console.log('check login')
      if(window.getLoginInfo() == true) {        
        stid = localStorage.getItem('stid')
        getSemester()
      } 
      else {
        //localStorage.setItem('reurl', window.location.href)
        if(window.location.href.indexOf('?') == -1 ) {
          window.loginSSO_Redirect()
        }
        // Swal.fire({
        //   title: t('請登入長庚大學帳號'),             
        //   icon: 'info',
        //   showCancelButton: false,
        //   allowOutsideClick: false,
        //   showConfirmButton: true,
        //   confirmButtonText: t('登入')
        // }).then((result) => {
        //   if (result.isConfirmed) {
        //     window.loginSSO()
        //   }
        // })            
      }
  }

  function getSemester() {
    let sUrl = sysConfig.apiUrl_IS + 'Course/GetSemester'
    let sMethod = 'get'

    axios({
      method: sMethod,
      url: sUrl
    })
      .then((response) => {
        console.log('GetSemester', response.data);
        setterms(response.data)
        checkReg(response.data[0].TERMID)

      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  function checkReg(termid) {

    settid(termid)
    let sUrl = sysConfig.apiUrl_IS + 'Course/CheckTermRegSchedule?termid=' + termid
    let sMethod = 'post'

    axios({
      method: sMethod,
      url: sUrl
    })
      .then((response) => {
        console.log('CheckTermRegSchedule', response.data);
        if (response.data.RESULT == 1) {
          let role = localStorage.getItem('role')
          if(role.toLowerCase().indexOf('student') > -1) {
            setIsReg(true)
          }          
        }
        else {
          setIsReg(false)
        }

        getStudentSection(termid)

      })
      .catch((error) => {
        console.log(error)
      })

  }

  function getSelData() {
    let endpoints = [
      sysConfig.apiUrl_IS + "Course/GetSemester",
      sysConfig.apiUrl_IS + "Course/GetDepartmentByCategory?categoryid=3"
    ];
    axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
      (data) => {
        console.log('all', data[0], data[1])
        setSem(data[0].data)
        setDep(data[1].data)
      }
    );
  }

  function add() {
    setModalShow(true)
  }

  function openCourseDetail(cobj) {
    //const cobj = course[index]    

    let sUrl = sysConfig.apiUrl_IS + 'Course/GetCourseSections?sectionid='+cobj.sectionid
    let sMethod = 'get'

    axios({
      method: sMethod,
      url: sUrl
    })
      .then((response) => {
        console.log(response.data);
        setd_cobj(response.data[0])
        setModalShowCourseDetail(true)        
      })
      .catch((error) => {
        console.log(error)
      })

    
    //alert(sectionid)
  }

  function updCourse() {    
    getSSOInfo()
    setModalShow(false)
  }

  function addCourse() {
    var list = localStorage.getItem('bookingIds')
    console.log('addCourse', list)

    if (list == '' || list == null) {
      Swal.fire({
        title: t('請選擇課程'),
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: t('確定')
      })
      return
    }

    let sUrl = sysConfig.apiUrl_IS + 'Course/AddCourse?studentid=' + stid + '&sectionid=' + list
    let sMethod = 'post'


    axios({
      method: sMethod,
      url: sUrl
    })
      .then((response) => {
        console.log(response.data);
        if (response.data[0].RESULT == 1) {
          setModalShow(false)
          getSSOInfo()
        } else {
          Swal.fire({
            title: response.data[0].MESSAGE.split('msg:')[1],
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: '確定'
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })

  }

  function orderBooking(itemid, direction) {
    let sUrl = sysConfig.apiUrl_IS + 'Booking/OrderBooking?itemid=' + itemid + '&direction=' + direction
    let sMethod = 'post'

    axios({
      method: sMethod,
      url: sUrl
    })
      .then((response) => {
        console.log(response.data);
        //search()
      })
      .catch((error) => {
        console.log(error)
      })
      .then(() => {

      })
  }

  function del(item) {

    let disName = ""
    if (i18n.language == 'en') {
      disName = item.SECTIONID + " " + item.ECOURSENAME
    } else {
      disName = item.SECTIONID + " " + item.CCOURSENAME
    }

    Swal.fire({
      title: t('確定退選這門課程'),
      text: disName,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('確定'),
      cancelButtonText: t('取消')
    }).then((result) => {
      if (result.isConfirmed) {
        let sUrl = sysConfig.apiUrl_IS + 'Course/DelCourse?sectionid=' + item.SECTIONID + '&studentid=' + stid
        let sMethod = 'post'

        openLoading(true)

        axios({
          method: sMethod,
          url: sUrl
        })
          .then((response) => {
            console.log(response.data);
            getStudentSection(tid)
          })
          .catch((error) => {
            console.log(error)
          })
          .then(() => {
            openLoading(false)
          })
      }
    })


  }

  let frameStyle = {
    top: 0, left: 0, bottom: 0, right: 0, border: 'none', margin: 0, padding: 0, overflow: 'hidden',
    width: '100%',
    height: window.innerHeight - 250 + 'px'
  }

  function showDetail(index, itemid, sectionid) {
    console.log(index, itemid, sectionid)

    let endpoints = [
      sysConfig.apiUrl_IS + "Course/GetSectionTime?sectionid=" + sectionid,
      sysConfig.apiUrl_IS + "Course/GetSectionSchedules?sectionid=" + sectionid
    ];
    axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
      (data) => {
        console.log('all', data[0], data[1])
        let cobj = course[index]
        cobj.time = data[0].data[0]
        cobj.subject = data[1].data
        setCourseDetail(cobj)

        setModalShow(true)
      }
    );

  }

  function DetailModal(props) {

    return (
      <Modal
        {...props}
        dialogClassName="modalStyle"
        keyboard="false"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form onSubmit="">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t('課程查詢')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="row">
              <iframe style={frameStyle} id="cframe" src="/course?f=1"></iframe>
            </div>

          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            {/* <input type="button" onClick={() => updCourse()} className="btn btn-primary col-3" value={t('確定')} /> */}
            <Button variant="secondary" onClick={() => updCourse()} className="col-3">{t('關閉')}</Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  // function search(event) {
  //   event.preventDefault();
  //   getCourse(event.target.p_TERMID.value, event.target.p_DEPARTMENTID.value)
  // }

  function reset() {
    window.location.href = window.location.href
  }

  function getStudentCourseCalendar(termid) {
    openLoading(true)
    let param = '?termid=' + termid + '&studentid=' + stid

    const apiUrl = sysConfig.apiUrl_IS + 'Course/GetStudentCourseCalendar' + param

    axios.get(apiUrl).then((response) => {

      const res = response.data
      console.log('getStudentCourseCalendar', res)
      setcalendar(res)

    })
      .catch((error) => {
        console.log(error)
      }).then(() => {
        openLoading(false)
      })
  }

  function getStudentCourseLog(termid) {
    openLoading(true)
    let param = '?termid=' + termid + '&studentid=' + stid

    const apiUrl = sysConfig.apiUrl_IS + 'Course/GetStudentCourseLog' + param

    axios.get(apiUrl).then((response) => {

      const res = response.data
      console.log('getStudentCourseLog', res)
      setcourseLog(res)

    })
      .catch((error) => {
        console.log(error)
      }).then(() => {
        openLoading(false)
      })
  }

  function getStudentSection(termid) {
    openLoading(true)
    //event.preventDefault();
    let param = '?termid=' + termid + '&studentid=' + stid

    const apiUrl = sysConfig.apiUrl_IS + 'Course/GetStudentSection' + param

    axios.get(apiUrl).then((response) => {

      const res = response.data
      console.log('course', res)

      let course_sort1 = []
      let course_sort2 = []
      res.forEach(item => {
        let tt = item.ETIMEMASK
        if(tt != '0 00:00-00:00') {
          course_sort1.push(item)
        } 
        else {
          course_sort2.push(item)
        }
      })
      let course_sort3 = course_sort1.concat(course_sort2);

      setCourse(course_sort3)
      getStudentCourseLog(termid)
      getStudentCourseCalendar(termid)
    })
      .catch((error) => {
        console.log(error)
      }).then(() => {
        openLoading(false)
      })
  }

  const bodyStyle = {
    height: '500px'
  }

  function formatTime(ctime) {
    var arr = ctime.split(' ');
    var week = '';
    var time = '';
    if(arr.length > 0)  week = t(arr[0])
    if(arr.length > 1)  time = arr[1]

    var res = ''
    res = week + ' ' + time
    return res
  }

  const mTableBody = course.map((item, index) =>
    <tr>

      <td className="td-stext">{item.SECTIONID}</td>
      <td>{item.CALL_ID}</td>
      <td className="td-stext">{item.CHTDEPALIAS}</td>
      <td className="td-stext">        
        <a href="javascript:void(0)" onClick={() => openCourseDetail({sectionid: item.SECTIONID})} className="my-anchor-element" >
          {i18n.language == 'en'? item.ECOURSENAME : item.CCOURSENAME} 
        </a>
      </td>
      <td className="td-stext">{item.NAME}</td>
      <td>{item.CREDITS}</td>
      <td>{formatTime(item.ETIMEMASK)}</td>
      <td>{item.STATUS == 'ADD' ? t("加選") : t("退選")}</td>
      {
        isReg ?
          <td>
            <button className="btn btn-danger" onClick={() => del(item)}><i className="bi bi-trash"></i></button>
          </td>
          :
          <></>
      }
      {/* <td>

          [{item.YEAR}-{item.GROUPID}] 
         {i18n.language == 'en'? item.ECOURSENAME : item.CCOURSENAME} 
         {item.FIELDNAME}
        &nbsp;
        <span className="badge bg-secondary">{item.NAME}</span>
      </td>
      <td>
        {item.CREDITS}
      </td>
      <td>
        {item.ETIMEMASK}
      </td> */}
      

    </tr>
  )

  function chgTerm(event) {
    const tid = event.target.value
    checkReg(tid)
  }

  let tdColName = ""
  tdColName += '.mt1 td:nth-of-type(1):before {content : "'+ t("開課序號") +'"}'
  tdColName += '.mt1 td:nth-of-type(2):before {content : "'+ t("科目代號") +'"}'
  tdColName += '.mt1 td:nth-of-type(3):before {content : "'+ t("開課單位") +'"}'
  tdColName += '.mt1 td:nth-of-type(4):before {content : "'+ t("課程名稱") +'"}'
  tdColName += '.mt1 td:nth-of-type(5):before {content : "'+ t("授課教師") +'"}'
  tdColName += '.mt1 td:nth-of-type(6):before {content : "'+ t("學分") +'"}'
  tdColName += '.mt1 td:nth-of-type(7):before {content : "'+ t("上課時間") +'"}'
  tdColName += '.mt1 td:nth-of-type(8):before {content : "'+ t("狀態") +'"}'
  tdColName += '.mt1 td:nth-of-type(9):before {content : "'+ t("退選") +'"}'

  const mTable = (
    <table className="table m-table mt1">
      <style>{tdColName}</style>
      <thead>

        <tr>
          <th nowrap="nowrap">{t("開課序號")}</th>
          <th nowrap="nowrap">{t("科目代號")}</th>
          <th nowrap="nowrap">{t("開課單位")}</th>
          <th nowrap="nowrap">{t("課程名稱")}</th>
          <th nowrap="nowrap">{t("授課教師")}</th>
          <th nowrap="nowrap">{t("學分")}</th>
          <th nowrap="nowrap">{t("上課時間")}</th>
          <th nowrap="nowrap">{t("狀態")}</th>
          {
            isReg ? <th nowrap="nowrap">{t("退選")}</th> : <></>
          }
          {/* <th nowrap="nowrap">開課序號</th>
          <th nowrap="nowrap">科目代號</th>
          <th nowrap="nowrap">科目名稱</th>
          <th nowrap="nowrap">調整順序</th>
          <th nowrap="nowrap">刪除</th> */}
        </tr>
      </thead>
      <tbody>
        {
          course.length > 0 ?
            (
              <>{mTableBody}</>
            )
            :
            <tr><td align="center" colSpan={10}>{t("無資料")}</td></tr>
        }

      </tbody>
    </table>
  )

  let tdColName2 = ""
  tdColName2 += '.mt2 td:nth-of-type(1):before {content : "'+ t("開課序號") +'"}'
  tdColName2 += '.mt2 td:nth-of-type(2):before {content : "'+ t("加/退選") +'"}'
  tdColName2 += '.mt2 td:nth-of-type(3):before {content : "'+ t("時間") +'"}'
  tdColName2 += '.mt2 td:nth-of-type(4):before {content : "'+ t("說明") +'"}'
  

  const mTableLog = (
    <table className="table m-table mt2">
      <style>{tdColName2}</style>
      <thead>

        <tr>
          <th nowrap="nowrap">{t("開課序號")}</th>
          <th nowrap="nowrap">{t("加/退選")}</th>
          <th nowrap="nowrap">{t("時間")}</th>
          <th nowrap="nowrap">{t("說明")}</th>

        </tr>
      </thead>
      <tbody>
        {
          course.length > 0 ?
            courseLog.map((item, index) => (
              <tr>
                <td>{item.C_SEC_ID}</td>
                <td>
                  {
                    item.OPERATION == 'INSERT' ?
                      <span className="text-primary">{t("加選")}</span>
                      :
                      <span className="text-danger">{t("退選")}</span>
                  }
                </td>
                <td>{Moment(item.TIMESTAMP).format('yyyy-MM-DD HH:mm:ss')}</td>
                <td>{item.SOURCE}</td>
              </tr>
            ))
            :
            <tr><td align="center" colSpan={10}>{t("無資料")}</td></tr>
        }

      </tbody>
    </table>
  )

  function formatInfo(item) {
    // let arr = info.split(' ')
    // let res_info = '';
    // if(arr.length > 0)  res_info += "<div>"+ sid + " " +arr[0]+"</div>"
    // if(arr.length > 1)  res_info += "<div>"+arr[1]+"</div>"
    let res_info = "<span>" + item.ccoursename + ' ' + item.calL_ID + '(' + item.sectionid + ')' + '</span>'
    return res_info
  }

  const mTableCalendar = (
    <table className="table s-table" style={{display: 'table'}}>
      <thead>

        <tr>
          <th nowrap="nowrap">{t("上課時間")}</th>
          <th nowrap="nowrap">{t("一")}</th>
          <th nowrap="nowrap">{t("二")}</th>
          <th nowrap="nowrap">{t("三")}</th>
          <th nowrap="nowrap">{t("四")}</th>
          <th nowrap="nowrap">{t("五")}</th>

        </tr>
      </thead>
      <tbody>
        {
          course.length > 0 ?
            calendar.map((item, index) => (
              // item.time != null ?
              // <tr>
              //   <td>{item.time}</td>
              // </tr>              
              item.time != null && item.time != '00:00~00:00' ?
                <tr>
                  <td width="10%">{item.time}</td>
                  <td width="18%">
                    {item.w2.map((w) => (
                      <div>
                        <a href="javascript:void(0)" onClick={() => openCourseDetail(w)} className="my-anchor-element"
                          data-tooltip-content={w.info}>{parse(formatInfo(w))}</a>
                        <Tooltip anchorSelect=".my-anchor-element" />
                      </div>
                    ))}
                  </td>
                  <td width="18%">
                    {item.w3.map((w) => (
                      <div>
                        <a href="javascript:void(0)" onClick={() => openCourseDetail(w)} className="my-anchor-element"
                          data-tooltip-content={w.info}>{parse(formatInfo(w))}</a>
                        <Tooltip anchorSelect=".my-anchor-element" />
                      </div>
                    ))}
                  </td>
                  <td width="18%">
                    {item.w4.map((w) => (
                      <div>
                        <a href="javascript:void(0)" onClick={() => openCourseDetail(w)} className="my-anchor-element"
                          data-tooltip-content={w.info}>{parse(formatInfo(w))}</a>
                        <Tooltip anchorSelect=".my-anchor-element" />
                      </div>
                    ))}
                  </td>
                  <td width="18%">
                    {item.w5.map((w) => (
                      <div>
                        <a href="javascript:void(0)" onClick={() => openCourseDetail(w)} className="my-anchor-element"
                          data-tooltip-content={w.info}>{parse(formatInfo(w))}</a>
                        <Tooltip anchorSelect=".my-anchor-element" />
                      </div>
                    ))}
                  </td>
                  <td width="18%">
                    {item.w6.map((w) => (
                      <div>
                        <a href="javascript:void(0)" onClick={() => openCourseDetail(w)} className="my-anchor-element"
                          data-tooltip-content={w.info}>{parse(formatInfo(w))}</a>
                        <Tooltip anchorSelect=".my-anchor-element" />
                      </div>
                    ))}
                  </td>
                </tr>
                :
                <>
                {
                  item.time == '00:00~00:00' ?
                  <tr>
                    <td>{t('未指定')}</td>
                    <td colSpan={4}>
                    {item.w0.map((w) => (
                      <div>
                        <a href="javascript:void(0)" onClick={() => openCourseDetail(w)} className="my-anchor-element"
                          data-tooltip-content={w.info}>{parse(formatInfo(w))}</a>
                        <Tooltip anchorSelect=".my-anchor-element" />
                      </div>
                    ))}
                    </td>
                  </tr>
                  :
                  <></>
                }
                </>
            ))
            :
            <tr><td align="center" colSpan={10}>{t("無資料")}</td></tr>
        }

      </tbody>
    </table>
  )

  const Main = (
    <>
      <main>
        <div className="container">
          <section className="section  min-vh-100 d-flex flex-column py-4" >


            <div className="container">



              <div className="row">
                <div className="col-lg-12">
                  <div className="card">                    
                    <div className="card-body">
                    <h5 className="card-title">
                      {
                        isReg?
                        <button onClick={() => add()} className="btn btn-secondary float-end"><i className="bi bi-plus-circle"></i> {t("加選課程")}</button>
                        :
                        <></>
                      }                     
                    </h5>
                    <div className="row">
                        <div className="col-lg-3 mt-3">
                          <div class="form-floating">
                            <select onChange={chgTerm} class="form-select" name="termid" aria-label="State" >
                              {
                                terms.map(item => (
                                  <option value={item.TERMID}>{i18n.language == 'en' ? item.ETERMNAME : item.CTERMNAME}</option>
                                ))
                              }
                            </select> 
                            <label for="">{t("開課學期")}</label>                          
                          </div>
                        </div>
                        <div className="col-lg-6" style={{marginTop: '35px', paddingLeft: '20px'}}>
                          {localStorage.getItem('dep')} {localStorage.getItem('stid')} {localStorage.getItem('userName')}
                        </div>          
                    </div>
                      

                      <ul class="nav nav-tabs pt-3" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button className={"nav-link active"} id={"tabh0"} data-bs-toggle="tab" data-bs-target={"#tab0"} type="button" aria-selected={"true"}>
                            {t("修課清單")}
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button className={"nav-link"} id={"tabh1"} data-bs-toggle="tab" data-bs-target={"#tab1"} type="button" aria-selected={"false"}>
                            {t("課程加退選紀錄")}
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button className={"nav-link"} id={"tabh2"} data-bs-toggle="tab" data-bs-target={"#tab2"} type="button" aria-selected={"false"}>
                            {t("學生課表")}
                          </button>
                        </li>
                      </ul>
                      <div class="tab-content pt-3" id="myTabjustifiedContent">
                        <div className={"tab-pane fade show active"} id={"tab0"} >
                          {mTable}
                        </div>
                        <div className={"tab-pane fade"} id={"tab1"} >
                          {mTableLog}
                        </div>
                        <div className={"tab-pane fade"} id={"tab2"} >
                          {mTableCalendar}
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>
        </div>
      </main>
    </>
  )

  return (
    <LayoutSc>
      {Main}    

      <DetailModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <CourseDetail
        cobj={d_cobj}
        show={modalShowCourseDetail}
        onHide={() => setModalShowCourseDetail(false)}
      />

    </LayoutSc>

  )
}

export default StuCourse