import React from "react"
import Layout from "../common/Layout"
import { useState, useEffect, useContext } from 'react'
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AppContext } from '../App';
import Swal from 'sweetalert2'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { useTranslation } from "react-i18next";

const WebLink = () => {
  const { t, i18n } = useTranslation();
  const pageName = '網站連結'
  const { sysConfig } = useContext(AppContext)

  const [modalShow, setModalShow] = React.useState(false);
  const [isLoading, setLoading] = useState(true);
  const [mData, setmData] = useState();
  const [selectedFile, setSelectedFile] = useState();
  let isOpen = false

  const [fId, setfId] = useState()
  const [fName, setfName] = useState()
  const [fDesc, setfDesc] = useState()
  const [fGroup, setfGroup] = useState()
  const [cGroup, setcGroup] = useState()
  const [defTag, setDefTag] = useState()

  const initObj = {
    link_id: "",
    link_group_id: "",
    name: "",
    description: "",
    url: "",
    url_target: 0,
    is_system_default: true
  }
  const [fData, setfData] = useState(initObj);

  function getdata() {
    const apiUrl = sysConfig.apiUrl + "Link"

    axios.get(apiUrl).then(response => {
      setTimeout(() => {
        setmData(response.data);
        console.log(response.data)
        setLoading(false);

      }, 1);

    });
  }

  function getTags() {
    const apiUrl = sysConfig.apiUrl + "Tag?mode=link"

    axios.get(apiUrl).then(response => {

      console.log('group', response.data)
      setfGroup(response.data)
    });
  }


  function upsert(index) {
    setDefTag([])
    getTags()
    setModalShow(true)
    if (index > -1) {
      const obj = mData[index]
      //const index = mData.findIndex(a => a.function_id == id)
      // setfId(obj.function_id)
      // setfName(obj.name)
      // setfDesc(obj.description)
      setfData(obj)

      let tArr = []
      for (var i in obj.tags) {
        tArr.push({ value: obj.tags[i].id, label: obj.tags[i].name })
      }
      setDefTag(tArr)
    }
    else {
      setfData(initObj)
    }
  }

  function save(event) {
    event.preventDefault();
    console.log(event.target.iName.value)

    let chk = [];

    for (var j = 0; j < event.target.iGroup.length; j++) {
      chk.push(event.target.iGroup[j].value)
    }
    if (typeof (event.target.iGroup.defaultValue) != 'undefined') {
      if (event.target.iGroup.defaultValue != '')
        chk.push(event.target.iGroup.defaultValue)
    }
    console.log('cgroup chk', chk)

    if (event.target.iFile.files.length > 0) {      
      if(event.target.iFile.files[0].name.indexOf('.jpg') == -1
          && event.target.iFile.files[0].name.indexOf('.png') == -1) {
        Swal.fire({
          title: '請上傳圖檔(.jpg或.png)',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: '確定'
        })
        return;
      }
      if(event.target.iFile.files[0].size > 204800) {
        Swal.fire({
          title: '上傳檔案大小請勿超過200KB',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: '確定'
        })
        return;
      }
    }

    if (event.target.iName.value === '') {
      Swal.fire({
        title: '請輸入名稱',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: '確定'
      }
      )
      return
    }

    let sUrl = sysConfig.apiUrl + 'Link'
    let sMethod = 'post'

    let sData = {
      link_group_id: '08dad47a-f234-4026-8343-04f72e04ea49',
      name: event.target.iName.value,
      ename: event.target.iEName.value,
      url: event.target.iUrl.value,
      iconpath: event.target.iIconPath.value,
      tags: chk
    }


    if (fData.link_id != '') {
      sUrl += '/' + fData.link_id
      sMethod = 'put'
    }

    axios({
      method: sMethod,
      url: sUrl,
      data: sData
    })
      .then((response) => {

        console.log(response.data);

        // upload file
        if (event.target.iFile.files.length > 0) {
          let uid = fData.link_id
          if (sMethod == 'post') uid = response.data;

          const formData = new FormData();
          formData.append('File', event.target.iFile.files[0]);

          let fUrl = sysConfig.apiUrl + 'Link/UploadFile?id=' + uid

          axios.post(fUrl, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            }
          })
            .catch((error) => {
              console.log(error)
            })
            .then(() => {
              setModalShow(false)
              getdata()
            })
        } else {
          setModalShow(false)
          getdata()
        }

      })
      .catch((error) => {
        console.log(error)
      })

  }

  function del(index) {

    Swal.fire({
      title: '確定刪除這筆資料',
      //text: "確定刪除這筆資料?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '確定',
      cancelButtonText: '取消'
    }).then((result) => {
      if (result.isConfirmed) {
        const obj = mData[index]
        let sUrl = sysConfig.apiUrl + 'Link/' + obj.link_id
        let sMethod = 'delete'

        const ndata = mData.filter(item => item.link_id !== obj.link_id)
        setmData(ndata)

        axios({
          method: sMethod,
          url: sUrl
        })
          .then((response) => {
            if (response.data.success) {
              Swal.fire({
                title: '刪除成功',
                icon: 'success',
                showCancelButton: false,
                timer: 2000,
                confirmButtonText: '確定'
              })

            } else {
              const rdata = response.data.data
              let rmsg = ''
              for (var i in rdata) {
                rmsg += rdata[i].name + "[" + t("position_" + rdata[i].position) + "] "
              }
              Swal.fire({
                title: response.data.msg,
                text: rmsg,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: '確定'
              })
            }
            getdata()
          })
          .catch((error) => {
            console.log(error)
          })
      }
    })

    //if(window.confirm('確定刪除?') === false) return


  }

  const handleChange = e => {
    //setcGroup(Array.isArray(e) ? e.map(x => x.value) : []);
    console.log('cgroup', e)
    //setcGroup(e)
  }

  const changeFileHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  function UpsertModal(props) {
    //setOpen(props.show)
    isOpen = props.show
    console.log(isOpen)

    let groups = []
    for (var i in fGroup) {
      groups.push({ value: fGroup[i].id, label: fGroup[i].name })
    }

    return (
      <Modal
        {...props}
        size="lg"
        backdrop="static"
        keyboard="false"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form onSubmit={save}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {pageName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="row g-3">
              <div className="col-md-4">
                <label className="form-label">名稱</label>
                <input type="text" defaultValue={fData.name} className="form-control" name="iName" />
              </div>
              <div className="col-md-8">
                <label className="form-label">英文名稱</label>
                <input type="text" defaultValue={fData.ename} className="form-control" name="iEName" />
              </div>
              <div className="col-md-12">
                <label className="form-label">連結</label>
                <input type="text" defaultValue={fData.url} className="form-control" name="iUrl" />
              </div>
              <div className="col-md-4">
                <label className="form-label">上傳圖示</label>
                <input type="file" className="form-control" name="iFile"  accept=".png,.jpg" />
              </div>
              <div className="col-md-8">
                <label className="form-label">分類</label>
                {/* <input type="text"   className="form-control" name="iGroup"/> */}
                <CreatableSelect
                  defaultValue={defTag}
                  isMulti
                  name="iGroup"
                  options={groups}
                  noOptionsMessage={() => "無項目"}
                  placeholder={"請選擇項目"}
                  formatCreateLabel={(inputText) => "新增: " + inputText}
                  className="basic-multi-select"
                  onChange={handleChange}
                  classNamePrefix="select"
                />
              </div>
              <div className="col-md-12">
                <label className="form-label">圖示</label>
                <input type="hidden" name="iIconPath" value={fData.iconpath} />
                {
                  fData.iconpath == null || fData.iconpath == '' ?
                    <img src="./img/t1.png" title="預設圖示" style={{ width: '100px', marginLeft: '10px' }} />
                    :
                    <img src={sysConfig.imgUrl + 'link/' + fData.iconpath} style={{ width: '100px', marginLeft: '10px' }} />
                }
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <input type="submit" name="submit" className="btn btn-primary" value="儲存" />
            <Button variant="secondary" onClick={props.onHide}>取消</Button>

          </Modal.Footer>
        </form>
      </Modal>
    );
  }



  useEffect(() => {
    getdata()

  }, [sysConfig]);

  if (isLoading) {
    return (
      <Layout>
        <main id="main" className="main">Loading...</main>
      </Layout>
    )
  }


  const mTableBody = mData.map(
    (f, index) => (
      <tr key={f.link_id}>
        <td>
          {
            f.iconpath == null || f.iconpath == '' ?
              <img src="./img/t1.png" title="預設圖示" style={{ width: '35px', marginRight: '10px' }} />
              :
              <img src={sysConfig.imgUrl + 'link/' + f.iconpath} style={{ width: '35px', marginRight: '10px' }} />
          }
          <a href={f.url} target="_blank">{f.name}</a>
          {
            f.tags.map((item, index) => (
              <span className="badge bg-secondary m-1">{item.name}</span>
            ))
          }
        </td>

        <td>
          <button className="btn btn-warning" onClick={() => upsert(index)}><i className="bi bi-pencil-square"></i></button>&nbsp;
          <button className="btn btn-danger" onClick={() => del(index)}><i className="bi bi-trash"></i></button>
        </td>
      </tr>
    )
  )
  const mTable = (
    <table className="table">
      <thead>
        <tr>
          <th>名稱</th>
          {/* <th>分類</th> */}
          <th>編輯</th>
        </tr>
      </thead>
      <tbody>
        {mTableBody}
      </tbody>
    </table>
  )
  const mBody = (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{pageName}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/">首頁</a></li>
              <li className="breadcrumb-item active">{pageName}</li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  {/* <h5 className="card-title">系統功能</h5> */}
                  <div className="row">
                    <div className="col-lg-6">
                      <h5 className="card-title">{pageName}列表</h5>
                    </div>
                    <div className="col-lg-6">
                      <h5 className="card-title">
                        <button className="btn btn-secondary float-end" onClick={() => upsert(-1)}><i className="bi bi-plus-circle"></i> 新增</button>
                      </h5>
                    </div>
                  </div>
                  {mTable}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )


  return (
    <Layout>
      {mBody}
      <UpsertModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Layout>

  );

}
export default WebLink