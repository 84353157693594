import './App.css';
import Login from './pages/Login';
import User from './pages/User'
import Home from './pages/Home'
import Role from './pages/Role'
import Logout from './pages/Logout';

import { BrowserRouter, Routes, Route, NavLink, Link } from "react-router-dom"
import { useState, useEffect } from 'react'
import { useNavigate, useLocation, useRoutes } from 'react-router-dom'
import React, { createContext } from 'react';
import Layout from './common/Layout';
import Header from './common/Header';
import Footer from './common/Footer';
import Siderbar from './common/Siderbar'
import Fun from './pages/Fun';
import WebLink from './pages/WebLink';
import Menu from './pages/Menu';
import Menu2 from './pages/Menu2';
import RssBlock from './pages/RssBlock';
import Course from './pages/sch/Course';
import CourseData from './pages/sch/CourseData';
import CourseTest from './pages/sch/CourseTest';
import DepCourse from './pages/sch/DepCourse';
import Booking from './pages/sch/Booking';
import StuCourse from './pages/sch/StuCourse';
import StuGrade from './pages/sch/StuGrade';
import AuthError from './pages/sch/AuthError'
import axios from "axios";
import School from './pages/School';
import SSO from './pages/SSO';
import Block from './pages/Block';
import ScAuth from './pages/sch/ScAuth';
import Search from './pages/Search';
import Report from './pages/Report';
import Tag from './pages/Tag';
import Page from './pages/Page';
import PageContent from './pages/PageContent';
import PageShow from './pages/PageShow';
import StyleTest from './pages/StyleTest';
import TableTest from './pages/TableTest';
import LoginRecord from './pages/LoginRecord';
import LogoutRe from './pages/LogoutRe';
import CoursePrint from './pages/sch/CoursePrint';
export const AppContext = createContext()

function App() {
  const gToken = localStorage.getItem("token")  
  console.log('app.js')
  const [sysName, setSysName] = useState('cgu-sch-web')

  const [token, setToken] = useState([])
  
  const navigate = useNavigate();
  const location = useLocation();  
  
  const [loading, showLoading] = useState(false)
  const [sysToken, setSysToken] = useState("xxx")
  const [favorite, setFavorite] = useState([])
  const [locale, setLocale] = useState(navigator.language);
  const [pageid, setpageid] = useState([])

  let sysConfig = null
  let sLogin = false
  if(gToken != null)  sLogin = true

  const [isLogin, setIsLogin] = useState(sLogin)
  const [userRole, setuserRole] = useState('')
  //alert(isLogin)

  // axios.get('http://localhost:3000/conf/config.json').then(response => {
  //   sysConfig = response.data
  //   console.log('loadConfig', sysConfig)
  // });

  const request = new XMLHttpRequest();
  const env = window.getEnv()
  const confPath = 'conf/config.' + env + '.json'
  request.open('GET', confPath, false);  
  request.send(null);

  try {
    sysConfig = JSON.parse(request.responseText)
  } 
  catch {
    console.error('confPath error',confPath)
  }
  
  const appContextValue = {
    sysName, setSysName, sysConfig, pageid, setpageid
    , sysToken, setSysToken
    , favorite, setFavorite
    , loading, showLoading
    , isLogin , setIsLogin
    , userRole, setuserRole
  }

  useEffect(() => {
    //alert(location.pathname)
    // if(gToken == null && (location.pathname != '/login')) {
    //   isLogin = false
    //   //return navigate("/login")
    // } else {
    //   isLogin = true
    // }
  },[])  

  // if(gToken == null) {
  //   isLogn = false

  //   if(location.pathname != '/login') {
  //     return (
  //       <>
  //       <MenuTop isLogin={isLogn}></MenuTop>
  //       <div>請登入系統</div>
  //       </>           
  //     )
  //   }
  // } else {
  //   isLogn = true
  // }

  const aa = (
    <div>aaa</div>
  )

  const routeConfig = [
    {
       path:'/',
       element:<Home />
    },
    ,
    {
       path:'/sso',
       element:<SSO />
    },
    {
       path:'/user',
       element:<User />
    },
    {
       path:'/fun',
       element:<Fun />
    },
    {
       path:'/rssblock',
       element:<RssBlock />
    }
    ,
    {
       path:'/weblink',
       element:<WebLink />
    },
    {
       path:'/tabletest',
       element:<TableTest />
    }
    ,
    {
       path:'/role',
       element:<Role />
    },
    {
       path:'/loginrecord',
       element:<LoginRecord />
    },
    {
       path:'/tag',
       element:<Tag />
    },
    {
       path:'/styletest',
       element:<StyleTest />
    },
    {
       path:'/search',
       element:<Search />
    },
    {
       path:'/report',
       element:<Report />
    }
    ,
    {
       path:'/login',
       element:<Login />
    },
    {
       path:'/scauth',
       element:<ScAuth />
    },
    {
       path:'/autherror',
       element:<AuthError />
    },
    {
       path:'/stucourse',
       element:<StuCourse />
    },
    {
       path:'/stugrade',
       element:<StuGrade />
    }
    ,
    {
       path:'/course',
       element:<Course />
    },
    {
       path:'/coursedata',
       element:<CourseData />
    },
    {
      path:'/courseprint',
      element:<CoursePrint />
    },
    {
       path:'/coursetest',
       element:<CourseTest />
    },
    {
       path:'/depcourse',
       element:<DepCourse />
    },
    {
       path:'/booking',
       element:<Booking />
    }
    ,
    {
       path:'/menu',
       element:<Menu />
    } ,
    {
       path:'/menu2',
       element:<Menu2 />
    }
    ,
    {
       path:'/block',
       element:<Block />
    },
    {
       path:'/page',
       element:<Page />
    },
    {
       path:'/pagecontent',
       element:<PageContent />
    },
    {
       path:'/pageshow',
       element:<PageShow />
    }
    ,
    {
       path:'/school',
       element:<School />
    }
    ,
    {
       path:'/logout',
       element:<Logout />
    },
    {
      path:'/logoutre',
      element:<LogoutRe />
    },
    {
       path:'/ssores',
       element:<SSORes />
    }
   ]

   const element = useRoutes(routeConfig)   

  return (
    <AppContext.Provider value={appContextValue} setLocale={setLocale}>
      {/* <MenuTop isLogin={isLogn}></MenuTop> */}
      <div>
        {
        /* <Header />
        <Siderbar/>
        <Routes>
          <Route element={Layout} >
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/fun" element={<Fun />} />
            <Route path="/login" element={<Login />} />
            <Route path="/user" element={<User />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes> */}
        {/* <Header/>
        <Siderbar/> */}
        {element}
       
      </div>
      {/* <Routes>
      <Route path="/" element={<Layout />} />
      <Route path="/about" element={<About />} />
      <Route path="/login" element={<Login />} />
      <Route path="/user" element={<User />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="*" element={<NotFound />} />
    </Routes> */}
    </AppContext.Provider>
  );

}

// const MenuTop = ({ isLogin }) => {

//   console.log('isLogin', isLogin)

//   return (
//     <nav>
//       <div className="menu-top" to="/">Home</div>
//       <div className="menu-top" to="/user">User</div>
//       <div className="menu-top" to="/about">About</div>
//       {/* <NavLink className="menu-top" to="/login">Login</NavLink> */}
//       {
//         isLogin ? (
//           <div className="menu-top" to="/logout">Logout</div>
//         ) : (
//           <div className="menu-top" to="/login">Login</div>
//         )
//       }


//     </nav>
//   )
// }

const NotFound = () => {
  return <div>Page Not Found</div>
}

const SSORes = () => {
  let res = window.getSSOData()
  window.location.href = '/'  
}

const About = () => {
  return <div>hello About</div>
}



export default App;
