import Modal from 'react-bootstrap/Modal';
import { useContext, useState } from "react";
import { AppContext } from '../../App';
import axios from "axios";
import { useTranslation } from "react-i18next";
import Moment from 'moment'

const CourseDetail = (props) => {
    const { t, i18n } = useTranslation();
    const { sysConfig, showLoading } = useContext(AppContext)
    const [courseDetail, setCourseDetail] = useState({ time: [], subject: [], preCourse:[] });

    let isParam = false
    if(window.location.href.indexOf('?sectionid=') > -1) {
        isParam = true
    }
   
    function loadDetail(cobj) {

        //openLoading(true)
        let endpoints = [
            sysConfig.apiUrl_IS + "Course/GetSectionTime?sectionid=" + cobj.SECTIONID,
            sysConfig.apiUrl_IS + "Course/GetSectionSchedules?sectionid=" + cobj.SECTIONID,
            sysConfig.apiUrl_IS + "Course/GetPreCourse?call_id=" + cobj.CALL_ID 
        ];
        axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
            (data) => {
                cobj.time = data[0].data
                cobj.subject = data[1].data
                cobj.preCourse = data[2].data
                console.log('cobj', cobj)
                setCourseDetail(cobj)
            }
        ).then(() => {
            //openLoading(false)
        })
    }


    if (props.cobj != null) {
        loadDetail(props.cobj)
        //alert(props.sectionid)
    }

    return (
        <Modal
            {...props}
            size="lg"
            scrollable="true"
            keyboard="false"
            aria-labelledby="contained-modal-title-vcenter" 
             
        >
            <form onSubmit="">
                {
                    isParam == false ?
                    <>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {t("課程詳細資料")} 
                            <a title={t("友善列印")} target='_blank' href={'courseprint?sectionid='+courseDetail.SECTIONID}> <i class="bi bi-printer"></i></a>                            
                            &nbsp;<a title={t("另開連結")} target='_blank' href={'coursedata?sectionid='+courseDetail.SECTIONID}> <i class="bi bi-box-arrow-up-right"></i></a>                            
                        </Modal.Title>
                    </Modal.Header>
                    </>
                    :
                    <>
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {t("課程詳細資料")}                      
                            <a title={t("友善列印")} target='_blank' href={'courseprint?sectionid='+courseDetail.SECTIONID}> <i class="bi bi-printer"></i></a>         
                        </Modal.Title>
                    </Modal.Header>
                    </>
                }                
                <Modal.Body>

                    <div className="row" id="courseDetail" style={{ height: window.innerHeight-150+'px' }}>
                        <ul class="nav nav-tabs d-flex" id="myTabjustified" role="tablist">
                            <li class="nav-item flex-fill" role="presentation">
                                <button class="nav-link w-100 active" id="detail_1" data-bs-toggle="tab" data-bs-target="#home-justified" type="button" role="tab" aria-controls="detail_1" aria-selected="true">{t("課程資訊")}</button>
                            </li>
                            <li class="nav-item flex-fill" role="presentation">
                                <button class="nav-link w-100" id="detail_2" data-bs-toggle="tab" data-bs-target="#profile-justified" type="button" role="tab" aria-controls="detail_2" aria-selected="false">{t("教學大綱")}</button>
                            </li>
                            <li class="nav-item flex-fill" role="presentation">
                                <button class="nav-link w-100" id="detail_3" data-bs-toggle="tab" data-bs-target="#contact-justified" type="button" role="tab" aria-controls="detail_3" aria-selected="false">{t("教學進度")}</button>
                            </li>
                            <li class="nav-item flex-fill" role="presentation">
                                <button class="nav-link w-100" id="detail_4" data-bs-toggle="tab" data-bs-target="#contact-justified2" type="button" role="tab" aria-controls="detail_4" aria-selected="false">{t("先修課程")}</button>
                            </li>
                        </ul>

                        <div class="tab-content pt-2" id="myTabjustifiedContent">
                            <div class="tab-pane fade show active" id="home-justified" role="tabpanel" aria-labelledby="detail_1">
                                <table class="table">
                                    <tbody>
                                        <tr><td className="table-active" scope="col">{t("學年")}/{t("學期")}</td><td>{courseDetail.ACADMICYEAR}/{courseDetail.ACADMICTERM}</td></tr>
                                        <tr><td className="table-active" scope="col">{t("授課教師")}</td><td>{i18n.language == 'en' ? courseDetail.ENAME : courseDetail.NAME}</td></tr>
                                        <tr><td className="table-active" scope="col">{t("開課單位")}</td><td>{i18n.language == 'en' ? courseDetail.DEPARTMENTNAME_E: courseDetail.DEPARTMENTNAME_C}</td></tr>
                                        <tr><td className="table-active" scope="col">{t("年級")}-{t("班別")}</td><td>{courseDetail.YEAR}-{courseDetail.GROUPID}</td></tr>
                                        <tr><td className="table-active" scope="col">{t("科目代號")}({t("學分")})</td><td>{courseDetail.CALL_ID}({courseDetail.CREDITS})</td></tr>
                                        <tr><td className="table-active" scope="col">{t("課程名稱")}</td><td>{i18n.language == 'en' ? courseDetail.ECOURSENAME : courseDetail.CCOURSENAME}</td></tr>
                                        <tr><td className="table-active" scope="col">{t("開課序號")}</td><td>{courseDetail.SECTIONID}</td></tr>
                                        <tr><td className="table-active" scope="col">{t("主要開課序號")}</td><td>{courseDetail.MAINSECTIONID}</td></tr>
                                        <tr><td className="table-active" scope="col">{t("修課學生")}</td><td>{t("本校")}: {courseDetail.CURRENRL}{t("人")}<>；</>{t('外校')}: {courseDetail.CROENRL}{t("人")}</td></tr>
                                        <tr><td className="table-active" scope="col">{t("人數限制")}</td><td>{courseDetail.MINENRL}~{courseDetail.MAXENRL}</td></tr>
                                        <tr>
                                            <td className="table-active" scope="col">{t("上課時間地點")}</td>
                                            <td>
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr className="table-primary"><th>{t("星期")}</th><th>{t("開始")} / {t("結束")}</th><th>{t("教室")} [<a href="https://academic.cgu.edu.tw/p/412-1009-8470.php" target="_blank">{t("代碼說明")}</a>]</th><th></th></tr>
                                                    </thead>
                                                    <tbody>
                                                        
                                                            {
                                                                typeof(courseDetail.time) != 'undefined' && courseDetail.time != null && courseDetail.time.length > 0 ?
                                                                courseDetail.time.map(item => (
                                                                <tr>

                                                                    <td>{i18n.language == 'en'? item.EWEEKDAY:  item.CWEEKDAY}</td>
                                                                    <td>{item.BEGINSEC} ({item.STARTTIME}) ~  {item.ENDSEC} ({item.ENDTIME})</td>
                                                                    <td>{item.LOCATIONID}</td>
                                                                    <td>{t(item.CATEGORYNAME)}</td>
                                                                </tr>
                                                                ))
                                                                                                                           
                                                                :
                                                                <tr>
                                                                    <td colSpan="4" align="center">{t("無")}</td>
                                                                </tr>
                                                            }                                                            
                                                        
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr><td className="table-active" scope="col">{t("核心能力")}</td><td><a href={"https://ep.cgu.edu.tw/Webfolio/checkCID.aspx?cid=" + courseDetail.CALL_ID} target="_blank">{t("按此查詢")}</a></td></tr>
                                        <tr><td className="table-active" scope="col">{t("備註")}</td><td>{courseDetail.ANNOTATION}</td></tr>
                                    </tbody>

                                </table>
                            </div>
                            <div class="tab-pane fade" id="profile-justified" role="tabpanel" aria-labelledby="detail_2">
                                <table class="table">
                                    <tbody>
                                        <tr><td className="table-active" scope="col">{t("教科書")}</td><td>{courseDetail.TEXTBOOK}</td></tr>
                                        <tr><td className="table-active" scope="col">{t("教科書")}ISBN</td><td>{"0000000000"}</td></tr>
                                        <tr><td className="table-active" scope="col">{t("參考書")}</td><td>{courseDetail.REFBOOK}</td></tr>
                                        <tr><td className="table-active" scope="col">{t("課程講義網址")}</td><td>{courseDetail.LECTURE}</td></tr>
                                        <tr><td className="table-active" scope="col">{t("整體教學目標")}</td><td>{courseDetail.OBJECTIVE}</td></tr>
                                        <tr><td className="table-active" scope="col">{t("教學方法")}</td><td>{courseDetail.PEDAGOGY}</td></tr>
                                        <tr><td className="table-active" scope="col">{t("課程簡介")}</td><td>{i18n.language == 'en'? courseDetail.ENGINTRO : courseDetail.CHTINTRO}</td></tr>
                                        <tr><td className="table-active" scope="col">{t("彈性學習")}</td><td>{courseDetail.ALTLEARNING}</td></tr>
                                        <tr><td className="table-active" scope="col">{t("成績考核")}</td><td>{courseDetail.SCOREMETHOD}</td></tr>
                                        <tr><td className="table-active" scope="col">{t("師生互動時間")}</td><td>{courseDetail.OFFICEHOUR}</td></tr>

                                    </tbody>
                                </table>
                            </div>
                            <div class="tab-pane fade" id="contact-justified" role="tabpanel" aria-labelledby="detail_3">
                                <table class="table">

                                    {
                                        courseDetail.subject.length > 0 ?
                                            <thead>
                                                <tr className="table-active"><th nowrap="nowrap">{t("項次")}</th><th>{t("教學主題")}</th></tr>
                                            </thead>
                                            :
                                            <tr><th className="text-center">{t("無資料")}</th></tr>
                                    }

                                    <tbody>                                        
                                        {
                                            courseDetail.subject.map((item, index) => (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        { 
                                                                item.SECTIONDAY != null && item.SECTIONDAY != '' ?                                                                
                                                                <div>{ Moment(item.SECTIONDAY).format('yyyy/MM/DD') }</div>
                                                                :
                                                                <></>
                                                        }                                                        
                                                        {item.SECTIONCONTENT}</td>
                                                    </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div class="tab-pane fade text-center" id="contact-justified2" role="tabpanel" aria-labelledby="detail_4">
                                {/* {t("本課程沒有先修課程規定")} */}
                                <table class="table">

                                    {
                                        courseDetail.preCourse.length > 0 ?
                                            <thead>
                                                <tr className="table-active">
                                                    <th>{t("適用學年度")}</th>
                                                    <th>{t("科目代號")}</th>
                                                    <th>{t("課程名稱")}</th>
                                                    <th>{t("及格分數")}</th>
                                                </tr>
                                            </thead>
                                            :
                                            <tr><th className="text-center">{t("本課程沒有先修課程規定")}</th></tr>
                                    }

                                    <tbody>
                                        {
                                            courseDetail.preCourse.map((item, index) => (
                                                <tr>
                                                    <td>{item.ACADMICYEAR}</td>
                                                    <td>{item.PREQCOURSEID}</td>
                                                    <td>{i18n.language == 'en' ? item.PREQECOURSENAME : item.PREQCCOURSENAME}</td>
                                                    <td>{item.PASSSCORE}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                </Modal.Body>
            </form>
        </Modal>
    );


}

export default CourseDetail