import React from "react"
import Layout from "../common/Layout"
import { useState, useEffect, useContext } from 'react'
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AppContext } from '../App';
import Swal from 'sweetalert2'
import CreatableSelect from 'react-select/creatable'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Menu = () => {

  const pageName = '選單管理'
  const { sysConfig } = useContext(AppContext)

  const [modalShow, setModalShow] = React.useState(false);
  const [isLoading, setLoading] = useState(true);
  const [mData, setmData] = useState();
  let isOpen = false

  const [fId, setfId] = useState()
  const [fName, setfName] = useState()
  const [fDesc, setfDesc] = useState()

  const [fMode, setfMode] = useState('link')
  const [sFun, setsFun] = useState([])
  const [sLink, setsLink] = useState([])
  const [sPage, setsPage] = useState([])

  const [tab, setTab] = useState('left')
  const [tabName, setTabName] = useState('左側')
  const [showTagSelect, setshowTagSelect] = useState(false)

  const [ficonpath, setficonpath] = useState('')
  const [foption, setfoption] = useState([])

  const initObj = {
    menu_id: '',
    name: "",
    icon: "",
    content_dictionary: [],
    position: "",
    mode: ""
  }
  const [fData, setfData] = useState(initObj);

  function getdata(position) {

    let apiUrl = sysConfig.apiUrl + "Menu"

    if (typeof (position) != 'undefined') apiUrl += '/' + position + '?userId=admin'

    axios.get(apiUrl).then(response => {
      setTimeout(() => {
        setmData(response.data);
        console.log(response.data)
        setLoading(false);

      }, 0);

    });

    let endpoints = [
      sysConfig.apiUrl + "Function",
      sysConfig.apiUrl + "Link",
      sysConfig.apiUrl + "Page"
    ];
    axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
      (data) => {
        console.log('all', data[0], data[1], data[2])
        setsFun(data[0].data)
        setsLink(data[1].data)
        setsPage(data[2].data)

        var srr = [];
        sLink.forEach(function (f) {
          srr.push({ id: f.link_id, name: f.name })
        })
        setfoption(srr)

      }
    );


  }

  function changeTab(mode) {
    if (mode == 'left') setTabName('左側')
    if (mode == 'top') setTabName('上方')
    if (mode == 'bottom') setTabName('下方')
    setTab(mode)
    getdata(mode)
  }

  function upsert(index,mode) {
    setfMode(mode)
    setModalShow(true)

    if (index > -1) {
      const obj = mData[index]
      //const index = mData.findIndex(a => a.function_id == id)
      // setfId(obj.function_id)
      // setfName(obj.name)
      // setfDesc(obj.description)
      setfData(obj)
      setficonpath(obj.iconpath)
      setfName(obj.name)
    }
    else {
      setfData(initObj)
      setficonpath('')
      setfName('')
      setDefName()           
    }
         
    
  }

  function setDefName() {
    setTimeout(function(){
      var sel = window.document.getElementsByName("selContent")
      if(sel.length > 0) {
        sel = sel[0]
        var text = sel.options[sel.selectedIndex].text;
        setfName(text)
      }
    }, 500)
  }

  function save(event) {
    event.preventDefault();
    console.log(event.target.iName.value)
   

    if (event.target.iName.value === '') {
      Swal.fire({
        title: '請輸入名稱',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: '確定'
      }
      )
      return
    }

    if (event.target.iFile.files.length > 0) {
      if (event.target.iFile.files[0].name.indexOf('.jpg') == -1
        && event.target.iFile.files[0].name.indexOf('.png') == -1) {
        Swal.fire({
          title: '請上傳圖檔(.jpg或.png)',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: '確定'
        })
        return;
      }
      if (event.target.iFile.files[0].size > 204800) {
        Swal.fire({
          title: '上傳檔案大小請勿超過200KB',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: '確定'
        })
        return;
      }
    }

    
    let sUrl = sysConfig.apiUrl + 'Menu'
    let sMethod = 'post'

    let sData = {
      name: event.target.iName.value,
      ename: event.target.iEName.value,
      content_dictionary: [event.target.selContent.value],
      icon: 'def',
      position: tab,
      iconpath: ficonpath,
      mode: event.target.selMode.value
    }


    if (fData.menu_id != '') {
      sUrl += '/' + fData.menu_id
      sMethod = 'put'
    }

    axios({
      method: sMethod,
      url: sUrl,
      data: sData
    })
      .then((response) => {
        console.log(response.data);

        // upload file
        if (event.target.iFile.files.length > 0) {
          let uid = fData.menu_id
          if (sMethod == 'post') uid = response.data;

          const formData = new FormData();
          formData.append('File', event.target.iFile.files[0]);

          let fUrl = sysConfig.apiUrl + 'Menu/UploadFile?id=' + uid

          axios.post(fUrl, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            }
          })
            .catch((error) => {
              console.log(error)
            })
            .then(() => {
              setModalShow(false)
              getdata(tab)
            })
        } else {
          setModalShow(false)
          getdata(tab)
        }


      })
      .catch((error) => {
        console.log(error)
      })

  }

  function del(index) {

    Swal.fire({
      title: '確定刪除這筆資料',
      //text: "確定刪除這筆資料?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '確定',
      cancelButtonText: '取消'
    }).then((result) => {
      if (result.isConfirmed) {
        const obj = mData[index]
        let sUrl = sysConfig.apiUrl + 'Menu/' + obj.menu_id
        let sMethod = 'delete'

        const ndata = mData.filter(item => item.menu_id !== obj.menu_id)
        setmData(ndata)

        axios({
          method: sMethod,
          url: sUrl
        })
          .then((response) => {
            console.log(response.data);
            getdata(tab)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    })

    //if(window.confirm('確定刪除?') === false) return


  }

  function changeMode(e) {
    const mode = e.target.value;   
    setfMode(mode)
    setDefName()
  }

  let sValue = ''
  function UpsertModal(props) {

    //var sel = window.document.getElementsByName("selMode")
      //var text = sel.options[sel.selectedIndex].text;

    if (fData.content_dictionary.length > 0) {
      sValue = fData.content_dictionary[0].content_id
    }
    setfId(sValue)   
    
    if (fData.menu_id == '') {
      //setfMode('link')      
    }

    function chgFun(event) {
     
      const name = event.target.selectedOptions[0].label
      window.document.getElementsByName("iName")[0].value = name      
      
      //alert(event.target.value)
      // if(event.target.value == '08db0a62-58a3-4bdc-8dc4-fc5faf1ae07b')
      // {
      //   setshowTagSelect(true)
      // } 
      // else {
      //   setshowTagSelect(false)
      // }
    }

    function delIcon() {
      setficonpath('')
    }

    const menuSel = (
      <>
        {
          fMode === 'fun' ?
            <div className="col-md-10"  >
              <label className="form-label">選擇功能</label>
              <select id="inputState" onChange={chgFun} name="selContent" className="form-select" defaultValue={sValue}>
                {
                  sFun.map((s) => (
                    <option value={s.function_id}>{s.name}</option>
                  ))
                }
              </select>
            </div>
            :
            <></>
        }
        {
          fMode == 'link' ?
            <div className="col-md-10">
              <label className="form-label">選擇連結</label>
              <select id="inputState" name="selContent" className="form-select" defaultValue={sValue}>
                {
                  sLink.map(s => (
                    <option value={s.link_id}>{s.name}</option>
                  ))
                }
              </select>
            </div>
            :
            <></>
        }
        {
          fMode == 'page' ?
            <div className="col-md-10">
              <label className="form-label">選擇頁面</label>
              <select id="inputState" name="selContent" className="form-select" defaultValue={sValue}>
                {
                  sPage.map(s => (
                    <option value={s.id}>{s.name}</option>
                  ))
                }
              </select>
            </div>
            :
            <></>
        }
      </>
    )

    //setOpen(props.show)
    isOpen = props.show
    console.log(isOpen)

    return (
      <Modal
        {...props}
        size="lg"
        backdrop="static"
        keyboard="false"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form onSubmit={save}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {pageName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="row g-3">
              <div className="col-md-2">
                <label className="form-label">類型</label>
                <select id="selMode" name="selMode" 
                    className="form-select" onChange={changeMode} defaultValue={fMode}>
                  <option value="link" >連結</option>
                  <option value="page" >頁面</option>
                  <option value="fun"  >功能</option>
                </select>
              </div>
              {/* {menuSel} */}
              <div className="col-md-10"  >
                <label className="form-label">選擇功能</label>
                <select id="inputState" onChange={chgFun} name="selContent" className="form-select" defaultValue={sValue}>
                  {
                    fMode == 'link' ?
                    sLink.map((s) => (
                      <option value={s.link_id}>{s.name}</option>
                    ))
                    :
                    <></>                    
                  }
                  {
                    fMode == 'page' ?
                    sPage.map((s) => (
                      <option value={s.id}>{s.name}</option>
                    ))
                    :
                    <></>                    
                  }
                  {
                    fMode == 'fun' ?
                    sFun.map((s) => (
                      <option value={s.function_id}>{s.name}</option>
                    ))
                    :
                    <></>                    
                  }
                </select>
              </div>

             
              <div className="col-md-4">
                <label className="form-label">顯示名稱</label>
                <input type="text" defaultValue={fName} className="form-control" name="iName" />
              </div>
              <div className="col-md-8">
                <label className="form-label">顯示英文名稱</label>
                <input type="text" defaultValue={fData.ename} className="form-control" name="iEName" />
              </div>

              <div className="col-md-12">
                <label className="form-label">上傳圖示</label>
                <input type="file" className="form-control" name="iFile" accept=".png,.jpg" />
              </div>

              <div className="col-md-12">
                <label className="form-label">圖示</label>
                <input type="hidden" name="iIconPath" value={ficonpath} />
                {
                  ficonpath == null || ficonpath == '' ?
                    <img className="defImg" src={"./img/def_"+fData.mode+".svg"} title="預設圖示" style={{ width: '100px', marginLeft: '10px' }} />
                    :
                    <span className=" position-relative">
                      <img src={sysConfig.imgUrl + 'menu/' + ficonpath} style={{ width: '100px', marginLeft: '10px' }} />
                      <span title="使用者預設圖示" onClick={() => delIcon()} style={{ cursor: 'pointer' }} className="position-absolute  top-0 start-10 translate-middle badge rounded-pill bg-danger">刪除</span>
                    </span>
                }
              </div>

              {
                showTagSelect ?
                  <div className="col-md-12">
                    <label className="form-label">連結分類</label>
                    <CreatableSelect
                      defaultValue={[]}
                      isMulti
                      name="iGroup"
                      options={[]}
                      noOptionsMessage={() => "無項目"}
                      placeholder={"請選擇項目"}
                      formatCreateLabel={(inputText) => "新增: " + inputText}
                      className="basic-multi-select"
                      //onChange={handleChange} 
                      classNamePrefix="select"
                    />
                  </div>
                  :
                  <></>
              }

            </div>

          </Modal.Body>
          <Modal.Footer>
            <input type="submit" name="submit" className="btn btn-primary" value="儲存" />
            <Button variant="secondary" onClick={props.onHide}>取消</Button>

          </Modal.Footer>
        </form>
      </Modal>
    );
  }



  useEffect(() => {
    getdata(tab)
    console.log('tab', tab)    

  }, [sysConfig]);

  if (isLoading) {
    return (
      <Layout>
        <main id="main" className="main">Loading...</main>
      </Layout>
    )
  }

  function getMenuMode(code) {
    switch (code) {
      case 'fun': return '功能'
      case 'link': return '連結'
      case 'page': return '頁面'
    }
  }

  // const mTableBody = mData.map(
  //   (f, index) => (
  //     <tr key={f.link_id}>
  //       <td>{index+1}</td>
  //       <td>{f.name}</td>
  //       <td>{getMenuMode(f.mode)}</td>
  //       <td>
  //         <button className="btn btn-warning" onClick={() => upsert(index)}><i className="bi bi-pencil-square"></i></button>&nbsp;
  //         <button className="btn btn-danger" onClick={() => del(index)}><i className="bi bi-trash"></i></button>
  //       </td>
  //     </tr>
  //   )
  // )

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    console.log('handleOnDragEnd', result)
    setOrder(result.draggableId, result.destination.index + 1)
  }

  function setOrder(itemid, order) {
    let sUrl = sysConfig.apiUrl + 'Menu/SetOrder?pid=' + itemid + '&position=' + tab + '&order=' + order
    let sMethod = 'post'

    axios({
      method: sMethod,
      url: sUrl
    })
      .then((response) => {
        console.log(response.data);
        getdata(tab)
      })
      .catch((error) => {
        console.log(error)
      })
      .then(() => {

      })
  }

  const mTableBody = (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="mData">
        {(provided) => (
          <tbody {...provided.droppableProps} ref={provided.innerRef}>
            {mData.map((item, index) => {
              return (
                <Draggable className="table" key={item.menu_id} draggableId={item.menu_id + ""} index={index}>
                  {(provided) => (
                    <tr title="可使用拖放資料列的方式移動順序" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <td><i class="bi bi-grip-vertical"></i> {index + 1}</td>
                      <td>
                        {
                          item.iconpath == null || item.iconpath == '' ?
                            <img className="defImg" src={"./img/def_"+item.mode+".svg"} title="預設圖示" style={{ width: '35px', marginRight: '10px' }} />
                            :
                            <img src={sysConfig.imgUrl + 'menu/' + item.iconpath} style={{ width: '35px', marginRight: '10px' }} />
                        }
                        {item.name}
                      </td>
                      <td>{getMenuMode(item.mode)}</td>
                      <td>
                        <button className="btn btn-warning" onClick={() => upsert(index,item.mode)}><i className="bi bi-pencil-square"></i></button>&nbsp;
                        <button className="btn btn-danger" onClick={() => del(index)}><i className="bi bi-trash"></i></button>
                      </td>
                    </tr>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </tbody>
        )}
      </Droppable>
    </DragDropContext>
  )

  const mTable = (
    <table className="table">
      <thead>
        <tr>
          <th>序號</th>
          <th>名稱</th>
          <th>類型</th>
          <th>編輯</th>
        </tr>
      </thead>
      {mTableBody}
    </table>
  )



  const mBody = (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{pageName}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/">首頁</a></li>
              <li className="breadcrumb-item active">{pageName}</li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  {/* <h5 className="card-title">系統功能</h5> */}
                  <div className="row">
                    <div className="col-lg-3">
                      <h5 className="card-title">{tabName}{pageName}列表</h5>
                    </div>
                    <div className="col-lg-9">
                      <h5 className="card-title">
                        <button className="btn btn-secondary" onClick={() => changeTab('left')}> 左側選單</button>&nbsp;
                        <button className="btn btn-secondary" onClick={() => changeTab('top')}> 上方選單</button>&nbsp;
                        <button className="btn btn-secondary" onClick={() => changeTab('bottom')}> 下方選單</button>
                        <button className="btn btn-secondary float-end" onClick={() => upsert(-1, 'link')}><i className="bi bi-plus-circle"></i> 新增</button>
                      </h5>
                    </div>
                  </div>
                  {mTable}
                </div>
              </div>
              {/* <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="home" title="左側選單" onClick={index => setTab('left')}  >
                  
                </Tab>
                <Tab eventKey="profile" title="上方選單" onSelect={index => setTab('top')}>
                
                </Tab>
                <Tab eventKey="contact" title="下方選單" onSelect={s => setTab('bottom')}>
               
                </Tab>
              </Tabs> */}


            </div>
          </div>
        </section>
      </main>
    </>
  )


  return (
    <Layout>
      {mBody}
      <UpsertModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Layout>

  );

}
export default Menu