import React from "react"
import Layout from "../../common/Layout"
import { useContext, useState } from "react";
import { AppContext } from '../../App';
import axios from "axios";
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CourseDetail from './CourseDetail'
import { Link } from "react-router-dom";
import LayoutSc from './LayoutSc'
import { useTranslation } from "react-i18next";
import { checkLang } from "../../common/Util";
const Booking = () => {
  const { t, i18n } = useTranslation();
  const { sysConfig, showLoading } = useContext(AppContext)
  const [course, setCourse] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowCourseDetail, setModalShowCourseDetail] = React.useState(false);
  const [d_cobj, setd_cobj] = React.useState(null);
  const [sem, setSem] = useState([]);
  const [dep, setDep] = useState([]);
  const [courseDetail, setCourseDetail] = useState({ time: [], subject: [] });
  const [loading, openLoading] = useState(false);
  const [isbooking, setisbooking] = useState(false);
  const [isannounce, setisannounce] = useState(false);
  const [year, setyear] = useState()
  const [term, setterm] = useState()

  const queryParams = new URLSearchParams(window.location.search)
  const skey = queryParams.get("skey")
  let stid = localStorage.getItem('stid')

  checkLang()

  React.useEffect(() => {

    getSSOInfo()

  }, []);

  function getSSOInfo() {
    console.log('check login')
    if (window.getLoginInfo() == true) {
      stid = localStorage.getItem('stid')
      search()
      checkBooking();
    }
    else {
      if(window.location.href.indexOf('?') == -1 ) {
        window.loginSSO_Redirect()
      }
      
    }
  }

  function checkBooking() {
    let sUrl = sysConfig.apiUrl_IS + 'Booking/CheckBookingSchedule?studentid=' + stid
    //sUrl += '&termid=60'
    let sMethod = 'post'

    axios({
      method: sMethod,
      url: sUrl
    })
      .then((response) => {
        console.log('checkBooking', response.data);
        if (response.data.RESULT == 1) {
          setisbooking(true)
        }
        if (response.data.DATA == '1') {
          setisannounce(true)
        }
        
      })
      .catch((error) => {
        console.log(error)
      })
      .then(() => {

      })
  }

  function getSelData() {
    let endpoints = [
      sysConfig.apiUrl_IS + "Course/GetSemester",
      sysConfig.apiUrl_IS + "Course/GetDepartmentByCategory?categoryid=3"
    ];
    axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
      (data) => {
        console.log('all', data[0], data[1])
        setSem(data[0].data)
        setDep(data[1].data)
      }
    );
  }

  function add() {

    let sUrl = sysConfig.apiUrl_IS + 'Booking/CheckBookingReq?studentid=' + stid
    let sMethod = 'post'

    axios({
      method: sMethod,
      url: sUrl
    })
      .then((response) => {
        console.log('CheckBookingReq', response.data);
        if (response.data.RESULT == 1) {
          setModalShow(true)
        } 
        else 
        {
          Swal.fire({
            title: response.data.MESSAGE,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: t('確定')
          })          
        } 
        
      })
      .catch((error) => {
        console.log(error)
      })


    
  }

  function openCourseDetail(cobj) {

    let sUrl = sysConfig.apiUrl_IS + 'Course/GetCourseSections?sectionid='+cobj.SECTIONID
    let sMethod = 'get'

    axios({
      method: sMethod,
      url: sUrl
    })
      .then((response) => {
        console.log(response.data);
        setd_cobj(response.data[0])
        setModalShowCourseDetail(true)        
      })
      .catch((error) => {
        console.log(error)
      })

  }

  function addCourse() {
    var list = localStorage.getItem('bookingIds')
    console.log('addCourse', list)

    if (list == '' || list == null) {
      Swal.fire({
        title: t('請選擇課程'),
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: t('確定')
      })
      return
    }

    let sUrl = sysConfig.apiUrl_IS + 'Booking/AddBookingList?studentid=' + stid + '&sectionid=' + list
    let sMethod = 'post'


    axios({
      method: sMethod,
      url: sUrl
    })
      .then((response) => {
        console.log(response.data);
        setModalShow(false)
        search()
      })
      .catch((error) => {
        console.log(error)
      })

  }

  function orderBooking(itemid, direction) {
    let sUrl = sysConfig.apiUrl_IS + 'Booking/OrderBooking?itemid=' + itemid + '&direction=' + direction
    let sMethod = 'post'

    axios({
      method: sMethod,
      url: sUrl
    })
      .then((response) => {
        console.log(response.data);
        search()
      })
      .catch((error) => {
        console.log(error)
      })
      .then(() => {

      })
  }

  function del(item) {

    let disName = ""
    if (i18n.language == 'en') {
      disName = item.SECTIONID + " " + item.ECOURSENAME
    } else {
      disName = item.SECTIONID + " " + item.CCOURSENAME
    }

    Swal.fire({
      title: t('確定刪除這筆志願'),
      text: disName,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('確定'),
      cancelButtonText: t('取消')
    }).then((result) => {
      if (result.isConfirmed) {
        let sUrl = sysConfig.apiUrl_IS + 'Booking/DelBookingList?itemid=' + item.ITEMID
        let sMethod = 'post'

        openLoading(true)

        axios({
          method: sMethod,
          url: sUrl
        })
          .then((response) => {
            console.log(response.data);
            search()
          })
          .catch((error) => {
            console.log(error)
          })
          .then(() => {
            openLoading(false)
          })
      }
    })


  }

  function showDetail(index, itemid, sectionid) {
    console.log(index, itemid, sectionid)

    let endpoints = [
      sysConfig.apiUrl_IS + "Course/GetSectionTime?sectionid=" + sectionid,
      sysConfig.apiUrl_IS + "Course/GetSectionSchedules?sectionid=" + sectionid
    ];
    axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
      (data) => {
        console.log('all', data[0], data[1])
        let cobj = course[index]
        cobj.time = data[0].data[0]
        cobj.subject = data[1].data
        setCourseDetail(cobj)

        setModalShow(true)
      }
    );

  }

  let frameStyle = {
    top: 0, left: 0, bottom: 0, right: 0, border: 'none', margin: 0, padding: 0, overflow: 'hidden',
    width: '100%',
    height: window.innerHeight - 250 + 'px'
  }

  function DetailModal(props) {

    return (
      <Modal
        {...props}
        dialogClassName="modalStyle"
        keyboard="false"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form onSubmit="">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            {t('課程查詢')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="row">
              <iframe style={frameStyle} id="cframe" src="/course?booking=1"></iframe>
            </div>

          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <input type="button" onClick={() => addCourse()} className="btn btn-primary col-3" value={t('確定')} />
            <Button variant="secondary" onClick={props.onHide} className="col-3">{t('取消')}</Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  // function search(event) {
  //   event.preventDefault();
  //   getCourse(event.target.p_TERMID.value, event.target.p_DEPARTMENTID.value)
  // }

  function reset() {
    window.location.href = window.location.href
  }

  function search() {

    showLoading(true)

    axios.get(sysConfig.apiUrl_IS + 'Course/GetSemester').then((response) => {

      const res = response.data[0]
      console.log('GetSemester', res)
      setyear(res.ACADMICYEAR)
      setterm(res.ACADMICTERM)
      const tid = res.TERMID

      let param = '?termid='+tid+'&studentid=' + stid

      const apiUrl = sysConfig.apiUrl_IS + 'Booking/GetBookingList' + param

      axios.get(apiUrl).then((response) => {

        const res = response.data
        let arr = []
        res.forEach(element => {
          arr.push(element)
        });
        console.log('course', arr)
        setCourse(res)

      })
        .catch((error) => {
          console.log(error)
        }).then(() => {
          showLoading(false)
        })

    })
      .catch((error) => {
        console.log(error)
      }).then(() => {

      })


    


  }

  const bodyStyle = {
    height: '500px'
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    console.log('handleOnDragEnd', result)

    orderBookingSetIndex(result.draggableId, result.destination.index + 1)
  }

  function orderBookingSetIndex(itemid, order) {
    let sUrl = sysConfig.apiUrl_IS + 'Booking/OrderBookingSet?itemid=' + itemid + '&itemorder=' + order
    let sMethod = 'post'

    axios({
      method: sMethod,
      url: sUrl
    })
      .then((response) => {
        console.log(response.data);
        search()
      })
      .catch((error) => {
        console.log(error)
      })
      .then(() => {

      })
  }

  const mTableBody = (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="course">
        {(provided) => (
          <tbody {...provided.droppableProps} ref={provided.innerRef}>
            {course.map((item, index) => {
              return (
                <Draggable isDragDisabled={isbooking ? false : true} className="table" key={item.ITEMID} draggableId={item.ITEMID + ""} index={index}>
                  {(provided) => (
                    <tr title={isbooking?"可使用拖放資料列的方式移動順序":""} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      {
                        isbooking ?
                          <td><i class="bi bi-grip-vertical"></i> {index + 1}</td>
                          :
                          <td>{index + 1}</td>
                      }

                      <td>{item.SECTIONID}</td>
                      <td>{item.CALL_ID}</td>
                      <td>
                        <a href="javascript:void(0)" onClick={() => openCourseDetail(item)}>
                          {i18n.language == 'en' ? item.ECOURSENAME : item.CCOURSENAME} 
                          &nbsp;<span className="badge bg-secondary">{item.FIELDNAME != 'CANCELED' ? item.FIELDNAME : ''}</span>
                        </a>
                      </td>
                      {
                        isbooking ?
                          <>
                            <td>
                              <button title="移至最上方" className="btn btn-info m-1" onClick={() => orderBooking(item.ITEMID, 'top')}><i className="bi bi-arrow-bar-up"></i></button>
                              <button title="移至上方" className="btn btn-info m-1" onClick={() => orderBooking(item.ITEMID, 'up')}><i className="bi bi-arrow-up-circle"></i></button>
                              <button title="移至下方" className="btn btn-warning m-1" onClick={() => orderBooking(item.ITEMID, 'down')}><i className="bi bi-arrow-down-circle"></i></button>
                              <button title="移至最下方" className="btn btn-warning m-1" onClick={() => orderBooking(item.ITEMID, 'bottom')}><i className="bi bi-arrow-bar-down"></i></button>
                            </td>
                            <td>
                              <button className="btn btn-danger" onClick={() => del(item)}><i className="bi bi-trash"></i></button>
                            </td>
                          </>
                          :
                          <></>
                      }
                      {
                        isannounce ? 
                          <>
                            <td>
                              {item.RESULT == '1' ? t('成功') : ''}
                              {item.RESULT == '0' ? t('失敗') : ''}
                            </td>
                            <td>{item.PROCMSG}</td>
                          </>
                          :
                          <></>
                      }


                    </tr>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </tbody>
        )}
      </Droppable>
    </DragDropContext>
  )

  let tdColName = ""
  tdColName += '.m-table td:nth-of-type(1):before {content : "'+ t("志願序") +'"}'
  tdColName += '.m-table td:nth-of-type(2):before {content : "'+ t("開課序號") +'"}'
  tdColName += '.m-table td:nth-of-type(3):before {content : "'+ t("科目代號") +'"}'
  tdColName += '.m-table td:nth-of-type(4):before {content : "'+ t("課程名稱") +'"}'
  if(isbooking) {
    tdColName += '.m-table td:nth-of-type(5):before {content : "'+ t("調整順序") +'"}'
    tdColName += '.m-table td:nth-of-type(6):before {content : "'+ t("刪除") +'"}'
  }
  if(isannounce) {
    tdColName += '.m-table td:nth-of-type(5):before {content : "'+ t("結果") +'"}'
    tdColName += '.m-table td:nth-of-type(6):before {content : "'+ t("說明") +'"}'
  }

  
  const mTable = (
    <table className="table m-table">
      <style>{tdColName}</style>
      <thead>

        <tr>
          <th nowrap="nowrap">{t("志願序")}</th>
          <th nowrap="nowrap">{t("開課序號")}</th>
          <th nowrap="nowrap">{t("科目代號")}</th>
          <th nowrap="nowrap">{t("課程名稱")}</th>
          {
            isbooking ?
              <>
                <th nowrap="nowrap">{t("調整順序")}</th>
                <th nowrap="nowrap">{t("刪除")}</th>
              </>
              :
              <></>
          }
          {
            isannounce ?
              <>
                <th nowrap="nowrap">{t("結果")}</th>
                <th nowrap="nowrap">{t("說明")}</th>
              </>
              :
              <></>
          }

        </tr>
      </thead>
      <>
        {
          course.length > 0 ?
            (
              <>{mTableBody}</>
            )
            :
            <tbody>
              <tr><td align="center" colSpan={10}>{t("無資料")}</td></tr>
            </tbody>
        }

      </>
    </table>
  )

  function formatTerm(term) {
    if(i18n.language == 'en') {
      switch(term) {
        case '1':
          term = 'FALL'
          break
        case '2':
          term = 'SPRING'
          break
        case '3':
          term = 'SUMMER'
          break          
      }
    } 
    return term
  }

  function formatYear(year) {
    if(i18n.language == 'en') {
      year = year + 1911
    } 
    return year
  }
  
  const Main = (
    <>
      <main>
        <div className="container">
          <section className="section  min-vh-100 d-flex flex-column py-4" >


            <div className="container">

              <div class="card" style={{ display: 'none' }}>
                <div class="card-body">
                  <h5 class="card-title">
                    {t("課程預選")}
                  </h5>
                  <form class="row g-3" onSubmit={search}>
                    <div class="col-md-6">
                      <div class="form-floating">
                        {/* <input type="text" class="form-control" name="p_TERMID" placeholder="開課學期"/> */}
                        <select class="form-select" name="termid" aria-label="State">
                          {
                            sem.map(item => (
                              <option value={item.TERMID}>{item.CTERMNAME}</option>
                            ))
                          }
                        </select>
                        <label for="">開課學期 Semester</label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-floating">
                        {/* <input type="text" class="form-control" name="p_DEPARTMENTID" placeholder="開課單位"/> */}
                        <select class="form-select" name="departmentid" aria-label="State">
                          <option value="">- 請選擇 -</option>
                          {
                            dep.map(item => (
                              <option value={item.DEPARTMENTID}>{item.CHTDEPNAME} {item.ENGDEPALIAS}</option>
                            ))
                          }
                        </select>
                        <label for="">開課單位 Department</label>
                      </div>
                    </div>




                    <div className="row">
                      <div className="col-lg-12 text-center py-2">

                        {/* <input type="submit" name="submit" className="btn btn-primary " value="搜尋"  /> */}
                        <button className="btn btn-primary m-1" type="submit"><i className="bi bi-search"></i> 搜尋</button>
                        <button className="btn btn-secondary m-1" onClick={() => reset()}><i className="bi bi-x"></i> 清除</button>
                      </div>
                    </div>

                  </form>


                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">
                      {formatYear(year)} {t("學年度")} {t("第")}{formatTerm(term)} {t("學期")} <span className="m-br" /> [{t("課程預選")}{ isannounce? t("分發結果") : "" }]
                        {
                          isbooking ?
                            <button onClick={() => add()} className="btn btn-secondary float-end"><i className="bi bi-plus-circle"></i> {t("新增志願")}</button>
                            :
                            <span className=" text-danger float-end"><h6>※ {t("非預選期間")} ※</h6></span>
                        }
                      </h5>
                      {
                        isannounce ?
                        <div class="text-danger m-2">
                          <ul className="ul-1">
                          <li>{t("book_desc1")}</li>
                          <li>{t("book_desc2")}</li>
                          <li>{t("book_desc3")}</li>
                          </ul>
                        </div>
                        :
                        <></>
                      }                                        
                      {mTable}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>
        </div>
      </main>
    </>
  )

  return (
    <LayoutSc>
      {Main}


      <DetailModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <CourseDetail
        cobj={d_cobj}
        show={modalShowCourseDetail}
        onHide={() => setModalShowCourseDetail(false)}
      />

    </LayoutSc>

  )
}

export default Booking