import React from "react"
import Layout from "../common/Layout"
import { useState, useEffect, useContext } from 'react'
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AppContext } from '../App';
import Swal from 'sweetalert2'
import {useLocation} from 'react-router-dom';
import zipcelx from 'zipcelx';
import Moment from 'moment'
const Report = () => {

  const { sysConfig } = useContext(AppContext) 
  const [loading, openLoading] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [isLoading, setLoading] = useState(true);
  const [functions, setFuntions] = useState([]);
  const [cols, setCols] = useState([]);
  let isOpen = false

  const [fId, setfId] = useState()
  const [fName, setfName] = useState()
  const [fDesc, setfDesc] = useState()

  const [fData, setfData] = useState({
    function_id: "",
    name: "",
    description: ""
  });

  function exportReport() {

    let earr = []
    
    var crr = [{value: '名稱'}]
    cols.forEach(col => {
      crr.push({value: col})
    });

    earr.push(crr)    
    
    functions.forEach(e => {
      let edata = []
      var obj = {value: e.name, type: 'string' }      
      edata.push(obj)

      e.nums.forEach(num => {
        edata.push({value: num, type: 'number'})
      });

      earr.push(edata)
    });

    const config = {
      filename: 'report-' + Moment().format('yyyy-MM-DD_HHmmss') ,
      sheet: {
        data: earr,
        cols: [{ width: 150 }, { width: 60 }] 
      }
    };
    
    zipcelx(config);

  }

  function getdata(key) {
    openLoading(true)
    const apiUrl = sysConfig.apiUrl + "OpLog/ReportMonth"
    
    axios.get(apiUrl).then(response => {
      setTimeout(() => {
        setFuntions(response.data);
        console.log('ReportMonth',response.data)
        setLoading(false);
        openLoading(false)

        setCols(response.data.cols)
        setFuntions(response.data.rows)
        // test
        // let tdata = [{name: "fun1", nums: [553,234,221]}, {name: "fun2", nums: [332,234,221]}];
        // setFuntions(tdata)
        // setCols(['2023/4','2023/2','2023/3'])

        window.document.getElementsByName("query")[0].value = key

      }, 1);
      
    });
  }

  function test() {
    setfName('ff')
  }

  function upsert(index) {   
    setModalShow(true)
    if(index > -1) {
      const obj = functions[index]
      //const index = functions.findIndex(a => a.function_id == id)
      setfId(obj.function_id)
      setfName(obj.name)
      setfDesc(obj.description)
      setFuntions(functions)
    } 
    else {
      setfId('')
      setfName('')
      setfDesc('')
    }
  }

  function save(event) {
    event.preventDefault();
    console.log(event.target.iName.value)

    if(event.target.iName.value === '') {
      Swal.fire({
        title: '請輸入名稱',     
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: '確定'
      })
      return
    }
    
    let sUrl = sysConfig.apiUrl + 'Function'
    let sMethod = 'post'

    let sData = {    
      name: event.target.iName.value,
      description: event.target.iDesc.value
    }
    

    if(fId != '') {
      sUrl += '/' + fId
      sMethod = 'put'
    }

    axios({
      method: sMethod,
      url: sUrl,
      data: sData
    })
    .then((response) => {
        console.log(response.data);
        setModalShow(false)
        getdata()
    })
    .catch((error) => {
        console.log(error)
    })

  }

  function del(index) {

    Swal.fire({
      title: '確定刪除這筆資料',
      //text: "確定刪除這筆資料?",
      icon: 'warning',
      showCancelButton: true,    
      confirmButtonText: '確定',
      cancelButtonText: '取消'
    }).then((result) => {
      if (result.isConfirmed) {
        const obj = functions[index]
        let sUrl = sysConfig.apiUrl + 'Function/' + obj.function_id
        let sMethod = 'delete'   
        
        const ndata = functions.filter(item => item.function_id !== obj.function_id)
        setFuntions(ndata)

        axios({
          method: sMethod,
          url: sUrl
        })
        .then((response) => {
            console.log(response.data);                     
            getdata()
        })
        .catch((error) => {
            console.log(error)
        })
      }
    })

    //if(window.confirm('確定刪除?') === false) return
    
    
  }

  function UpsertModal(props) {
    //setOpen(props.show)
    isOpen = props.show
    console.log(isOpen)
    return (
      <Modal
        {...props}
        size="lg"
        backdrop="static"
        keyboard="false"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form  onSubmit={save}>      
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            系統功能
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <div className="row g-3">
            <div className="col-md-4">
              <label   className="form-label">名稱</label>
              <input type="text" defaultValue={fName} className="form-control" name="iName"/>
            </div>
            <div className="col-md-8">
              <label   className="form-label">描述</label>
              <input type="text" defaultValue={fDesc} className="form-control" name="iDesc"/>
            </div>               
          </div>
          
        </Modal.Body>
        <Modal.Footer>
        <input type="submit" name="submit" className="btn btn-primary" value="儲存"  />
        <Button variant="secondary" onClick={props.onHide}>取消</Button>
        
        </Modal.Footer>
        </form>
      </Modal>
    );
  }

  const param = useLocation() 
   

  useEffect(() => {       
    
    console.log('key', param.state)    

    getdata(param.state)
    
  }, [sysConfig]);

  if (isLoading) {
    return (
      <Layout>
        <main id="main" className="main">Loading...</main>
      </Layout>
    )
  }

  function upd() {
    alert('upd')
  }
  
  const skey = btoa(localStorage.getItem('LoginId').split('@')[0])

  const mTableBody = functions.map(
    (f,index) => (
      <tr>       
        <td  className="text-center">
          {
            f.mode == 'fun'? 
            <span className="badge bg-primary m-1">功能</span> 
            :          
            <span className="badge bg-secondary m-1">連結</span>           
          }
        </td>
        <td className="m-10">{f.name}</td>   
        {
          f.nums.map((num) => (
              <td className="text-center">{num}</td>
          ))
        }    
        
      </tr>
    )
  )
  const mTable = (
    <table className="table">
      <thead>
        <tr>          
          <th className="text-center">類型</th>   
          <th className="text-center">名稱</th>   
          {
            cols.map((col) => (
              <th className="text-center">{col}</th>  
            ))
          }
                       
        </tr>
      </thead>
      <tbody>
        {mTableBody}
      </tbody>
    </table>
  )
  const mBody = (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>統計報表</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/">首頁</a></li>
              <li className="breadcrumb-item active">統計報表</li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  {/* <h5 className="card-title">系統功能</h5> */}
                  <div className="row">
                    <div className="col-lg-6">
                      <h5 className="card-title">系統功能瀏覽人次分析</h5>
                    </div>
                     <div className="col-lg-6">
                      <h5 className="card-title">                   
                        <button className="btn btn-success float-end" onClick={() => exportReport()}><i  className="bi bi-file-earmark-spreadsheet"></i> 匯出</button>
                      </h5>                      
                    </div> 
                  </div>
                  {mTable}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )


  return (
    <Layout>
       
       {mBody}
       <UpsertModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
       
    </Layout>
    
  );

  //const mTableBody = users.map(users => <tr key={users.id}><td>{users.id}</td><td>{users.name}</td></tr>)
       

    const Main = (
        <>
          <main id="main" className="main">
            <div className="pagetitle">
              <h1>Dashboard</h1>
              <table>
                {}
              </table>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                  <li className="breadcrumb-item active">Dashboard</li>
                </ol>
              </nav>
            </div>
            <section className="section dashboard">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Default Table</h5>
                     
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )
    
    return (
        <>
        <Layout>
        {Main}
        </Layout>        
        </>
        
    )
}

export default Report