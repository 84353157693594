
import { useState, useEffect } from 'react'
import { useNavigate, useLocation, useRoutes } from 'react-router-dom'

const Logout = () => {


  localStorage.removeItem("token")
  localStorage.removeItem("role")   
  localStorage.removeItem("userId")  
  localStorage.removeItem("LoginId")    
  localStorage.removeItem("userName")   

  useEffect(() => {

    if(window.getIsSSOLogin() == true) 
    {
        window.logoutSSO()
    } 
    else 
    {
      window.location.href = "/"
    }
    
    
    //return navigate("/")
  }, [])

  //return navigate("/login")
  //return <div>Logout</div>
}

export default Logout;