import { useTranslation } from "react-i18next";
import React from "react"
import { useContext, useState } from "react";
import axios from "axios";
import { AppContext } from '../App';
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import { getSkey, getToken, trans, AddLog } from './Util'

function chgSiderBar() {
    if (document.body.className === 'toggle-sidebar')
        document.body.className = ''
    else
        document.body.className = 'toggle-sidebar'
}

function Logout() {

    localStorage.removeItem("token")
    localStorage.removeItem("role")
    localStorage.removeItem("userId")
    localStorage.removeItem("LoginId")
    localStorage.removeItem("userName")
    localStorage.removeItem("stid")
    localStorage.removeItem("style")
    localStorage.removeItem("i18n")       
    
    window.logoutRe()

    if (window.getIsSSOLogin() == true) {
        window.logoutSSO()        
    }
    else {
        window.location.href = "/"
    }
}

const HeaderCgu = (props) => {
    
    const { t, i18n } = useTranslation();
    const userStr = localStorage.getItem('userName')
    const userName = localStorage.getItem('userName')
    const userId = localStorage.getItem('LoginId')

    console.log('---header')
    const defMenu = []
    const { sysName, setSysName, sysConfig, favorite, setFavorite, isLogin, setIsLogin } = useContext(AppContext)
    const [menu, setMenu] = useState(defMenu)
    const [user, setUser] = useState(null)
    

    let navigate = useNavigate();

    React.useEffect(() => {

        if (window.getIsSSOLogin() == true) {
            tokenSSO()
        }
        else {
            checkAuth()
        }
       

    }, []);

    function getTopMenu() {
        let apiUrl = sysConfig.apiUrl + 'Menu/top?userId=' + userId
        if(userId == null)  {
            apiUrl = sysConfig.apiUrl + 'Menu/Guest?position=top' 
        }
        axios.get(apiUrl,{
            headers: {'token': localStorage.getItem('token')}
        }).then((response) => {

            console.log('menu', response.data)
            const menus = response.data
            let mrr = []

            for (var i in menus) {
                var obj = menus[i]
                var mobj = {}
                if (obj.mode == 'fun') {
                    mobj = {
                        id: obj.menu_id,
                        cid: obj.content_dictionary[0].content_id,
                        name: trans(i18n.language, obj.name, obj.ename),
                        mode: obj.mode,
                        path: obj.content_dictionary[0].content + getSkey(),
                        icon: "bi-grid",
                        iconpath: obj.iconpath,
                        out: true,
                        sub: null
                    }
                }
                if (obj.mode == 'link') {
                    mobj = {
                        id: obj.menu_id,
                        cid: obj.content_dictionary[0].content_id,
                        name: trans(i18n.language, obj.name, obj.ename),
                        mode: obj.mode,
                        path: obj.content_dictionary[0].content,
                        icon: "bi-link",
                        iconpath: obj.iconpath,
                        out: true,
                        sub: null
                    }
                }
                if (obj.mode == 'page') {
                    mobj = {
                        id: obj.menu_id,
                        cid: obj.content_dictionary[0].content_id,
                        mode: obj.mode,
                        name: trans(i18n.language, obj.name, obj.ename),
                        path: '/pageshow?id=' + obj.content_dictionary[0].content_id,
                        icon: "bi-card-list",
                        iconpath: obj.iconpath,
                        out: false,
                        sub: null
                    }
                }
                mrr.push(mobj)
            }

            setMenu(mrr)            

        });
    }

    function tokenSSO() {

        let sUrl = sysConfig.apiUrl + 'Auth/TokenSSO'
        let sMethod = 'post'
        let param = {
            "uId": localStorage.getItem('LoginId'),
            "uName": localStorage.getItem('userName'),
            "uRole": "user",
            "token": getToken()
        }

        if (param.uId.indexOf('@') == -1) return
        axios({
            method: sMethod,
            data: param,
            url: sUrl
        })
            .then((response) => {
                console.log('tokenSSO', response.data)
                if (response.data.code == 200) {

                    let token = response.data.tnToken.tokenStr
                    localStorage.setItem('token', token)

                    if(props.body != 'sc') {
                        axios.defaults.headers.common['token'] = `${token}`;
                    }

                    localStorage.setItem('userId', response.data.data.user.id)
                    localStorage.setItem('dep', response.data.data.user.dep)

                    var user = response.data.data.user
                    var rids = ''
                    user.roleList.forEach(item => {        
                        rids += item.id + ','
                    });
                    localStorage.setItem('role', rids)

                    getFavorite()
                    setIsLogin(true)                    

                } else {
                    console.log('tokenSSO Error', response.data)
                }
                getTopMenu()

            })
            .catch((error) => {
                console.log(error)
            })

    }

    function getUser(id) {

        let sUrl = sysConfig.apiUrl + 'User?id=' + id
        let sMethod = 'get'


        axios({
            method: sMethod,
            headers: {'token': localStorage.getItem('token')},
            url: sUrl
        })
            .then((response) => {
                console.log('getUser', response.data)
                const uobj = response.data[0]
                setUser(response.data[0])
                let isreload = false
                if (uobj.lang != localStorage.getItem('i18n')) {
                    isreload = true  
                    localStorage.setItem('i18n', uobj.lang)                    
                }
                if (uobj.style != localStorage.getItem('style')) {
                    isreload = true    
                    localStorage.setItem('style', uobj.style)
                }
                if (isreload) window.location.reload()
            })
            .catch((error) => {
                console.log(error)
            })

    }

    function checkAuth() {
        const token = localStorage.getItem('token')
        if (token == null) {
            getTopMenu()
            return
        } 
        let sUrl = sysConfig.apiUrl + 'Auth/ValiToken?tokenStr=' + token
        let sMethod = 'get'
        axios({
            method: sMethod,
            headers: {'token': localStorage.getItem('token')},
            url: sUrl
        })
            .then((response) => {
                console.log('ValiToken', response.data)
                if (response.data.code == 200) {
                    getFavorite()
                } else {
                    console.log('ValiToken Error', response.data)
                }
                getTopMenu()
            })
            .catch((error) => {
                console.log(error)
            })

    }

    function updUserExt(lang, style) {
        const uid = localStorage.getItem('userId')  
        
        if(uid == null) {
            var isreload = false
            if(lang != '') {
                localStorage.setItem('i18n',lang)
                isreload = true
            }
            if(isreload) {
                window.location.reload()
            }
        }

        let sUrl = sysConfig.apiUrl + 'User/UpdExt?id=' + uid +'&lang='
                    +lang+'&style='+style
        let sMethod = 'post'
        axios({
            method: sMethod,
            headers: {'token': localStorage.getItem('token')},
            url: sUrl
        })
            .then((response) => {
                console.log('updUserExt', response.data)
                getUser(uid)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    function getFavorite() {
        //event.preventDefault();
        //alert('add ' + id)

        const uid = localStorage.getItem('userId')
        getUser(uid)

        let sUrl = sysConfig.apiUrl + 'Favorite?userId=' + uid
        let sMethod = 'get'
        axios({
            method: sMethod,
            headers: {'token': localStorage.getItem('token')},
            url: sUrl
        })
            .then((response) => {
                console.log('header getFavorite', response.data)
                setFavorite(response.data)

            })
            .catch((error) => {
                console.log(error)
            })

    }

    function delFavorite(c_id) {
        //event.preventDefault();
        //alert('add ' + id)

        let sUrl = sysConfig.apiUrl + 'Favorite'
        let sMethod = 'delete'

        const uid = localStorage.getItem('userId')

        let sData = {
            userId: parseInt(uid),
            contentId: c_id
        }

        axios({
            method: sMethod,
            headers: {'token': localStorage.getItem('token')},
            url: sUrl,
            data: sData
        })
            .then((response) => {
                console.log(response.data);
                getFavorite()

                let msg = t("已刪除常用功能")

                Swal.fire({
                    title: msg,
                    icon: 'success',
                    showCancelButton: false,
                    timer: 2000,
                    showConfirmButton: false,
                    confirmButtonText: '確定'
                })

            })
            .catch((error) => {
                console.log(error)
            })

    }

    function updName(nn) {
        setSysName(nn)
    }

    function chgLang(lang) {
        localStorage.setItem("i18n", lang)
        window.location.reload(true)
    }

    function getSSOInfo() {
        if (window.getIsSSOLogin() == true) {
            setIsLogin(true)
        }
    }

    function goHome() {
        var reurl = sysConfig.homeUrl
        window.location.replace(reurl);
    }

    function search(event) {
        event.preventDefault()
        const key = event.target.query.value

        if (event.target.query.value === '') {
            Swal.fire({
                title: '請輸入功能名稱關鍵字',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: '確定'
            })
            return
        }

        navigate('/search', { state: key })


    }

    return (
        <>
        
            <header id="header" className="header fixed-top d-flex align-items-center">                
            {/* {
                            props.body == 'sc' ?
                                // <li>
                                //     <a className="dropdown-item d-flex align-items-center" href="javascript:void(0)" onClick={() => goHome()} >
                                //         <i className="bi bi-house-fill"></i> <span>{t("首頁")}</span>
                                //     </a>
                                // </li>
                                <button className="btn btn-primary btn-sm m-1 scHome" onClick={() => goHome()}><i className="bi bi-house-fill"></i> {t("首頁")}</button>
                            :
                            <></>
                        } */}
                
                <img className="cgu-logo-m" onClick={() => window.location.href = ('/')} src="./img/cgu-logo3.png" />
                <nav className="header-nav ms-auto">
                    
                    <ul className="d-flex align-items-center">
                        {
                            props.body != 'sc' ?
                            <li>
                                <div className="search-bar">
                                    <form onSubmit={search} className="search-form d-flex align-items-center" method="POST" action="#">
                                        <input type="text" name="query" placeholder={t("請輸入連結、功能名稱")} />
                                        <button type="submit" title="Search"><i className="bi bi-search"></i></button>
                                    </form>
                                </div>
                            </li>
                            :
                            <></>
                        }
                        {
                            props.body == 'sc' ?
                                    <li className="nav-item pe-3">
                                        <a onClick={() => goHome()} href='javascript:void(0)'  className="btn-top-person nav-link nav-profile d-flex align-items-center pe-0" >
                                            <i className={"bi-house-fill"}></i>
                                            <span className="d-none d-md-block ps-2">{t("首頁")}</span>
                                        </a>
                                    </li>
                            :
                            <></>
                        }                            
                        
                        {/* <li className="nav-item d-block d-lg-none">
                        <a className="nav-link nav-icon search-bar-toggle " href="#">
                            <i className="bi bi-search"></i>
                        </a>
                    </li> */}
                        {
                            props.body != 'sc' ?
                            menu.map(menu => (

                                menu.out == false ?
                                    <li className="nav-item pe-3">
                                        <Link onClick={() => AddLog(sysConfig, menu.cid, menu.mode)} className="nav-link nav-profile d-flex align-items-center pe-0" to={menu.path}>
                                            <i className={"bi " + menu.icon + " defImg-top-menu"}></i>
                                            <span className="d-none d-md-block ps-2">{menu.name}</span>
                                        </Link>
                                    </li>
                                    :
                                    <li className="nav-item pe-3">
                                        <a onClick={() => AddLog(sysConfig, menu.cid, menu.mode)} className="btn-top av-link nav-profile d-flex align-items-center pe-0" href={menu.path} target="_blank" >
                                            {
                                                menu.iconpath == '' || menu.iconpath == null ?
                                                    <img className="defImg-top-menu" src={"./img/def_" + menu.mode + ".svg"} />
                                                    :
                                                    <img src={sysConfig.imgUrl + 'menu/' + menu.iconpath} className="icon-top-menu" />

                                            }
                                            {/* <i  className={"bi " + menu.icon}></i> */}
                                            <span className="d-none d-md-block ps-2">{menu.name}</span>
                                        </a>
                                    </li>


                            ))
                            :
                            <></>
                        }
                        
                        {
                            isLogin && props.body != 'sc' ?
                                <li className="nav-item dropdown pe-3">
                                    <a className="btn-top-person nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                        {/* <img src="assets/img/profile-img.jpg" alt="Profile" className="rounded-circle" /> */}
                                        <i className="bi bi-star-fill icon-top-menu"></i>
                                        <span className="d-none d-md-block dropdown-toggle ps-2">{t("常用功能")}</span>
                                    </a>

                                    {
                                        favorite.length > 0 ?
                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                                {
                                                    favorite.map(f => {
                                                        if (f.mode == 'link') {
                                                            return (
                                                                <li className="d-flex">
                                                                    <a onClick={() => AddLog(sysConfig, f.contentId, 'link')} className="dropdown-item d-flex align-items-center  inline-block" href={f.content} target="_blank">
                                                                        <i className="bi bi-link"></i>
                                                                        <span>{i18n.language == 'en' ? f.eName : f.name}</span>
                                                                    </a>
                                                                    <button className="btn btn-danger btn-sm m-2" onClick={() => delFavorite(f.contentId)}><i className="bi bi-trash"></i></button>
                                                                </li>
                                                            )
                                                        } else {
                                                            return (
                                                                <li>
                                                                    <a className="dropdown-item d-flex align-items-center" href={f.content} target="_blank">
                                                                        <i className="bi bi-grid"></i>
                                                                        <span>{f.name}</span>
                                                                    </a>
                                                                </li>
                                                            )
                                                        }

                                                    })
                                                }
                                            </ul>
                                            :
                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                                <li>
                                                    <a className="dropdown-item d-flex align-items-center" >
                                                        <span>無資料</span>
                                                    </a>
                                                </li>
                                            </ul>
                                    }
                                </li>
                                :
                                <div></div>
                        }
                        <li className="nav-item dropdown pe-3">
                            <a className="btn-top-person nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                {/* <img src="assets/img/profile-img.jpg" alt="Profile" className="rounded-circle" /> */}
                                <i className="bi bi-globe icon-top-menu"></i>
                                <span className="d-none d-md-block dropdown-toggle ps-2">
                                    {
                                        localStorage.getItem('i18n') == null || localStorage.getItem('i18n') == 'zh_tw' ?
                                            t("中文")
                                            :
                                            t("英文")
                                    }
                                    {/* {t("語言")} */}
                                </span>
                            </a>

                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                {
                                    localStorage.getItem('i18n') == null || localStorage.getItem('i18n') == 'zh_tw' ?
                                        <li>
                                            <a className="dropdown-item d-flex align-items-center" href="javascript:void(0);" onClick={() => updUserExt('en','')}>
                                                <i className="bi bi-translate"></i> <span>{t("英文")}</span>
                                            </a>
                                        </li>
                                        :
                                        <li>
                                            <a className="dropdown-item d-flex align-items-center" href="javascript:void(0);" onClick={() => updUserExt('zh_tw','')}>
                                                <i className="bi bi-translate"></i> <span>{t("中文")}</span>
                                            </a>
                                        </li>

                                }
                            </ul>
                        </li>
                        {
                            isLogin ?
                                <li className="nav-item dropdown pe-3">
                                    <a className="btn-top-person nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                        {/* <img src="assets/img/profile-img.jpg" alt="Profile" className="rounded-circle" /> */}
                                        <i className="bi bi-person-circle icon-top-menu"></i>
                                        <span className="d-none d-md-block dropdown-toggle ps-2">{userStr}</span>
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                        <li className="dropdown-header">
                                            <h6>{userName}</h6>
                                            <span>{userId}</span>
                                        </li>
                                        <li>
                                            <hr className="dropdown-divider" />
                                        </li>
                                        <li>
                                            <a className="dropdown-item d-flex align-items-center" href="https://myaccount.microsoft.com/" target="_blank">
                                                <i className="bi bi-person"></i>
                                                <span>{t("檢視帳戶")}</span>
                                            </a>
                                        </li>

                                        {
                                            localStorage.getItem('style') == 'def' ?
                                                <li>
                                                    <a onClick={() => updUserExt('','dark')} className="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                                                        <i className="bi bi-heptagon-fill"></i>
                                                        <span>{t("深色主題")}</span>
                                                    </a>
                                                </li>
                                                :
                                                <li>
                                                    <a onClick={() => updUserExt('','def')} className="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                                                        <i className="bi bi-heptagon"></i>
                                                        <span>{t("淺色主題")}</span>
                                                    </a>
                                                </li>
                                        }


                                        <li>
                                            <a className="dropdown-item d-flex align-items-center" href="javascript:void(0)" onClick={() => Logout()} >
                                                <i className="bi bi-box-arrow-left"></i>
                                                <span>{t("登出")}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                :
                                <>
                                    {/* <li className="nav-item pe-3">
                                        <Link className="btn-top nav-link nav-profile d-flex align-items-center pe-0" to="/login">
                                            <i className="bi bi-box-arrow-right icon-top-menu"></i>
                                            <span className="d-none d-md-block ps-2">{t("系統登入")}</span>
                                        </Link>
                                    </li> */}
                                    <li className="nav-item pe-3">
                                        <a className="btn-top nav-link nav-profile d-flex align-items-center pe-0" href="javascript:void(0)" onClick={() => window.loginSSO()} >
                                            <i className="bi bi-cloud-check icon-top-menu"></i>
                                            <span className="d-none d-md-block ps-2">{t("登入")}</span>
                                        </a>
                                    </li>
                                </>
                        }
                    </ul>

                </nav>


            </header>
            {
                props.body == 'sc' ? <div style={{height:50}}></div> : <></>
            }
            
        </>
    )
}
export default HeaderCgu