import React from "react"
import Layout from "../common/Layout"
import { useState, useEffect, useContext } from 'react'
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AppContext } from '../App';
import Swal from 'sweetalert2'
import { useTranslation } from "react-i18next";
import Moment from 'moment'
import { type } from "@testing-library/user-event/dist/type";

const LoginRecord = () => {
  const { t, i18n } = useTranslation();
  const { sysConfig } = useContext(AppContext)
  const [loading, openLoading] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [isLoading, setLoading] = useState(true);
  const [functions, setFuntions] = useState();
  let isOpen = false

  const [fId, setfId] = useState()
  const [fName, setfName] = useState()
  const [fDesc, setfDesc] = useState()
  const [fMode, setfMode] = useState()
  const [starttime, setstarttime] = useState()
  const [endtime, setendtime] = useState()

  const [fData, setfData] = useState({
    function_id: "",
    name: "",
    description: ""
  });

  function getdata() {
    openLoading(true)
    const apiUrl = sysConfig.apiUrl + "LoginRecord/report"

    let sData = {
      "startTime": starttime,
      "endTime": endtime + 'T23:59:59.999Z'
    }
    if(typeof(starttime) == 'undefined') return

    axios({
      method: 'post',
      url: apiUrl,
      data: sData
    })
      .then((response) => {
        setFuntions(response.data);
        console.log('LoginRecord', response.data)
        setLoading(false);
        openLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })

  }

  function test() {
    setfName('ff')
  }

  function updcontent(id) {
    window.location.replace('./pagecontent?id=' + id)
  }

  function upsert(index) {
    setModalShow(true)
    if (index > -1) {
      const obj = functions[index]
      //const index = functions.findIndex(a => a.function_id == id)
      setfId(obj.id)
      setfName(obj.name)
      setfDesc(obj.link)
    }
    else {
      setfId('')
      setfName('')
      setfDesc('')
    }
  }

  function save(event) {
    event.preventDefault();
    console.log(event.target.iName.value)

    if (event.target.iName.value === '') {
      Swal.fire({
        title: '請輸入名稱',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: '確定'
      })
      return
    }

    let sUrl = sysConfig.apiUrl + 'RssBlock'
    let sMethod = 'post'

    let sData = {
      name: event.target.iName.value,
      link: event.target.iDesc.value,
      lang: 'zh_tw'
    }

    if (fId != '') {
      //sUrl += '/' + fId
      sData.id = fId
      sMethod = 'put'
    }

    axios({
      method: sMethod,
      url: sUrl,
      data: sData
    })
      .then((response) => {
        console.log(response.data);
        setModalShow(false)
        getdata()
      })
      .catch((error) => {
        console.log(error)
      })

  }

  function del(index) {

    Swal.fire({
      title: '確定刪除這筆資料',
      //text: "確定刪除這筆資料?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '確定',
      cancelButtonText: '取消'
    }).then((result) => {
      if (result.isConfirmed) {
        const obj = functions[index]
        let sUrl = sysConfig.apiUrl + 'RssBlock/' + obj.id
        let sMethod = 'delete'

        const ndata = functions.filter(item => item.id !== obj.id)
        setFuntions(ndata)

        axios({
          method: sMethod,
          url: sUrl
        })
          .then((response) => {
            console.log(response.data);
            Swal.fire({
              title: '刪除成功',
              icon: 'success',
              showCancelButton: false,
              timer: 2000,
              confirmButtonText: '確定'
            })
            getdata()
          })
          .catch((error) => {
            console.log(error)
          })
      }
    })

    //if(window.confirm('確定刪除?') === false) return


  }

  function UpsertModal(props) {
    //setOpen(props.show)
    isOpen = props.show
    console.log(isOpen)
    return (
      <Modal
        {...props}
        size="lg"
        backdrop="static"
        keyboard="false"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form onSubmit={save}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              RSS管理
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="row g-3">
              <div className="col-md-4">
                <label className="form-label">名稱</label>
                <input type="text" defaultValue={fName} className="form-control" name="iName" />
              </div>
              <div className="col-md-8">
                <label className="form-label">連結</label>
                <input type="text" defaultValue={fDesc} className="form-control" name="iDesc" />

              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <input type="submit" name="submit" className="btn btn-primary" value="儲存" />
            <Button variant="secondary" onClick={props.onHide}>取消</Button>

          </Modal.Footer>
        </form>
      </Modal>
    );
  }



  useEffect(() => {

    const today = new Date();
    const numberOfDaysToAdd = 3;
    const date = today.setDate(today.getDate() + numberOfDaysToAdd); 
    const defaultValueS = new Date(date).toISOString().split('T')[0] 

    setstarttime(Moment().add(-30,'d').format('yyyy-MM-DD'))
    setendtime(Moment().format('yyyy-MM-DD'))

    getdata()
  }, [sysConfig]);

  if (isLoading) {
    return (
      <Layout>
        <main id="main" className="main">Loading...</main>
      </Layout>
    )
  }

  function upd() {
    alert('upd')
  }

  const mTableBody = functions.day.map(
    (f, index) => (
      <tr key={f.name}>
        <td>{f.name}</td>

        <td>
          {f.count}
        </td>
      </tr>
    )
  )
  const mTable = (
    <>
      <div className="row">
        <div className="col-lg-6">
          <h5 className="card-title">依日期統計</h5>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th className="w-50">日期</th>
            <th>人次</th>
          </tr>
        </thead>
        <tbody>
          {mTableBody}
        </tbody>
      </table>
    </>

  )

  const mTableHour = (
    <>
      <div className="row">
        <div className="col-lg-6">
          <h5 className="card-title">依時間統計</h5>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th className="w-50">時間</th>
            <th>人次</th>
          </tr>
        </thead>
        <tbody>
          {
            functions.hour.map(
              (f, index) => (
                <tr key={f.name}>
                  <td>{f.name}:00 ~ {f.name}:59</td>
          
                  <td>
                    {f.count}
                  </td>
                </tr>
              )
            )
          }
        </tbody>
      </table>
    </>
  )

  const mTableDevice = (
    <>
      <div className="row">
        <div className="col-lg-6">
          <h5 className="card-title">依裝置統計</h5>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th className="w-50">裝置</th>
            <th>人次</th>
          </tr>
        </thead>
        <tbody>
          {
            functions.device.map(
              (f, index) => (
                <tr key={f.name}>
                  <td>{f.name}</td>
          
                  <td>
                    {f.count}
                  </td>
                </tr>
              )
            )
          }
        </tbody>
      </table>
    </>
  )

  const mTableBrowser = (
    <>
      <div className="row">
        <div className="col-lg-6">
          <h5 className="card-title">依瀏覽器統計</h5>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th className="w-50">瀏覽器</th>
            <th>人次</th>
          </tr>
        </thead>
        <tbody>
          {
            functions.browser.map(
              (f, index) => (
                <tr key={f.name}>
                  <td>{f.name}</td>
          
                  <td>
                    {f.count}
                  </td>
                </tr>
              )
            )
          }
        </tbody>
      </table>
    </>
  )

  const mTableIp = (
    <>
      <div className="row">
        <div className="col-lg-6">
          <h5 className="card-title">依IP統計</h5>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th className="w-50">IP</th>
            <th>人次</th>
          </tr>
        </thead>
        <tbody>
          {
            functions.ip.map(
              (f, index) => (
                <tr key={f.name}>
                  <td>{f.name}</td>
          
                  <td>
                    {f.count}
                  </td>
                </tr>
              )
            )
          }
        </tbody>
      </table>
    </>
  )

  function search(event) {   
    event.preventDefault();
    let stime =  event.target.starttime.value
    let etime =  event.target.endtime.value
    setstarttime(stime)
    setendtime(etime)
    getdata()
  }

  const mBody = (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>到訪人次紀錄</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/">首頁</a></li>
              <li className="breadcrumb-item active">到訪人次紀錄</li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard">
          <form class="row" onSubmit={search}>
              <div class="col-6 col-md-3"><div class="form-floating">
                <input type="date" class="form-control" name="starttime"
                 defaultValue={starttime} onChange={(e) => {setstarttime(e.target.value)}}  />
                <label for="floatingEmail">開始時間</label></div>
              </div>
              <div class="col-6 col-md-3"><div class="form-floating">
                <input type="date" class="form-control" name="endtime" 
                defaultValue={endtime} onChange={(e) => {setendtime(e.target.value)}}  />
                <label for="floatingEmail">結束時間</label></div>
              </div>
              <div class="col-6 col-md-2 mt-2">
                <button class="btn btn-primary" type="submit">
                <i class="bi bi-search"></i> 查詢</button>
              </div>
          </form>
          <br/>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  {/* <h5 className="card-title">系統功能</h5> */}

                  {mTable}
                  {mTableHour}
                  {mTableDevice}
                  {mTableBrowser}
                  {mTableIp}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )


  return (
    <Layout>

      {mBody}
      <UpsertModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

    </Layout>

  );

  //const mTableBody = users.map(users => <tr key={users.id}><td>{users.id}</td><td>{users.name}</td></tr>)


  const Main = (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Dashboard</h1>
          <table>
            { }
          </table>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="index.html">Home</a></li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Default Table</h5>

                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )

  return (
    <>
      <Layout>
        {Main}
      </Layout>
    </>

  )
}

export default LoginRecord