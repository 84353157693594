import { useState, useMemo } from 'react'
import { sortRows, filterRows, paginateRows } from './Helpers'
import { Pagination } from './Pagination'

export const StTable = ({ columns, rows, isSearch, isPage, pageSize }) => {
  const [activePage, setActivePage] = useState(1)
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState({ order: 'asc', orderBy: 'id' })
  let rowsPerPage = 5

  if (isPage == false)
    rowsPerPage = rows.length
  else
    if (typeof (pageSize) != 'undefined') rowsPerPage = pageSize

  const filteredRows = useMemo(() => filterRows(rows, filters), [rows, filters])
  const sortedRows = useMemo(() => sortRows(filteredRows, sort), [filteredRows, sort])
  const calculatedRows = paginateRows(sortedRows, activePage, rowsPerPage)

  const count = filteredRows.length
  const totalPages = Math.ceil(count / rowsPerPage)

  const handleSearch = (value, accessor) => {
    setActivePage(1)

    if (value) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [accessor]: value,
      }))
    } else {
      setFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters }
        delete updatedFilters[accessor]

        return updatedFilters
      })
    }
  }

  const handleSort = (accessor) => {
    setActivePage(1)
    setSort((prevSort) => ({
      order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
      orderBy: accessor,
    }))
  }

  const clearAll = () => {
    setSort({ order: 'asc', orderBy: 'id' })
    setActivePage(1)
    setFilters({})
  }

  return (
    <>
      <table>
        <thead>
          <tr>
            {columns.map((column) => {
              const sortIcon = () => {
                if (column.accessor === sort.orderBy) {
                  if (sort.order === 'asc') {
                    return '↑'
                  }
                  return '↓'
                } else {
                  return '️↕️'
                }
              }
              return (
                <th style={{cursor: 'pointer'}} key={column.accessor} onClick={() => handleSort(column.accessor)}>
                  <span>{column.label} {sortIcon()}</span>
                  {/* <button onClick={() => handleSort(column.accessor)}>{sortIcon()}</button> */}
                </th>
              )
            })}
          </tr>
          {
            isSearch ?
              <tr>
                {columns.map((column) => {
                  return (
                    <th>
                      {
                        column.filter ?
                        <input
                          key={`${column.accessor}-search`}
                          type="search"
                          placeholder={`${column.label}`}
                          value={filters[column.accessor]}
                          onChange={(event) => handleSearch(event.target.value, column.accessor)}
                        />
                        :
                        <></>
                      }                      
                    </th>
                  )
                })}
              </tr>
              :
              <></>
          }

        </thead>
        <tbody>
          {calculatedRows.map((row) => {
            return (
              <tr key={row.id}>
                {columns.map((column) => {
                  if (column.format) {
                    return <td key={column.accessor}>{column.format(row[column.accessor])}</td>
                  }
                  return <td key={column.accessor}>{row[column.accessor]}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>

      {
        count > 0 ?
          isPage ?
            <Pagination
              activePage={activePage}
              count={count}
              rowsPerPage={rowsPerPage}
              totalPages={totalPages}
              setActivePage={setActivePage}
            />
            :
            <div></div>
          :
          <p>無資料</p>
      }

    </>
  )
}
