import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"
import React, { useContext, useState } from "react";
import { AppContext } from '../App';
import axios from "axios";
import { getSkey, AddLog, trans } from './Util'
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
const Siderbar = () => {

    const { t, i18n } = useTranslation();
    let isAdmin = false
    let navigate = useNavigate();
    const defMenu = [
        {
            id: "0000",
            name: t("首頁"),
            path: "/",
            icon: "system",
            out: false,
            sub: null
        },
        {
            id: "4000",
            name: "系統管理",
            path: "",
            icon: "system",
            out: false,
            sub: [
                {
                    id: "4100",
                    name: "系統功能",
                    path: "/fun",
                    icon: "bi-window",
                    out: false
                },
                {
                    id: "4200",
                    name: "網站連結",
                    path: "/weblink",
                    icon: "bi-box-arrow-up-right",
                    out: false
                },
                {
                    id: "4300",
                    name: "選單管理",
                    path: "/menu",
                    icon: "bi-menu-button",
                    out: false
                },
                // {
                //     id: "4400",
                //     name: "區塊管理",
                //     path: "/block",
                //     icon: "bi-app",
                //     out: false
                // },
                {
                    id: "4600",
                    name: "頁面管理",
                    path: "/page",
                    icon: "bi-file-earmark-text",
                    out: false
                },
                {
                    id: "4700",
                    name: "分類管理",
                    path: "/tag",
                    icon: "bi-bookmark-star",
                    out: false
                },
                {
                    id: "4800",
                    name: "RSS管理",
                    path: "/rssblock",
                    icon: "bi-rss",
                    out: false
                },
                {
                    id: "4500",
                    name: "統計報表",
                    path: "/report",
                    icon: "bi-bar-chart-line",
                    out: false
                },
                {
                    id: "4502",
                    name: "到訪人次紀錄",
                    path: "/loginrecord",
                    icon: "bi-file-earmark-bar-graph",
                    out: false,
                },
                {
                    id: "4900",
                    name: "使用者管理",
                    path: "/user",
                    icon: "bi-people-fill",
                    out: false,
                    sub: null
                },
                {
                    id: "4950",
                    name: "角色管理",
                    path: "/role",
                    icon: "bi-person-badge-fill",
                    out: false,
                    sub: null
                }
            ]
        },
        {
            id: "8888",
            name: t("登出"),
            path: "/logout",
            icon: "system",
            out: false,
            sub: null
        }
    ]


    const [menu, setMenu] = useState([])
    const location = useLocation();
    console.log('pathname',location.pathname);

    const { sysConfig, isLogin, setIsLogin, userRole, setuserRole } = useContext(AppContext)

    function clickLink(id, mode) {
        AddLog(sysConfig, id, mode)

        chgSiderBar()
    }

    function chgSiderBar() {
        if (window.innerWidth < 768) {
            window.openSiderBar(false)
        }
    }

    React.useEffect(() => {
        
        if (isLogin)
            getUser()
        else
            getMenu()

    }, [isLogin]);

    function getUser() {
        const uUrl = sysConfig.apiUrl + 'User?loginId=' + localStorage.getItem('LoginId')
        axios.get(uUrl).then((response) => {         
            

            var user = response.data[0]
            var rids = ''
            user.roleList.forEach(item => {
                if (item.id == 'admin') {
                    isAdmin = true
                }
                rids += item.id + ','
            });
            localStorage.setItem('role', rids)
            setuserRole(rids)
            getMenu()         
               

        })
        .catch((error, response) => {
            console.log(error)
            
            if(error.response.status == 401) {
                let token = ''
                token = localStorage.getItem('token')
                axios.defaults.headers.common['token'] = `${token}`;
                console.log('setDefHeader')
                setTimeout(() => {
                    window.location.reload()
                }, 1500);
            }
        })

    }

    function getMenu() {

        const uid = localStorage.getItem('LoginId')       

        let endpoints = [
            sysConfig.apiUrl + 'Menu/top?userId=' + uid,
            sysConfig.apiUrl + 'Menu/left?userId=' + uid
        ];
        if(uid == null) {
            endpoints = [
                sysConfig.apiUrl + 'Menu/Guest?position=top',
                sysConfig.apiUrl + 'Menu/Guest?position=left'
            ];
        }
        axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
            (data) => {
                console.log('all', data[0], data[1])

                const m_top = data[0].data
                const m_left = data[1].data

                let menus = []
                
                m_left.forEach(m => {
                    menus.push(m)
                });

                m_top.forEach(m => {
                    menus.push(m)
                });

                let mrr = []
                //mrr.push(defMenu[0])    // home

                if (isAdmin) {
                    mrr.push(defMenu[1])    // sysMenu
                }

                for (var i in menus) {
                    var obj = menus[i]
                    var mobj = {}
                    if (obj.mode == 'fun') {
                        mobj = {
                            id: obj.menu_id,
                            cid: obj.content_dictionary[0].content_id,
                            mode: obj.mode,
                            position: obj.position,
                            name: trans(i18n.language, obj.name, obj.ename),
                            path: obj.content_dictionary[0].content + getSkey(),
                            icon: "bi-grid",
                            iconpath: obj.iconpath,
                            out: true,
                            sub: null
                        }
                        if (window.location.href.indexOf('localhost:3000') > -1)
                            mobj.path = mobj.path.replace('https://catalog.cgu.edu.tw', 'http://localhost:3000')
                    }
                    if (obj.mode == 'link') {
                        mobj = {
                            id: obj.menu_id,
                            cid: obj.content_dictionary[0].content_id,
                            mode: obj.mode,
                            position: obj.position,
                            name: trans(i18n.language, obj.name, obj.ename),
                            path: obj.content_dictionary[0].content,
                            icon: "bi-link",
                            iconpath: obj.iconpath,
                            out: true,
                            sub: null
                        }
                    }
                    if (obj.mode == 'page') {
                        mobj = {
                            id: obj.menu_id,
                            cid: obj.content_dictionary[0].content_id,
                            mode: obj.mode,
                            position: obj.position,
                            name: trans(i18n.language, obj.name, obj.ename),
                            path: '/pageshow?id=' + obj.content_dictionary[0].content_id,
                            icon: "bi-card-list",
                            iconpath: obj.iconpath,
                            out: false,
                            sub: null
                        }
                    }
                    //if (isLogin || window.getIsSSOLogin() == true)
                    mrr.push(mobj)
                }




                setMenu(mrr)

            }
        );

        // axios.get(apiUrl).then((response) => {



        // });
    }

    let activeClass = "nav-link collapsed"

    function Logout() {
        localStorage.removeItem("token")
        localStorage.removeItem("role")
        localStorage.removeItem("userId")
        localStorage.removeItem("LoginId")
        localStorage.removeItem("userName")
        localStorage.removeItem("stid")
        localStorage.removeItem("style")
        localStorage.removeItem("i18n")

        if (window.getIsSSOLogin() == true) {
            window.logoutSSO()
        }
        else {
            window.location.href = "/"
        }
    }

    function search(event) {
        event.preventDefault()
        const key = event.target.query.value

        if (event.target.query.value === '') {
            Swal.fire({
                title: '請輸入功能名稱關鍵字',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: '確定'
            })
            return
        }

        navigate('/search', { state: key })

        chgSiderBar()

    }

    return (
        <>
            {/* <aside id="sidebar" className="sidebar"> */}
            <ul className="sidebar-nav" id="sidebar-nav">

                {
                    isLogin ?
                        <li class="nav-item ">
                            <form onSubmit={search} className="m-search search-form d-flex align-items-center" method="POST" action="#">
                                <input type="text" name="query" placeholder={t("請輸入連結、功能名稱")} />
                                <button type="submit" title="Search">
                                    <i class="bi bi-search"></i></button>
                            </form>
                        </li>
                        :
                        <></>
                }


                <li class="nav-item "><a class="nav-link" href="/">
                    <img class="defImg-left-menu" src="./img/0000.png" /><span>{t("首頁")}</span></a>
                </li>




                {
                    menu.map(menu => (


                        menu.sub == null ?
                            <li className={"nav-item " + (menu.position == 'top' ? "nav-item-mobile" : "")} key={menu.id}>
                                {
                                    menu.out === false ?
                                        <Link onClick={() => clickLink(menu.cid, menu.mode)} className={location.pathname === menu.path ? "nav-link" : "nav-link collapsed"} to={menu.path}>
                                            {
                                                menu.icon == 'system' ?
                                                    <img className="defImg-left-menu" src={"./img/" + menu.id + ".png"} />
                                                    :
                                                    menu.iconpath == '' || menu.iconpath == null ?
                                                        <img className="defImg-left-menu" src={"./img/def_" + menu.mode + ".svg"} />
                                                        :
                                                        <img src={sysConfig.imgUrl + 'menu/' + menu.iconpath} className="icon-left-menu" />
                                            }
                                            <span>{menu.name}</span>
                                        </Link>
                                        :
                                        <a onClick={() => clickLink(menu.cid, menu.mode)} className={activeClass} href={menu.path} target="_blank">
                                            {/* <i className={"bi " + menu.icon}></i> */}
                                            {
                                                menu.iconpath == '' || menu.iconpath == null ?
                                                    <img className="defImg-left-menu" src={"./img/def_" + menu.mode + ".svg"} />
                                                    :
                                                    <img src={sysConfig.imgUrl + 'menu/' + menu.iconpath} className="icon-left-menu" />
                                            }

                                            <span>{menu.name}</span>
                                        </a>
                                }
                            </li>
                            :
                            <li className="nav-item" key={menu.id}>
                                <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
                                    <img className="defImg-left-menu" src={"./img/" + menu.id + ".png"} />
                                    <span>{menu.name}</span> &nbsp;<i className="bi bi-chevron-down ms-auto"></i>
                                </a>
                                {
                                    <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                                        {
                                            menu.sub.map(sub => (

                                                <li key={sub.id}>
                                                    <Link onClick={() => chgSiderBar()} className={location.pathname === menu.path ? "nav-link" : "nav-link collapsed"} to={sub.path}>
                                                        <i className={"bi " + sub.icon + " icon-left-menu"}></i>
                                                        <span>{sub.name}</span>
                                                    </Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                }
                            </li>
                    ))
                }
                {
                    isLogin == false ?
                        <>
                            <li class="nav-item m-login">
                                <a class="nav-link collapsed" href="javascript:void(0)" onClick={() => window.loginSSO()}>
                                    <img class="defImg-left-menu" src="./img/cloud-check.png" />
                                    <span>{t("登入")}</span></a>
                            </li>
                            {/* <li class="nav-item m-login">
                                <a class="nav-link collapsed" href="/login">
                                    <img class="defImg-left-menu" src="./img/login.png" />
                                    <span>{t("系統登入")}</span></a>
                            </li> */}
                        </>
                        :
                        <>
                            <li class="nav-item m-login">
                                <a class="nav-link collapsed" href="javascript:void(0)" onClick={() => Logout()}>
                                    <img class="defImg-left-menu" src="./img/logout.png" />
                                    <span>{t("登出")}</span></a>
                            </li>
                        </>
                }

            </ul>
            <br/><br/><br/><br/>
            {/* <div className="d-flex align-items-center justify-content-center">
                <div className="btn-m-b-div ">
                    <div className="btn-m-b">
                        <a href="https://www.cgu.edu.tw/?tw" target="_blank"><img src="./img/btn-m-b-1.png" width="30" /> 長庚大學首頁</a>
                    </div>
                    <div className="btn-m-b">
                        <a href="https://ic.cgu.edu.tw/" target="_blank"><img src="./img/btn-m-b-2.png" width="30" /> 長庚大學資訊中心</a>
                    </div>
                </div>
            </div> */}

            {/* </aside> */}
        </>

    )
}

export default Siderbar