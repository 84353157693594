import React from "react"
import Layout from "../common/Layout"
import { useState, useEffect, useContext } from 'react'
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AppContext } from '../App';
import Swal from 'sweetalert2'
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const PageContent = () => {
  const { t, i18n } = useTranslation();
  const { sysConfig } = useContext(AppContext) 
  const [loading, openLoading] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [isLoading, setLoading] = useState(true);
  const [functions, setFuntions] = useState({contents: []});
  const [seldata, setseldata] = useState([]);
  let seldataDef = null
  const [selmode, setselmode] = useState('tag')
  const [selmodeClose, setselmodeClose] = useState("")
  let isOpen = false

  const [fId, setfId] = useState()
  const [fName, setfName] = useState()
  const [fDesc, setfDesc] = useState()

  const queryParams = new URLSearchParams(window.location.search)
  let id = queryParams.get("id")
  
  const [fData, setfData] = useState({
    function_id: "",
    name: "",
    description: ""
  });

  function getdata() {
    openLoading(true)
    const apiUrl = sysConfig.apiUrl + "Page?id="+id
    
    axios.get(apiUrl).then(response => {
      setTimeout(() => {
        setFuntions(response.data[0]);
        console.log(response.data)
        setLoading(false);
        openLoading(false)
        
      }, 1);
      
    });
  }

  useEffect(() => {
    getSelData()
  }, [functions])

  function getlinks() {
    openLoading(true)
    const apiUrl = sysConfig.apiUrl + "Link"
    
    axios.get(apiUrl).then(response => {
      
      const links = response.data
      let srr = []
      links.forEach(aa => {
        let sobj = {}
        sobj.id = aa.link_id
        sobj.name = aa.name
        srr.push(sobj)
      });

      setseldata(srr)
     
      
    });
  }

  function test() {
    setfName('ff')
  }

  function upsert(index) {   
    if(id == "00db220e-ffdf-4a7e-8a42-416adfa55db9") {
      setselmodeClose("disabled")
    }

    setModalShow(true)
    if(index > -1) {
      const obj = functions[index]
      //const index = functions.findIndex(a => a.function_id == id)
      setfId(obj.function_id)
      setfName(obj.name)
      setfDesc(obj.description)
      setFuntions(functions)
    } 
    else {
      setfId('')
      setfName('')
      setfDesc('')
    }
  }

  function save(event) {
    event.preventDefault();
        
    let sUrl = sysConfig.apiUrl + 'PageContent'
    let sMethod = 'post'

    let sData = {    
      pageId: id,
      contentId: event.target.iContentId.value,
      mode: event.target.iMode.value
    }
    

    // if(fId != '') {
    //   sUrl += '/' + fId
    //   sMethod = 'put'
    // }

    axios({
      method: sMethod,
      url: sUrl,
      data: sData
    })
    .then((response) => {
        console.log(response.data);
        setModalShow(false)
        getdata()      
       
    })
    .catch((error) => {
        console.log(error)
    })

  }

  function del(index) {

    Swal.fire({
      title: '確定刪除這筆資料',
      //text: "確定刪除這筆資料?",
      icon: 'warning',
      showCancelButton: true,    
      confirmButtonText: '確定',
      cancelButtonText: '取消'
    }).then((result) => {
      if (result.isConfirmed) {
        const obj = functions.contents[index]
        let sUrl = sysConfig.apiUrl + 'PageContent' 
        let sMethod = 'delete'   

        let sData = {    
          pageId: id,
          contentId: obj.contentId
        }
        
        //const ndata = functions.filter(item => item.function_id !== obj.function_id)
        //setFuntions(ndata)

        axios({
          method: sMethod,
          url: sUrl,
          data: sData
        })
        .then((response) => {
            console.log(response.data);   
            if(response.data.success) {
              Swal.fire({
                title: '刪除成功',     
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: '確定',
                timer: 2000
              })
              
            } else {
              const rdata = response.data.data
              let rmsg = ''
              for(var i in rdata) {
                rmsg += rdata[i].name + "[" + t("position_"+rdata[i].position) + "] "
              }
              Swal.fire({
                title: response.data.msg,    
                text: rmsg, 
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: '確定'
              })
            }
            getdata()
            
        })
        .catch((error) => {
            console.log(error)
        })
      }
    })

    //if(window.confirm('確定刪除?') === false) return
    
    
  }

  function changeSelMode(event) {
    const smode = event.target.value
    if(smode == 'tag')  getSelData()
    if(smode == 'link') getlinks()
    setselmode(smode)
  }

  function UpsertModal(props) {
    //setOpen(props.show)
    isOpen = props.show
    console.log(isOpen)
    return (
      <Modal
        {...props}
        size="lg"
        backdrop="static"
        keyboard="false"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form  onSubmit={save}>      
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            系統頁面
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <div className="row g-3">
            <div className="col-md-4"  >
                <label className="form-label">選擇模式</label>
                <select disabled={selmodeClose} id="inputState" onChange={changeSelMode} defaultValue={selmode}  name="iMode" className="form-select"  >
                  <option value="tag" >連結分類</option>
                  {/* <option value="link" >連結</option>                   */}
                </select>
            </div>
            <div className="col-md-8"  >
                <label className="form-label">選擇內容</label>
                <select id="inputState"  name="iContentId" className="form-select"  >
                  {
                      seldata.map((item) => (
                        <option value={item.id} >{item.name}</option>
                      )) 
                  }
                                
                </select>
            </div>
            {/* <div className="col-md-8">
              <label   className="form-label">描述</label>
              <input type="text" defaultValue={fDesc} className="form-control" name="iDesc"/>
            </div>                */}
          </div>
          
        </Modal.Body>
        <Modal.Footer>
        <input type="submit" name="submit" className="btn btn-primary" value="儲存"  />
        <Button variant="secondary" onClick={props.onHide}>取消</Button>
        
        </Modal.Footer>
        </form>
      </Modal>
    );
  }

  function getSelData() {
    let endpoints = [
      sysConfig.apiUrl + "Tag?mode=link"
    ];
    axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
      (data) => {
        console.log('all', data[0], data[1], data[2])
         
        if(seldataDef === null) {
          seldataDef = data[0].data
        }
        filterSelData(data[0].data)
      }
    );
  }

  function filterSelData(data) {
    let mdata =  functions.contents;
    let narr = [];
    
    mdata.forEach(item => {
      var index = data.findIndex(function(x){ return x.id == item.contentId})
      if(index > -1) {
        data.splice(index, 1)
      }
    })
    
    setseldata(data)
  }

  useEffect(() => {       
    getdata()
    
  }, [sysConfig]);

  if (isLoading) {
    return (
      <Layout>
        <main id="main" className="main">Loading...</main>
      </Layout>
    )
  }

  function upd() {
    alert('upd')
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    console.log('handleOnDragEnd',result)    
    setOrder(result.draggableId, result.destination.index+1)
  }

  function setOrder(itemid, order) {
    let sUrl = sysConfig.apiUrl + 'Page/SetOrder?pid='+id+'&cid=' + itemid + '&order=' + order
    let sMethod = 'post'

    axios({
      method: sMethod,
      url: sUrl
    })
      .then((response) => {
        console.log(response.data);
        getdata()
      })
      .catch((error) => {
        console.log(error)
      })
      .then(() => {

      })
  }

  const mTableBody = (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="functions">
        {(provided) => (
          <tbody {...provided.droppableProps} ref={provided.innerRef}>
            { functions.contents.map((item, index) => {
              return (
                <Draggable className="table" key={item.contentId} draggableId={item.contentId + ""} index={index}>
                  {(provided) => (
                    <tr title="可使用拖放資料列的方式移動順序" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                       <td><i class="bi bi-grip-vertical"></i> {index+1}</td>
                       <td>{item.name}</td>
                       <td>{t("pmode_"+item.mode)}</td>
                       <td>                    
                          <button className="btn btn-danger" onClick={() => del(index)}><i  className="bi bi-trash"></i></button>
                        </td>       
                    </tr>         
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </tbody>
        )}
      </Droppable>
    </DragDropContext>
  )

  // const mTableBody = functions.contents.map(
  //   (f,index) => (
  //     <tr key={f.id}>
  //        <td>{index+1}</td>
  //       <td>{f.name}</td>
  //       <td>{t("pmode_"+f.mode)}</td>
  //       <td>
  //         {/* <button className="btn btn-warning" onClick={() => upsert(index)}><i  className="bi bi-pencil-square"></i></button>&nbsp; */}
  //         <button className="btn btn-danger" onClick={() => del(index)}><i  className="bi bi-trash"></i></button>
  //       </td>       
  //     </tr>
  //   )
  // )
  const mTable = (
    <table className="table">
      <thead>
        <tr>
          <th>序號</th>
          <th>名稱</th>
          <th>模式</th>
          <th>編輯</th>         
        </tr>
      </thead>
      {mTableBody}
    </table>
  )
  const mBody = (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>頁面內容</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/">首頁</a></li>
              <li className="breadcrumb-item"><a href="/page">頁面管理</a></li>
              <li className="breadcrumb-item active">頁面內容</li>                 
            </ol>
          </nav>
        </div>
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  {/* <h5 className="card-title">系統功能</h5> */}
                  <div className="row">
                    <div className="col-lg-6">
                      <h5 className="card-title">頁面名稱： {functions.name}</h5>
                    </div>
                    <div className="col-lg-6">
                      <h5 className="card-title">                   
                        <button className="btn btn-secondary float-end" onClick={() => upsert(-1)}><i  className="bi bi-plus-circle"></i> 新增</button>
                      </h5>                      
                    </div>
                  </div>
                  {mTable}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )


  return (
    <Layout>
       
       {mBody}
       <UpsertModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
       
    </Layout>
    
  );

  //const mTableBody = users.map(users => <tr key={users.id}><td>{users.id}</td><td>{users.name}</td></tr>)
       

    const Main = (
        <>
          <main id="main" className="main">
            <div className="pagetitle">
              <h1>Dashboard</h1>
              <table>
                {}
              </table>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                  <li className="breadcrumb-item active">Dashboard</li>
                </ol>
              </nav>
            </div>
            <section className="section dashboard">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Default Table</h5>
                     
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )
    
    return (
        <>
        <Layout>
        {Main}
        </Layout>        
        </>
        
    )
}

export default PageContent